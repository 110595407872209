import { Field, FieldProps, Formik, FormikProps } from 'formik';
import React, { FunctionComponent } from 'react';
import * as Yup from 'yup';
import styled from 'src/styles/styled-components';
import { auth } from 'src/firebase';
import { Input } from 'src/components/ui/Input/Input';
import { PrimaryButton } from 'src/components/ui/Button/PrimaryButton';

const FormActions = styled.div`
    display: flex;

    & > * {
        flex: 1;
    }
`;

const validationSchema = Yup.object().shape({
    emailAddress: Yup.string()
        .email('Please enter a valid email address')
        .required('Please enter a valid email address'),
    password: Yup.string().required('Please enter a password'),
});

const INITIAL_VALUES = {
    emailAddress: '',
    password: '',
};

type Props = {
    onSuccess: () => void;
};

export const LoginForm: FunctionComponent<Props> = ({ onSuccess }) => {
    return (
        <Formik
            initialValues={{ ...INITIAL_VALUES }}
            validationSchema={validationSchema}
            onSubmit={async (values, actions) => {
                try {
                    await auth.signInWithEmailAndPassword(
                        values.emailAddress,
                        values.password
                    );

                    onSuccess();
                } catch (err) {
                    actions.setErrors({ emailAddress: err.message });
                }
            }}
        >
            {({
                submitCount,
                handleSubmit,
                isSubmitting,
            }: FormikProps<typeof INITIAL_VALUES>) => (
                <form onSubmit={handleSubmit} noValidate>
                    <Field name="emailAddress">
                        {({ field, meta }: FieldProps) => (
                            <Input
                                label="Email address"
                                type="email"
                                placeholder="Enter your email address"
                                error={(submitCount > 0 && meta.error) || null}
                                {...field}
                            />
                        )}
                    </Field>

                    <Field name="password">
                        {({ field, meta }: FieldProps) => (
                            <Input
                                label="Password"
                                type="password"
                                placeholder="Enter a password"
                                error={(submitCount > 0 && meta.error) || null}
                                {...field}
                            />
                        )}
                    </Field>

                    <FormActions>
                        <PrimaryButton
                            type="submit"
                            isSubmitting={isSubmitting}
                        >
                            Log in
                        </PrimaryButton>
                    </FormActions>
                </form>
            )}
        </Formik>
    );
};
