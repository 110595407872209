import styled from 'src/styles/styled-components';
import React from 'react';
import { css } from 'styled-components';
import { media } from 'src/utils/mediaQueries';

interface InputProps
    extends React.DetailedHTMLProps<
        React.InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
    > {
    label?: string;
    hint?: string;
    error: string | null;
}

export const StyledInputLabel = styled.label<{ hasHint?: boolean }>`
    display: block;
    font-weight: 500;
    font-size: ${({ theme }) => theme.font.size.level5.small};
    margin-bottom: 0.7rem;

    ${({ hasHint }) =>
        hasHint &&
        css`
            margin-bottom: 0rem;
        `}

    ${media.m`
        font-size: ${({ theme }) => theme.font.size.level4.small};
    `}
`;

export const InputHint = styled.small`
    display: block;
    margin-bottom: 0.7rem;

    ${media.m`
        font-size: ${({ theme }) => theme.font.size.level5.small};
    `}
`;

const StyledInput = styled.input`
    appearance: none;
    border-radius: 3px;
    border: solid 1px ${({ theme }) => theme.colors.grey3};
    display: block;
    width: 100%;
    padding: 1.1rem 1.4rem;
    font-size: ${({ theme }) => theme.font.size.level4.small};

    &:focus {
        border-color: ${({ theme }) => theme.colors.turquoise};
    }

    &::placeholder {
        color: ${({ theme }) => theme.colors.grey0};
    }
`;

export const StyledInputError = styled.div`
    font-size: ${({ theme }) => theme.font.size.level5.small};
    color: ${({ theme }) => theme.colors.pinkRed};
    position: absolute;
    top: calc(100% + 2px);
    left: 0;
    right: 0;
    text-align: right;
    white-space: nowrap;
`;

export const Input = styled(
    ({ label, error, name, hint, className, ref, ...props }: InputProps) => (
        <div className={className}>
            {label && (
                <StyledInputLabel htmlFor={`${name}-id`}>
                    {label}
                </StyledInputLabel>
            )}
            {hint && <InputHint>{hint}</InputHint>}
            <StyledInput name={name} id={`${name}-id`} {...props} />
            {error && <StyledInputError>{error}</StyledInputError>}
        </div>
    )
)<InputProps>`
    position: relative;
    margin-bottom: ${({ theme }) => theme.spacing.medium};

    ${({ error }) =>
        error &&
        css`
            ${StyledInput} {
                border-color: ${({ theme }) => theme.colors.pinkRed};
            }
        `}

    ${({ hint }) =>
        hint &&
        css`
            ${StyledInputLabel} {
                margin-bottom: 0.2rem;
            }
        `}
`;
