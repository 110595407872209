import React, { FunctionComponent, useState } from 'react';
import styled, { css } from 'src/styles/styled-components';
import { media } from 'src/utils/mediaQueries';
import { ReactComponent as PlayActionSVG } from 'src/svgs/icons/play-action.svg';
import { Loading } from '../ui/Loading';
import { Modal } from '../ui/Modal';
import { VideoWrapper } from '../VideoModal/VideoModal';
import { Constrain } from '../ui/Constrain';
import { FlockVideoPlayer } from '../FlockVideoPlayer';

const Wrapper = styled.div`
    position: relative;
    display: flex;
    border-radius: 3px;
    overflow: hidden;
    aspect-ratio: 1.1/1;
    flex: 1;

    ${({ onClick }) =>
        onClick &&
        css`
            &:hover {
                cursor: pointer;
            }
        `}

    @supports not (aspect-ratio: 1.1/1) {
        position: relative;

        &::before {
            float: left;
            padding-top: 77.25%;
            content: '';
        }
        &::after {
            display: block;
            content: '';
            clear: both;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
`;

const CoverImg = styled.img<{ blur: boolean }>`
    object-fit: cover;

    ${media.m`
        max-height: none;
    `}

    ${({ blur }) =>
        blur &&
        css`
            filter: blur(4px);
        `}
`;

const PlayContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    svg {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        box-shadow: 0px 0px 12px 4px rgba(0, 0, 0, 0.2);
    }
`;

const Processing = styled.div`
    border-radius: 3px;
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.8rem 2rem;
    font-weight: 500;

    svg {
        margin-bottom: 0.5rem;
    }
`;

const FreeNotice = styled.small`
    display: block;
    text-align: center;
    color: ${({ theme }) => theme.colors.grey3};
    margin-top: 3px;
`;

type Props = {
    previewVideo: api.CreatorPreviewVideo;
    className?: string;
};

export const UnstyledPreviewVideo: FunctionComponent<Props> = ({
    previewVideo,
    className,
}) => {
    const [isWatching, setIsWatching] = useState<boolean>(false);
    const { thumbnail, asset } = previewVideo;
    const isProcessing = asset.status !== 'ready';

    return (
        <div className={className}>
            <Wrapper
                onClick={() =>
                    (!isProcessing && setIsWatching(true)) || undefined
                }
                data-testid="previewVideo"
            >
                <CoverImg
                    src={thumbnail}
                    alt={`Preview video thumbnail`}
                    blur={isProcessing}
                    data-testid="cover"
                />

                {isProcessing && (
                    <Processing>
                        <Loading color="white" />
                        <small>Processing video</small>
                    </Processing>
                )}

                {!isProcessing && (
                    <PlayContainer>
                        <PlayActionSVG />
                    </PlayContainer>
                )}
            </Wrapper>

            <FreeNotice>Free to watch&nbsp;</FreeNotice>

            <Modal
                isOpen={!!isWatching}
                title="Preview"
                onClose={() => setIsWatching(false)}
            >
                {isWatching && (
                    <VideoWrapper>
                        <Constrain wide style={{ height: '100%' }}>
                            <FlockVideoPlayer
                                workoutOrPreview={previewVideo}
                                autoPlay={true}
                            />
                        </Constrain>
                    </VideoWrapper>
                )}
            </Modal>
        </div>
    );
};

export const PreviewVideo = styled(UnstyledPreviewVideo)`
    margin-bottom: 1.5rem;

    ${media.m`
        display: flex;
        flex-direction: column;
    `}
`;
