function isOfImageType(file: File): boolean {
    return ['image/png', 'image/jpeg'].includes(file.type);
}

function delay(time: number, value?: any) {
    return new Promise(function (resolve) {
        setTimeout(resolve.bind(null, value), time);
    });
}

function keepTrying(
    triesRemaining: number,
    storageRef: firebase.default.storage.Reference
): Promise<string> {
    if (triesRemaining < 0) {
        return Promise.reject('out of tries');
    }

    return storageRef
        .getDownloadURL()
        .then((url) => {
            return url;
        })
        .catch((error) => {
            switch (error.code) {
                case 'storage/object-not-found':
                    return delay(4000).then(() => {
                        return keepTrying(triesRemaining - 1, storageRef);
                    });
                default:
                    console.log(error);
                    return Promise.reject(error);
            }
        });
}

export const uploadImageToStorage = async (
    storageRef: firebase.default.storage.Reference,
    file: File,
    uploadedCb: Function = () => {}
): Promise<{ downloadUrl: string }> => {
    return new Promise((resolve, reject) => {
        if (!isOfImageType(file)) {
            reject('Please only upload an image');
            return;
        }

        if (file.size > 7 * 1024 * 1024) {
            reject('Please upload a image that is less than 7MB');
            return;
        }

        const fileNameWithDateNow = `${Date.now()}-${file.name}`;

        const upload = storageRef.child(fileNameWithDateNow).put(file);
        upload.then(async () => {
            uploadedCb();

            const downloadUrl = await keepTrying(
                15,
                storageRef.child(
                    fileNameWithDateNow.replace(
                        /(\.[\w\d_-]+)$/i,
                        `_1000x1000$1`
                    )
                )
            );

            resolve({ downloadUrl });
        });
    });
};
