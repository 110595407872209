/* eslint-disable react-hooks/rules-of-hooks */
import React, { FunctionComponent, useCallback } from 'react';
import { Heading } from '../ui/Heading';
import { Field, FieldProps, Formik, FormikProps } from 'formik';
import { PrimaryButton } from 'src/components/ui/Button/PrimaryButton';
import { FormActions } from 'src/components/ui/FormActions';
import { Input } from 'src/components/ui/Input/Input';
import { Select } from 'src/components/ui/Select';
import { countries } from 'src/utils/countries';
import * as Yup from 'yup';
import { firestore, functions } from 'src/firebase';
import { CheckboxInput } from '../ui/Input/Checkbox';
import stripeLogo from 'src/images/stripe-wordmark.svg';
import styled from 'src/styles/styled-components';
import { useGoingToStripe } from 'src/contexts/goingToStripe';
import { useUserStore } from 'src/store/userStore';

const StripeMessage = styled.div`
    display: flex;
    border-radius: 3px;
    padding: 1.2rem;
    border: solid 1px ${({ theme }) => theme.colors.grey5};
    margin-bottom: 2.4rem;

    p {
        margin: 0;
        font-size: ${({ theme }) => theme.font.size.level5.small};
    }
`;

const Alert = styled.div<{ severity: 'high' | 'medium' }>`
    background-color: ${({ severity, theme }) =>
        severity === 'high' ? theme.colors.pinkRed : '#FEFAED'};
    color: ${({ severity, theme }) =>
        severity === 'high' ? 'white' : theme.colors.black};
    font-weight: 500;
    padding: 1.2rem;
    border-radius: 3px;
    box-shadow: ${({ severity, theme }) =>
        severity === 'high'
            ? theme.effects.redShadow
            : theme.effects.greyShadow};
`;

const validationSchema = Yup.object().shape({
    addressLine1: Yup.string().required('Please enter your address'),
    addressLine2: Yup.string(),
    county: Yup.string().required('Please enter your county'),
    city: Yup.string(),
    postalCode: Yup.string().required('Please enter your postal code'),
    country: Yup.string().required('Please select your country'),
    accountType: Yup.string().oneOf(['individual', 'company']).required(),
    confirmTerms: Yup.boolean().isTrue(
        'Please accept our terms and conditions'
    ),
});

export const INITIAL_VALUES = {
    addressLine1: '',
    addressLine2: '',
    county: '',
    city: '',
    postalCode: '',
    country: '',
    accountType: 'individual',
    confirmTerms: false,
    receiveUpdates: false,
};

export const CreatorOnboarding: FunctionComponent = () => {
    const { user } = useUserStore();
    const { setGoingToStripeMessage } = useGoingToStripe();

    const uploadData = useCallback(
        async (data: typeof INITIAL_VALUES) => {
            if (!data || !user) {
                throw new Error(
                    'No user or user data when trying to create a new creator'
                );
            }

            const stripeFn = functions.httpsCallable('createStripeAccount');

            try {
                await firestore
                    .collection('creators')
                    .doc(user.uid)
                    .set(data, { merge: true });

                const stripeResult = await stripeFn({
                    email: user.email,
                });

                window.location.assign(stripeResult.data.url);
            } catch (err: any) {
                throw err && err.message;
            }

            await new Promise((resolve) => {
                setTimeout(resolve, 2000);
            });
        },
        [user]
    );

    return (
        <>
            <Heading>
                Tell us <span>about you</span>
            </Heading>

            <Formik
                initialValues={{ ...INITIAL_VALUES }}
                validationSchema={validationSchema}
                onSubmit={async (values, actions) => {
                    actions.setFieldError('country', undefined);
                    setGoingToStripeMessage(
                        'Taking you to Stripe to complete your account setup.'
                    );

                    try {
                        await uploadData(values);
                    } catch (err) {
                        actions.setFieldError(
                            'country',
                            'Please check your address'
                        );

                        setGoingToStripeMessage(null);
                        actions.setSubmitting(false);
                    }
                }}
            >
                {({
                    submitCount,
                    handleSubmit,
                    isSubmitting,
                    isValid,
                }: FormikProps<typeof INITIAL_VALUES>) => (
                    <form onSubmit={handleSubmit} noValidate>
                        {[
                            {
                                name: 'addressLine1',
                                label: 'Address line 1',
                                placeholder: 'Enter your address',
                            },
                            {
                                name: 'addressLine2',
                                label: 'Address line 2 (optional)',
                                placeholder: 'Enter your address (cont)',
                            },
                            {
                                name: 'city',
                                label: 'City (optional)',
                                placeholder: 'Enter your city',
                            },
                            {
                                name: 'county',
                                label: 'County/state',
                                placeholder: 'Enter your county/state',
                            },
                            {
                                name: 'postalCode',
                                label: 'Postal code',
                                placeholder: 'Postcode/Zip',
                            },
                        ].map(({ name, label, placeholder }, index) => (
                            <Field name={name} key={index}>
                                {({ field, meta }: FieldProps) => (
                                    <Input
                                        {...field}
                                        label={label}
                                        type="text"
                                        placeholder={placeholder}
                                        error={
                                            (submitCount > 0 && meta.error) ||
                                            null
                                        }
                                    />
                                )}
                            </Field>
                        ))}

                        <Field name="country">
                            {({ field, meta }: FieldProps) => (
                                <Select
                                    {...field}
                                    label="Country"
                                    error={
                                        (submitCount > 0 && meta.error) || null
                                    }
                                    options={countries.map(
                                        ({ code, name }) => ({
                                            value: code,
                                            label: name,
                                        })
                                    )}
                                    pleaseSelect
                                />
                            )}
                        </Field>

                        {/* <Field name="accountType">
                                {({ field, meta, form }: FieldProps) => (
                                    <div style={{ marginBottom: '2.4rem' }}>
                                        <StyledInputLabel hasHint={true}>
                                            Account type
                                        </StyledInputLabel>
                                        <InputHint>
                                            Do you want to register as an
                                            Individual or a company? (Note, you
                                            will need your company information
                                            to register as a company)
                                        </InputHint>
                                        <RadioInput
                                            label="Individual"
                                            id="radio-individual"
                                            defaultChecked
                                            {...field}
                                            value="individual"
                                        />

                                        <RadioInput
                                            label="Company"
                                            id="radio-company"
                                            {...field}
                                            value="company"
                                        />
                                    </div>
                                )}
                            </Field> */}

                        <StripeMessage>
                            <p>
                                We use Stripe to make sure you get paid on time
                                and to keep your personal bank and details
                                secure. Click Save and continue to set up your
                                payments on Stripe.
                            </p>
                            <img src={stripeLogo} alt="Stripe logo" />
                        </StripeMessage>

                        <Field name="confirmTerms">
                            {({ field, meta }: FieldProps) => (
                                <CheckboxInput
                                    {...field}
                                    label={
                                        <>
                                            I agree to the FLOCK platform{' '}
                                            <a
                                                href="https://flock.fitness/terms"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                terms of service
                                            </a>
                                        </>
                                    }
                                    error={
                                        (submitCount > 0 && meta.error) || null
                                    }
                                />
                            )}
                        </Field>

                        <Field name="receiveUpdates">
                            {({ field, meta }: FieldProps) => (
                                <CheckboxInput
                                    {...field}
                                    label="I'd like to receive updates from FLOCK"
                                    error={
                                        (submitCount > 0 && meta.error) || null
                                    }
                                />
                            )}
                        </Field>

                        {!isValid && submitCount > 0 && (
                            <Alert severity="high">
                                Please correct the errors in the form and try
                                again
                            </Alert>
                        )}

                        <FormActions>
                            <PrimaryButton
                                type="submit"
                                isSubmitting={isSubmitting}
                            >
                                Save &amp; continue
                            </PrimaryButton>
                        </FormActions>
                    </form>
                )}
            </Formik>
        </>
    );
};
