import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'src/styles/styled-components';
import { ReactComponent as CrossSVG } from 'src/svgs/icons/cross.svg';
import { ReactComponent as IOSShareSVG } from 'src/svgs/icons/share.svg';
import { ReactComponent as PlusBoxSVG } from 'src/svgs/icons/plus-box.svg';
import { ReactComponent as AndroidChromeMenuSVG } from 'src/svgs/icons/chrome-menu.svg';

enum PhoneType {
    ANDROID = 'ANDROID',
    IPHONE = 'IPHONE',
    IPAD = 'IPAD',
    UNSPECIFIED = 'UNSPECIFIED',
}

function isAndroid() {
    return navigator.userAgent.match(/Android/i);
}

function isIphone() {
    return navigator.userAgent.match(/iPhone|iPod/i);
}

function isIpad() {
    return navigator.userAgent.match(/iPad/i);
}

function getDeviceType(): PhoneType {
    if (isAndroid()) {
        return PhoneType.ANDROID;
    } else if (isIphone()) {
        return PhoneType.IPHONE;
    } else if (isIpad()) {
        return PhoneType.IPAD;
    } else {
        return PhoneType.UNSPECIFIED;
    }
}

function isRunningStandalone() {
    return window.matchMedia('(display-mode: standalone)').matches;
}

const fadeInUp = keyframes`
    from {
        opacity: 0;
        transform: translateY(20px) scale(.95);
    }

    to {
        opacity: 1;
        transform: translateY(0px) scale(1);
    }
`;

const AnimateFadeInUp = styled.div`
    animation: ${fadeInUp} 300ms ease-out forwards;
`;

const fadeIn = keyframes`
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
`;

const Overlay = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1001;
    animation: ${fadeIn} 100ms linear forwards;
`;

const PositionWrapper = styled.div<{ phoneType: PhoneType }>`
    position: fixed;
    bottom: ${({ phoneType }) =>
        phoneType === PhoneType.IPHONE ? '3rem' : 'auto'};
    top: ${({ phoneType }) =>
        phoneType === PhoneType.ANDROID || phoneType === PhoneType.IPAD
            ? '3rem'
            : 'auto'};
    left: 50%;
    transform: translateX(-50%);
    z-index: 1002;
    max-width: 360px;
    width: 100%;
    padding: 0 1rem;
`;

const Wrapper = styled.div<{ phoneType: PhoneType }>`
    background-color: white;
    border-radius: 3px;
    padding: 1.6rem;
    animation: ${fadeIn} 300ms linear forwards;
    box-shadow: ${({ theme }) => theme.effects.greyShadow};

    &:after {
        position: absolute;
        content: '';
        top: ${({ phoneType }) =>
            phoneType === PhoneType.IPHONE ? '100%' : 'auto'};
        bottom: ${({ phoneType }) =>
            phoneType === PhoneType.IPHONE ? 'auto' : '100%'};
        left: ${({ phoneType }) =>
            phoneType === PhoneType.IPHONE ? '50%' : '90%'};
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 10px 10px 10px;
        border-color: ${({ phoneType }) =>
            phoneType === PhoneType.IPHONE
                ? 'white transparent transparent transparent'
                : 'transparent transparent white transparent'};
    }

    .plus-box {
        width: 4rem;
        height: 4rem;
        stroke: ${({ theme }) => theme.colors.black};
    }

    .share-ios {
        width: 2.4rem;
        height: 2.4rem;
        vertical-align: middle;
    }

    .share-android {
        width: 1.8rem;
        height: 1.8rem;
        vertical-align: middle;
    }
`;

const Layout = styled.div`
    display: flex;
    padding-right: 2rem;

    div:first-child {
        margin-right: 1rem;
        display: inline-flex;
        align-items: center;
    }
`;

const BlankButton = styled.button`
    appearance: none;
    border: 0;
    margin: 0;
    padding: 0;
    background: none;
    position: absolute;
    right: 1rem;
    top: 1rem;

    svg {
        width: 2.4rem;
        height: 2.4rem;
    }
`;

const ADD_TO_HOME_SCREEN_SHOWN_FLAG = 'addToHomeScreenShown';

export const AddToHomeScreen = () => {
    const [isShowing, setIsShowing] = useState<boolean>(false);
    const phoneType = getDeviceType();

    useEffect(() => {
        const shouldNotShow =
            phoneType === PhoneType.UNSPECIFIED || isRunningStandalone();
        const hasShownPreviously = localStorage.getItem(
            ADD_TO_HOME_SCREEN_SHOWN_FLAG
        );

        if (hasShownPreviously === null && !shouldNotShow) {
            setIsShowing(true);
            localStorage.setItem(ADD_TO_HOME_SCREEN_SHOWN_FLAG, 'true');
        }
    }, [phoneType]);

    if (!isShowing) {
        return <></>;
    }

    return (
        <>
            <Overlay />

            <PositionWrapper phoneType={phoneType}>
                <AnimateFadeInUp>
                    <Wrapper phoneType={phoneType}>
                        <BlankButton onClick={() => setIsShowing(false)}>
                            <CrossSVG />
                        </BlankButton>
                        <Layout>
                            <div>
                                <PlusBoxSVG className="plus-box" />
                            </div>
                            <div>
                                Add <strong>FLOCK</strong> to your home screen.
                                Tap{' '}
                                {phoneType === PhoneType.ANDROID ? (
                                    <AndroidChromeMenuSVG className="share-android" />
                                ) : (
                                    <IOSShareSVG
                                        className="share-ios"
                                        stroke="#2D80EF"
                                    />
                                )}{' '}
                                then <strong>Add to home screen</strong>
                            </div>
                        </Layout>
                    </Wrapper>
                </AnimateFadeInUp>
            </PositionWrapper>
        </>
    );
};
