import { Field, FieldProps, Formik, FormikProps } from 'formik';
import React, { FunctionComponent } from 'react';
import { Input } from '../ui/Input/Input';
import * as Yup from 'yup';
import { PrimaryButton } from '../ui/Button/PrimaryButton';
import firebase from 'firebase/app';
import 'firebase/auth';
import styled from 'src/styles/styled-components';
import { countries } from 'src/utils/countries';
import { Select } from '../ui/Select';
// import { firestore } from 'src/firebase';

const FormActions = styled.div`
    display: flex;

    & > * {
        flex: 1;
    }
`;

const validationSchema = Yup.object().shape({
    // inviteCode: Yup.string().required('Please enter an invite code'),
    emailAddress: Yup.string()
        .email('Please enter a valid email address')
        .required('Please enter a valid email address'),
    password: Yup.string()
        .required('Please enter a password')
        .min(8, 'Please enter a password of at least 8 characters')
        .matches(/\d/, 'Your password contain at least one number'),
    confirmPassword: Yup.string().when('password', {
        is: (value: string) => (value && value.length > 0 ? true : false),
        then: Yup.string()
            .oneOf([Yup.ref('password')], 'Both passwords need to be the same')
            .required('Please confirm your password'),
    }),
    country: Yup.string().required('Please select your country'),
});

const INITIAL_VALUES = {
    // inviteCode: '',
    emailAddress: '',
    password: '',
    confirmPassword: '',
    country: '',
};

export const CreateCreatorAccount: FunctionComponent = () => {
    return (
        <Formik
            initialValues={{ ...INITIAL_VALUES }}
            validationSchema={validationSchema}
            onSubmit={async (values, actions) => {
                try {
                    // const inviteCodeRef = firestore
                    //     .collection('inviteCodes')
                    //     .doc(values.inviteCode);
                    // const inviteCodeDoc = await inviteCodeRef.get();

                    // if (!inviteCodeDoc.exists) {
                    //     actions.setErrors({
                    //         inviteCode: 'Code not recognised',
                    //     });
                    //     return;
                    // }

                    // const inviteCode = inviteCodeDoc.data() as api.InviteCode;

                    // if (inviteCode.uses === 0) {
                    //     actions.setErrors({
                    //         inviteCode: 'This code has expired',
                    //     });
                    //     return;
                    // }

                    const user = await firebase
                        .auth()
                        .createUserWithEmailAndPassword(
                            values.emailAddress,
                            values.password
                        );

                    if (!user.user) {
                        throw new Error('No user defined');
                    }

                    // call function to assign creator custom claim
                    const assignCreator = await firebase
                        .functions()
                        .httpsCallable('assignCreatorRole');

                    await assignCreator({ userId: user.user?.uid });
                    // await inviteCodeRef.update({
                    //     uses: firebase.firestore.FieldValue.increment(-1),
                    // });

                    actions.setSubmitting(false);

                    // Redirects on auth token changed in /creator/signup
                } catch (err: any) {
                    if (err && err.message) {
                        actions.setErrors({ emailAddress: err.message });
                    }
                }
            }}
        >
            {({
                submitCount,
                handleSubmit,
                isSubmitting,
            }: FormikProps<typeof INITIAL_VALUES>) => (
                <form onSubmit={handleSubmit} noValidate>
                    {/* <Field name="inviteCode">
                        {({ field, meta }: FieldProps) => (
                            <Input
                                label="Invite code"
                                type="text"
                                placeholder="Enter your invite code"
                                error={(submitCount > 0 && meta.error) || null}
                                {...field}
                            />
                        )}
                    </Field> */}

                    <Field name="emailAddress">
                        {({ field, meta }: FieldProps) => (
                            <Input
                                label="Email address"
                                type="email"
                                placeholder="Enter your email address"
                                error={(submitCount > 0 && meta.error) || null}
                                {...field}
                            />
                        )}
                    </Field>

                    <Field name="password">
                        {({ field, meta }: FieldProps) => (
                            <Input
                                label="Password"
                                type="password"
                                placeholder="Enter a password"
                                error={(submitCount > 0 && meta.error) || null}
                                {...field}
                            />
                        )}
                    </Field>

                    <Field name="confirmPassword">
                        {({ field, meta }: FieldProps) => (
                            <Input
                                label="Confirm password"
                                type="password"
                                placeholder="Confirm your password"
                                error={(submitCount > 0 && meta.error) || null}
                                {...field}
                            />
                        )}
                    </Field>

                    <Field name="country">
                        {({ field, meta }: FieldProps) => (
                            <Select
                                {...field}
                                label="Country"
                                error={(submitCount > 0 && meta.error) || null}
                                options={countries.map(({ code, name }) => ({
                                    value: code,
                                    label: name,
                                }))}
                                pleaseSelect
                            />
                        )}
                    </Field>

                    <FormActions>
                        <PrimaryButton
                            type="submit"
                            isSubmitting={isSubmitting}
                        >
                            Create your account
                        </PrimaryButton>
                    </FormActions>
                </form>
            )}
        </Formik>
    );
};
