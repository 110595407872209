import styled, { css } from 'src/styles/styled-components';
import theme from 'src/styles/theme';
import { media } from 'src/utils/mediaQueries';

type Props = {
    alignment?: 'left' | 'center' | 'right';
    color?: keyof typeof theme.colors;
    size?: keyof typeof theme.font.size;
    spacing?: keyof typeof theme.spacing;
};

export const Header = styled.h2<Props>`
    margin-bottom: 0.5rem;
    font-weight: bold;
    font-size: ${({ theme, size }) =>
        size ? theme.font.size[size].small : '22px'};
    color: ${({ theme, color }) => theme.colors[color ? color : 'grey1']};

    ${({ alignment }) =>
        alignment &&
        css`
            text-align: ${alignment};
        `};

    ${({ spacing, theme }) =>
        spacing &&
        css`
            margin-bottom: ${theme.spacing[spacing]};
        `};

    ${media.l`
        font-size: ${({ theme, size }: any) =>
            size ? theme.font.size[size].large : '26px'};
    `}
`;
