/* eslint-disable react-hooks/rules-of-hooks */
import React, { FunctionComponent, useState } from 'react';
import { Field, FieldProps, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { Input, InputHint, StyledInputLabel } from '../ui/Input/Input';
import styled from 'src/styles/styled-components';
import { RichTextEditor } from '../ui/RichTextEditor/RichTextEditor';
import { Node } from 'slate';
import { RadioInput } from '../ui/Input/Radio';
import { Textarea } from '../ui/Input/Textarea';
import { FormActions } from '../ui/FormActions';
import { PrimaryButton } from '../ui/Button/PrimaryButton';
import { serialize } from '../ui/RichTextEditor/utils/htmlSerializer';
import { CoverThumbnailUpload } from '../CoverThumbnailUpload/CoverThumbnailUpload';
import { media } from 'src/utils/mediaQueries';
import { firestore } from 'src/firebase';
import { deserializeHtml } from '../ui/RichTextEditor/utils/htmlDeserialize';
import { WorkoutVideoUploader } from './WorkoutVideoUploader';

const Error = styled.div`
    color: ${({ theme }) => theme.colors.pinkRed};
    font-weight: 500;
    text-align: center;
    margin-bottom: 2rem;
`;

const Form = styled.form`
    max-width: 500px;
    margin: 0 auto;

    ${media.l`
        max-width: 1000px;
    `}
`;

const FieldsWrapper = styled.div`
    ${media.l`
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 5rem;
    `}
`;

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Please enter a workout name'),
    subtitle: Yup.string().required('Please enter a workout subtitle'),
    workoutId: Yup.string().required(
        'Please upload your video and wait for it to finish'
    ),
    thumbnail: Yup.string().required('Upload a thumbnail'),
    equipment: Yup.string().when('equipmentRequired', {
        is: true,
        then: Yup.string().required('Please specify the equipment needed'),
    }),
});

const INITIAL_VALUES = {
    workoutId: '',
    name: '',
    subtitle: '',
    thumbnail: '',
    whatsInvolved: [
        {
            type: 'paragraph',
            children: [
                {
                    text: '',
                },
            ],
        },
    ] as Node[],
    equipmentRequired: false,
    equipment: '',
};

type Props = {
    draftWorkout: flock.Workout | null;
    onSuccess: (workoutId: string) => void;
};

function getInitialValuesFromWorkout(
    workout: flock.Workout
): typeof INITIAL_VALUES {
    return {
        workoutId: workout.id,
        name: workout.name,
        subtitle: workout.subtitle,
        thumbnail: workout.thumbnail,
        whatsInvolved:
            workout.whatsInvolved === '<p></p>'
                ? (deserializeHtml('<p> </p>') as Node[])
                : (deserializeHtml(workout.whatsInvolved) as Node[]),
        equipmentRequired: workout.equipment.length > 0,
        equipment: workout.equipment,
    };
}

export const CreateWorkoutForm: FunctionComponent<Props> = ({
    onSuccess,
    draftWorkout,
}) => {
    const [error, setError] = useState<string | null>(null);

    return (
        <Formik
            initialValues={
                draftWorkout
                    ? getInitialValuesFromWorkout(draftWorkout)
                    : INITIAL_VALUES
            }
            validationSchema={validationSchema}
            onSubmit={async (values, actions) => {
                try {
                    const { name, equipment, thumbnail, subtitle } = values;

                    await firestore
                        .collection('workouts')
                        .doc(values.workoutId)
                        .set(
                            {
                                name,
                                subtitle,
                                equipment,
                                whatsInvolved: serialize(values.whatsInvolved),
                                thumbnail,
                            },
                            { merge: true }
                        );

                    actions.setSubmitting(false);

                    onSuccess(values.workoutId);
                    setError(null);
                } catch (err) {
                    setError('An error occurred saving your workouts');
                }
            }}
        >
            {({
                submitCount,
                handleSubmit,
                values,
                isSubmitting,
                setFieldValue,
                setErrors,
            }: FormikProps<typeof INITIAL_VALUES>) => {
                return (
                    <Form onSubmit={handleSubmit} noValidate>
                        <FieldsWrapper>
                            <div>
                                <Field name="workoutId">
                                    {({ field, meta }: FieldProps) => (
                                        <WorkoutVideoUploader
                                            workout={field.value}
                                            error={meta.error}
                                            onError={(
                                                err: string | undefined
                                            ) =>
                                                setErrors({
                                                    workoutId: err,
                                                })
                                            }
                                            clearWorkout={() =>
                                                setFieldValue('workoutId', '')
                                            }
                                            clearError={() =>
                                                setErrors({
                                                    workoutId: undefined,
                                                })
                                            }
                                            onSuccess={(workoutId: string) =>
                                                setFieldValue(
                                                    'workoutId',
                                                    workoutId
                                                )
                                            }
                                        />
                                    )}
                                </Field>

                                <CoverThumbnailUpload
                                    upload={values.thumbnail}
                                    bucket="workout-thumbnails"
                                    guide="Upload a photo that best displays your workout"
                                    onError={(err) => {
                                        setErrors({ thumbnail: err });
                                    }}
                                    onClear={() =>
                                        setFieldValue('thumbnail', '')
                                    }
                                    onSuccess={(thumbnailId) => {
                                        setFieldValue('thumbnail', thumbnailId);
                                    }}
                                />

                                <Field name="name">
                                    {({ field, meta }: FieldProps) => (
                                        <Input
                                            label="Workout name"
                                            type="text"
                                            placeholder="Full body"
                                            error={
                                                (submitCount > 0 &&
                                                    meta.error) ||
                                                null
                                            }
                                            {...field}
                                        />
                                    )}
                                </Field>

                                <Field name="subtitle">
                                    {({ field, meta }: FieldProps) => (
                                        <Input
                                            label="Workout subtitle"
                                            type="text"
                                            hint="A one line description to back up the name of your workout."
                                            placeholder="Full body cardio and strength burn"
                                            error={
                                                (submitCount > 0 &&
                                                    meta.error) ||
                                                null
                                            }
                                            {...field}
                                        />
                                    )}
                                </Field>
                            </div>

                            <div>
                                <Field name="whatsInvolved">
                                    {({ field, form }: FieldProps) => (
                                        <RichTextEditor
                                            label="What's involved in the workout"
                                            hint="Describe the exercises and activities in your workout"
                                            value={field.value}
                                            setValue={(value: Node[]) => {
                                                form.setFieldValue(
                                                    'whatsInvolved',
                                                    value
                                                );
                                            }}
                                        />
                                    )}
                                </Field>

                                <Field name="equipment">
                                    {({ field, meta, form }: FieldProps) => (
                                        <div>
                                            <StyledInputLabel hasHint={true}>
                                                Any equipment required?
                                            </StyledInputLabel>
                                            <InputHint>
                                                Let your subscribers know what
                                                they’ll need
                                            </InputHint>
                                            <RadioInput
                                                label="No equipment needed"
                                                name="equipmentRequired"
                                                id="radio-false"
                                                defaultChecked
                                                onChange={() => {
                                                    form.setFieldValue(
                                                        'equipmentRequired',
                                                        false
                                                    );
                                                    form.setFieldValue(
                                                        'equipment',
                                                        ''
                                                    );
                                                }}
                                                value="false"
                                            />

                                            <RadioInput
                                                label="Yes, specify below"
                                                id="radio-true"
                                                name="equipmentRequired"
                                                onChange={() =>
                                                    form.setFieldValue(
                                                        'equipmentRequired',
                                                        true
                                                    )
                                                }
                                                value="true"
                                            />

                                            {values.equipmentRequired && (
                                                <Textarea
                                                    placeholder="e.g. Free weights, resistance bands"
                                                    error={
                                                        (submitCount > 0 &&
                                                            meta.error) ||
                                                        null
                                                    }
                                                    {...field}
                                                />
                                            )}
                                        </div>
                                    )}
                                </Field>
                            </div>
                        </FieldsWrapper>

                        {error && <Error>{error}</Error>}

                        <FormActions>
                            <PrimaryButton
                                type="submit"
                                isSubmitting={isSubmitting}
                            >
                                Save &amp; preview
                            </PrimaryButton>
                        </FormActions>
                    </Form>
                );
            }}
        </Formik>
    );
};
