import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect } from 'react';
import { useNotificationStore } from 'src/store/notificationStore';
import styled from 'src/styles/styled-components';

const Wrapper = styled.ul`
    position: absolute;
    margin: 0;
    padding: 0;
    list-style: none;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
`;

const Notification = styled(motion.li)`
    backdrop-filter: blur(3px);
    background-color: rgba(242, 242, 242, 0.5);
    padding: 12px 24px;
    border-radius: 24px;
    margin-bottom: 16px;
    min-width: 100px;
    font-weight: 500;
    text-align: center;
`;

export const Notifications = () => {
    const { notifications, remove, setAsShown } = useNotificationStore();

    useEffect(() => {
        notifications
            .filter((n) => !n.shown)
            .forEach((n) => {
                setTimeout(() => remove(n), n.timeout);
                setAsShown(n.id);
            });
    }, [notifications, setAsShown, remove]);

    return (
        <>
            {notifications.length > 0 && (
                <Wrapper>
                    <AnimatePresence>
                        {notifications
                            .slice()
                            .reverse()
                            .map(({ id, text }) => (
                                <Notification
                                    key={id}
                                    initial={{ opacity: 0, y: -40, scale: 0.7 }}
                                    animate={{ opacity: 1, y: 0, scale: 1 }}
                                    exit={{
                                        opacity: 0,
                                        scale: 0.5,
                                        transition: { duration: 0.2 },
                                    }}
                                >
                                    {text}
                                </Notification>
                            ))}
                    </AnimatePresence>
                </Wrapper>
            )}
        </>
    );
};
