import React, { FunctionComponent, useState } from 'react';
import styled from 'src/styles/styled-components';
import { uploadImageToStorage } from 'src/utils/uploadImageToStorage';
import { v4 as uuidv4 } from 'uuid';
import { ReactComponent as UploadSVG } from '../../svgs/icons/upload.svg';
import { ReactComponent as CrossSVG } from '../../svgs/icons/cross.svg';
import { Loading } from '../ui/Loading';
import { StyledInputLabel } from '../ui/Input/Input';
import { media } from 'src/utils/mediaQueries';
import { storage } from 'src/firebase';

const Wrapper = styled.div`
    display: flex;
    margin-bottom: 2.4rem;

    ${media.l`
        button {
            transition: opacity 200ms;
            opacity: 0;
        }

        &:hover button {
            opacity: 1;
        }
    `}
`;

const ThumbnailLabel = styled.label`
    background-color: white;
    border: solid 1px ${({ theme }) => theme.colors.grey3};
    border-radius: 3px;
    padding: 0.2rem 4rem;
    text-align: center;
    font-weight: normal;
    font-size: ${({ theme }) => theme.font.size.level4.small};
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 15rem;
    height: 15rem;
    flex-shrink: 0;

    span {
        display: inline-block;
        margin-top: 1rem;
    }

    ${media.m`
        font-size: ${({ theme }) => theme.font.size.level3.small};
        width: 21.2rem;
        height: 21.2rem;
    `}

    svg {
        margin-bottom: 0.8rem;
        width: 2.2rem;
        height: 2.2rem;
    }
`;

const PreviewImage = styled.div`
    position: relative;

    button {
        position: absolute;
        display: flex;
        padding: 0.5rem;
        background: rgba(255, 255, 255, 0.8);
        top: 1rem;
        right: 1rem;
        border: 0;
        appearance: none;
        border: 0;
        margin: 0;
        padding: 0;
        border-radius: 3px;
        cursor: pointer;

        svg {
            width: 3rem;
            height: 3rem;
        }
    }

    img {
        width: 15rem;
        height: 15rem;
        object-fit: cover;
        border-radius: 3px;

        ${media.m`
            width: 21.2rem;
            height: 21.2rem;
        `}
    }
`;

const Guide = styled.div`
    padding: 2rem;
    text-align: center;
    display: inline-flex;
    align-items: center;
    font-size: ${({ theme }) => theme.font.size.level5.small};

    ${media.m`
        font-size: ${({ theme }) => theme.font.size.level4.small};
    `}
`;

const Error = styled(Guide)`
    color: ${({ theme }) => theme.colors.pinkRed};
`;

const HiddenInput = styled.input`
    position: absolute;
    left: -9999px;
`;

type Props = {
    upload: string;
    guide: string;
    bucket: string;
    onSuccess: (downloadUrl: string) => void;
    onClear: () => void;
    onError: (err: string) => void;
};

enum UPLOADING_STATE {
    idle,
    uploading,
    compressing,
}

export const CoverThumbnailUpload: FunctionComponent<Props> = ({
    upload,
    guide,
    bucket,
    onSuccess,
    onClear,
    onError,
}) => {
    const [storageRef] = useState(storage.ref());
    const [error, setError] = useState<string | null>();
    const [uploadingState, setUploadingState] = useState<UPLOADING_STATE>(
        UPLOADING_STATE.idle
    );

    return (
        <div>
            <StyledInputLabel>Cover image</StyledInputLabel>
            <Wrapper>
                {upload.length > 0 && (
                    <PreviewImage>
                        <img
                            src={upload}
                            alt="Cover"
                            data-testid="previewImage"
                        />
                        <button
                            type="button"
                            onClick={onClear}
                            data-testid="clearPreviewImage"
                        >
                            <CrossSVG />
                        </button>
                    </PreviewImage>
                )}

                {upload.length === 0 && (
                    <>
                        <ThumbnailLabel htmlFor="uploadThumbnail">
                            {uploadingState === UPLOADING_STATE.idle && (
                                <>
                                    <UploadSVG />
                                    Upload photo
                                </>
                            )}

                            {uploadingState !== UPLOADING_STATE.idle && (
                                <>
                                    <Loading />
                                    <span>
                                        {(uploadingState ===
                                            UPLOADING_STATE.uploading &&
                                            'Uploading') ||
                                            'Compressing'}
                                    </span>
                                </>
                            )}
                        </ThumbnailLabel>

                        {!error && <Guide>{guide}</Guide>}

                        {error && <Error>{error}</Error>}
                    </>
                )}

                <HiddenInput
                    type="file"
                    name="uploadThumbnail"
                    accept="image/*"
                    data-testid="uploadThumbnail"
                    id="uploadThumbnail"
                    onChange={async (e) => {
                        setError(null);

                        if (e.target.files?.length === 0) {
                            return;
                        }

                        const file: File = e.target.files![0];
                        const workoutThumbnailRef = storageRef.child(
                            `${bucket}/${uuidv4()}/`
                        );

                        try {
                            setUploadingState(UPLOADING_STATE.uploading);
                            const { downloadUrl } = await uploadImageToStorage(
                                workoutThumbnailRef,
                                file,
                                () =>
                                    setUploadingState(
                                        UPLOADING_STATE.compressing
                                    )
                            );
                            onSuccess(downloadUrl);
                            setUploadingState(UPLOADING_STATE.idle);
                        } catch (err) {
                            onError(err as any);
                            setError(err as any);
                            setUploadingState(UPLOADING_STATE.idle);
                        }
                    }}
                />
            </Wrapper>
        </div>
    );
};
