import create from 'zustand';
import produce from 'immer';

type BaseSubscriberStore = {
    loading: boolean;
    setLoading: (loading: boolean) => void;
    setSubscriber: (subscriber: api.Subscriber) => void;
    setSubscriptions: (subscriptions: api.Subscription[]) => void;
    resetState: () => void;
};

type EmptySubscriberStore = BaseSubscriberStore & {
    loading: true;
    subscriber: null;
    subscriptions: null;
};

type HydratedSubscriberStore = BaseSubscriberStore & {
    loading: false;
    subscriber: api.Subscriber;
    subscriptions: api.Subscription[];
};

type SubscriberStore = EmptySubscriberStore | HydratedSubscriberStore;

export const useSubscriberStore = create<SubscriberStore>((set, get) => ({
    loading: true,
    subscriber: null,
    subscriptions: null,
    setLoading: (loading: boolean) => {
        set((baseState) =>
            produce(baseState, (state) => {
                state.loading = loading;
            })
        );
    },
    setSubscriber: (subscriber: api.Subscriber) => {
        set((baseState) =>
            produce(baseState, (state) => {
                state.subscriber = subscriber;
            })
        );
    },
    setSubscriptions: (subscriptions: api.Subscription[]) => {
        set((baseState) =>
            produce(baseState, (state) => {
                state.subscriptions = subscriptions;
            })
        );
    },
    resetState: () => {
        set((baseState) =>
            produce(baseState, (state) => {
                state.subscriber = null;
                state.subscriptions = null;
                state.loading = false;
            })
        );
    },
}));
