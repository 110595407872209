import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import config from './config';

const initFirebase = () => {
    if (!firebase.apps.length) {
        return firebase.initializeApp({
            apiKey: config.API_KEY,
            authDomain: config.AUTH_DOMAIN,
            projectId: config.PROJECT_ID,
            storageBucket: config.STORAGE_BUCKET,
            messagingSenderId: config.MESSAGING_SENDER_ID,
            appId: config.APP_ID,
            measurementId: config.MEASUREMENT_ID,
        });
    }

    return firebase.apps[0];
};

export default initFirebase;

export const auth = initFirebase().auth();
export const firestore = initFirebase().firestore();
export const storage = initFirebase().storage();
export const functions = initFirebase().functions();
