import { CreatorState } from '../creatorStore';

export const isCreatorRequiredToVerify = (state: CreatorState) => {
    if (state.creator === null) {
        return false;
    }

    return (
        state.creator.stripeAccount?.requirements?.currently_due?.some((r) =>
            r.includes('verification.additional_document')
        ) ||
        state.creator.stripeAccount?.requirements?.currently_due?.some((r) =>
            r.includes('verification.document')
        ) ||
        false
    );
};
