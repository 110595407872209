import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AppLayout from 'src/components/layouts/AppLayout';
import { PrimaryButton } from 'src/components/ui/Button/PrimaryButton';
import { Constrain } from 'src/components/ui/Constrain';
import { Loading } from 'src/components/ui/Loading';
import { LoadingPage } from 'src/components/ui/LoadingPage';
import { firestore } from 'src/firebase';
import styled from 'src/styles/styled-components';
import { getSymbolFromCurrencyCode } from 'src/utils/getSymbolFromCurrencyCode';
import { ReactComponent as CircleTickIcon } from '../svgs/icons/circle-tick.svg';

function getFormattedSubscriptionPrice(subscription: api.Subscription): string {
    return `${getSymbolFromCurrencyCode(
        subscription.currency as api.SupportedCurrencies
    )}${subscription.amount / 100}`;
}

const Wrapper = styled(Constrain)`
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;

    svg {
        height: 10rem;
        width: 10rem;
        margin-bottom: 2rem;
    }
`;

const Inner = styled.div`
    max-width: 40rem;
    text-align: center;
    padding-bottom: 10rem;

    p {
        text-align: left;
    }

    button {
        width: 100%;
    }
`;

const Heading = styled.h2`
    font-weight: bold;
    font-size: 2.4rem;
    margin: 0;
    margin-left: -0.1rem;
    margin-bottom: 2rem;
`;

const Subscription = () => {
    const [subscription, setSubscription] = useState<api.Subscription | null>(
        null
    );
    const [creatorProfile, setCreatorProfile] =
        useState<api.CreatorProfile | null>(null);
    const { id } = useParams<{ id: string }>();
    const { push } = useHistory();

    useEffect(() => {
        async function getSubscription() {
            const subscriptionDoc = await firestore
                .collection('subscriptions')
                .doc(id)
                .get();
            const subscriptionData = subscriptionDoc.data() as api.Subscription;
            setSubscription(subscriptionData);

            const profileDoc = await subscriptionData.profileRef.get();
            setCreatorProfile(profileDoc.data() as api.CreatorProfile);
        }

        if (id) {
            getSubscription();
        }
    }, [id]);

    if (!subscription || !subscription.stripeSubscription || !creatorProfile) {
        return <LoadingPage />;
    }

    return (
        <AppLayout
            title="Track my subscription | FLOCK.fitness"
            description="Tracking the progress of your subscription"
        >
            <Wrapper>
                {((subscription && !subscription.stripeSubscription) ||
                    !creatorProfile) && (
                    <Inner>
                        <Loading />

                        <Heading>Processing subscription</Heading>
                    </Inner>
                )}

                {subscription &&
                    subscription.stripeSubscription &&
                    creatorProfile && (
                        <Inner>
                            <CircleTickIcon stroke="black" />

                            <Heading>Subscription successful</Heading>

                            <p>
                                Great news, we’ve set up your subscription to{' '}
                                <strong>{creatorProfile.displayName}</strong>{' '}
                                for{' '}
                                {getFormattedSubscriptionPrice(subscription)} a
                                month.
                            </p>

                            <PrimaryButton onClick={() => push('/')}>
                                Get started
                            </PrimaryButton>
                        </Inner>
                    )}
            </Wrapper>
        </AppLayout>
    );
};

export default Subscription;
