import React, { FunctionComponent, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Constrain } from 'src/components/ui/Constrain';
import { Modal } from 'src/components/ui/Modal';
import { useUserStore } from 'src/store/userStore';
import styled from 'src/styles/styled-components';
import { Block } from 'src/components/ui/Block';
import { CreatorSettings } from './CreatorSettings';
import { Header } from '../../components/ui/Header';
import { SubscriberSettings } from './SubscriberSettings';
import { Text } from 'src/components/ui/Text';
import { media } from 'src/utils/mediaQueries';

const HeightConstrain = styled(Constrain)`
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const LogoutLink = styled(Link)`
    border: solid 2px ${({ theme }) => theme.colors.grey1};
    border-radius: 3px;
    padding: 0.8rem;
    text-align: center;
    display: block;
    font-weight: 500;
    font-size: ${({ theme }) => theme.font.size.level4.small};
    margin-top: auto;
    margin-bottom: 2rem;
    text-decoration: none;

    ${media.l`
        font-size: ${({ theme }) => theme.font.size.level4.large};
    `}
`;

const PrimaryLink = styled.a`
    text-decoration-color: ${({ theme }) => theme.colors.turquoise};
    text-decoration-thickness: 2px;
`;

export const Settings: FunctionComponent = () => {
    const [isOpen, setIsOpen] = useState(true);
    const history = useHistory();
    const { userType } = useUserStore();

    return (
        <Modal
            isOpen={isOpen}
            title="Settings"
            onClose={() => setIsOpen(false)}
            onAfterClose={history.goBack}
        >
            <HeightConstrain tiny noPadding>
                {userType === 'creator' && <CreatorSettings />}
                {userType === 'subscriber' && <SubscriberSettings />}

                <Block spacing="large">
                    <Header>Contact support</Header>

                    <Text>
                        If you have any questions or feedback, email us at{' '}
                        <PrimaryLink href="mailto: support@flock.fitness">
                            support@flock.fitness
                        </PrimaryLink>
                    </Text>
                </Block>

                <LogoutLink to="/logout">Log out</LogoutLink>
            </HeightConstrain>
        </Modal>
    );
};
