import React, { FunctionComponent, useState } from 'react';
import styled, { css } from 'src/styles/styled-components';
import { ReactComponent as ClockSVG } from 'src/svgs/icons/clock.svg';
import { ReactComponent as LockSVG } from 'src/svgs/icons/lock.svg';
import { media } from 'src/utils/mediaQueries';
import { Loading } from '../ui/Loading';
import formatDistance from 'date-fns/formatDistance';
import { ViewWorkoutModal } from 'src/routes/Profile/ViewWorkoutModal';

const Wrapper = styled.div<{ locked?: boolean }>`
    position: relative;
    display: flex;
    border-radius: 3px;
    overflow: hidden;
    aspect-ratio: 1.1/1;
    flex: 1;

    ${({ locked }) =>
        !locked &&
        css`
            &:hover {
                cursor: pointer;
            }
        `}

    @supports not (aspect-ratio: 1.1/1) {
        position: relative;

        &::before {
            float: left;
            padding-top: 77.25%;
            content: '';
        }
        &::after {
            display: block;
            content: '';
            clear: both;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
`;

const Detail = styled.div`
    position: absolute;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.45) 45.83%,
        #000000 100%
    );
    bottom: 0;
    left: 0;
    right: 0;
    color: white;
    padding: 1rem;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    small {
        font-size: 1.4rem;
    }
`;

const CoverImg = styled.img<{ blur: boolean }>`
    object-fit: cover;

    ${media.m`
        max-height: none;
    `}

    ${({ blur }) =>
        blur &&
        css`
            filter: blur(4px);
        `}
`;

const Name = styled.div`
    text-transform: uppercase;
    line-height: 1;
`;

const Duration = styled.div`
    padding: 0.5rem;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 3px;
    margin-left: 0.5rem;
    display: inline-flex;
    align-items: center;

    svg {
        margin-right: 0.5rem;
    }
`;

const TimeSince = styled.small`
    display: block;
    text-align: right;
    color: ${({ theme }) => theme.colors.grey3};
    margin-top: 3px;
`;

const Processing = styled.div`
    border-radius: 3px;
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.8rem 2rem;
    font-weight: 500;

    svg {
        margin-bottom: 0.5rem;
    }
`;

const Locked = styled.div`
    background-color: rgba(0, 0, 0, 0.8);
    position: absolute;
    left: 1rem;
    top: 1rem;
    color: white;
    font-weight: 500;
    padding: 0.5rem 0.7rem;
    display: flex;
    align-items: center;
    border-radius: 3px;
`;

type Props = {
    workout: flock.Workout;
    locked?: boolean;
    isDraft?: boolean;
    className?: string;
};

const UnstyledWorkout: FunctionComponent<Props> = ({
    className,
    workout,
    locked,
    isDraft,
}) => {
    const { name, thumbnail, asset, subtitle } = workout;
    const [isWatching, setIsWatching] = useState<boolean>(false);
    const isProcessing = asset.status !== 'ready';

    const duration = asset.tracks?.find(
        (track) => track.type === 'video'
    )?.duration;

    const minutes = Math.ceil(duration! / 60);

    return (
        <div className={className} data-testid="workout">
            <Wrapper
                locked={locked}
                onClick={() =>
                    (!isProcessing && !locked && setIsWatching(true)) ||
                    undefined
                }
            >
                <CoverImg
                    src={thumbnail}
                    alt={`${name} thumbnail`}
                    blur={isProcessing}
                />

                {locked && (
                    <Locked data-testid="locked">
                        <LockSVG />
                    </Locked>
                )}

                {isProcessing && (
                    <Processing>
                        <Loading color="white" />
                        <small>Processing video</small>
                    </Processing>
                )}

                <Detail>
                    <div>
                        <Name>{name}</Name>
                        {subtitle && <small>{subtitle}</small>}
                    </div>
                    {!isNaN(minutes) && (
                        <Duration>
                            <ClockSVG />
                            {minutes}
                        </Duration>
                    )}
                </Detail>
            </Wrapper>

            <TimeSince>
                {formatDistance(
                    (
                        workout.createdAt as firebase.default.firestore.Timestamp
                    ).toDate(),
                    new Date()
                )}{' '}
                ago
            </TimeSince>

            <ViewWorkoutModal
                workout={isWatching ? workout : null}
                noDelete={isDraft}
                dismiss={() => setIsWatching(false)}
            />
        </div>
    );
};

export const Workout = styled(UnstyledWorkout)`
    margin-bottom: 1.5rem;

    ${media.m`
        display: flex;
        flex-direction: column;
    `}
`;
