import create from 'zustand';
import produce from 'immer';

type BaseUserStateSlice = {
    setLoading: (loading: boolean) => void;
    setCreator: (creator: api.Creator) => void;
    setCreatorProfile: (profile: api.CreatorProfile) => void;
    setCreatorProfilePreview: (preview: api.CreatorPreviewVideo) => void;
    setWorkouts: (workouts: flock.Workout[]) => void;
    resetState: () => void;
};

type EmptyUserStateSlice = BaseUserStateSlice & {
    loading: true;
    creator: null;
    creatorProfile: null;
    creatorProfilePreview: null;
    workouts: null;
};

type HydratedCreatorStateSlice = BaseUserStateSlice & {
    loading: false;
    creator: api.Creator;
    creatorProfile: api.CreatorProfile;
    creatorProfilePreview: api.CreatorPreviewVideo | null;
    workouts: flock.Workout[];
};

export type CreatorState = EmptyUserStateSlice | HydratedCreatorStateSlice;

export const useCreatorStore = create<CreatorState>((set, get) => ({
    loading: true,
    creator: null,
    creatorProfile: null,
    creatorProfilePreview: null,
    workouts: null,
    setLoading: (loading: boolean) => {
        set((baseState) =>
            produce(baseState, (state) => {
                state.loading = loading;
            })
        );
    },
    setCreator: (creator: api.Creator) => {
        set((baseState) =>
            produce(baseState, (state) => {
                state.creator = creator;
            })
        );
    },
    setCreatorProfile: (creatorProfile: api.CreatorProfile) => {
        set((baseState) =>
            produce(baseState, (state) => {
                state.creatorProfile = creatorProfile;
            })
        );
    },
    setCreatorProfilePreview: (
        creatorProfilePreview: api.CreatorPreviewVideo
    ) => {
        set((baseState) =>
            produce(baseState, (state) => {
                state.creatorProfilePreview = creatorProfilePreview;
            })
        );
    },
    setWorkouts: (workouts: flock.Workout[]) => {
        set((baseState) =>
            produce(baseState, (state) => {
                state.workouts = workouts;
            })
        );
    },
    resetState: () => {
        set((baseState) =>
            produce(baseState, (state) => {
                state.creator = null;
                state.creatorProfile = null;
                state.workouts = [];
                state.loading = false;
            })
        );
    },
}));
