import styled, { css } from 'src/styles/styled-components';
import theme from 'src/styles/theme';

type Props = {
    alignment?: 'left' | 'center' | 'right';
    spacing?: keyof typeof theme.spacing;
};

export const Block = styled.div<Props>`
    ${({ spacing, theme }) =>
        spacing &&
        css`
            margin-bottom: ${theme.spacing[spacing]};
        `};
`;
