import React, { FunctionComponent } from 'react';
import { useSlate } from 'slate-react';
import styled, { css } from 'src/styles/styled-components';
import { toggleMark } from './utils/toggleMark';
import { toggleBlock } from './utils/toggleBlock';
import { isBlockActive } from './utils/isBlockActive';
import { isMarkActive } from './utils/isMarkActive';
import { LeafFormats, BlockFormats } from './RichTextEditor';

const EditorButton = styled.button<{ active: boolean }>`
    appearance: none;
    margin: 0;
    padding: 0.3rem;
    background-color: white;
    color: ${({ theme }) => theme.colors.grey3};
    border: 0;
    width: 2.5rem;
    border-radius: 3px;
    display: inline-flex;
    justify-content: center;

    svg {
        stroke: ${({ theme }) => theme.colors.grey3};
    }

    ${({ active, theme }) =>
        active &&
        css`
            color: ${theme.colors.grey1};
            background-color: ${theme.colors.grey5};

            svg {
                stroke: ${theme.colors.grey1};
            }
        `}
`;

export const MarkButton: FunctionComponent<{
    format: LeafFormats;
    children: React.ReactNode;
}> = ({ format, children }) => {
    const editor = useSlate();

    return (
        <EditorButton
            type="button"
            active={isMarkActive(editor, format)}
            onMouseDown={(event) => {
                event.preventDefault();
                toggleMark(editor, format);
            }}
        >
            {children}
        </EditorButton>
    );
};

export const BlockButton: FunctionComponent<{
    format: BlockFormats;
    children: React.ReactNode;
}> = ({ format, children }) => {
    const editor = useSlate();

    return (
        <EditorButton
            type="button"
            active={isBlockActive(editor, format)}
            onMouseDown={(event) => {
                event.preventDefault();
                toggleBlock(editor, format);
            }}
        >
            {children}
        </EditorButton>
    );
};
