import React, { useCallback, useState } from 'react';
import AppLayout from 'src/components/layouts/AppLayout';
import styled, { css } from 'src/styles/styled-components';
import instagramLogoPNG from 'src/images/instagram-icon.png';
import { Constrain } from 'src/components/ui/Constrain';
import { TertiaryButton } from 'src/components/ui/Button/TertiaryButton';
import { Link, useHistory } from 'react-router-dom';
import { Modal } from 'src/components/ui/Modal';
import { EditProfile } from 'src/components/EditProfile/EditProfile';
import { media } from 'src/utils/mediaQueries';
import { WorkoutGrid } from 'src/components/Workout/WorkoutGrid';
import { PreviewVideo } from 'src/components/PreviewVideo/PreviewVideo';
import { Workout } from 'src/components/Workout/Workout';
import { lighten } from 'polished';
import { ReactComponent as CirclePlusSVG } from 'src/svgs/icons/circle-plus.svg';
import { firestore } from 'src/firebase';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const Header = styled.header`
    background-color: white;
    padding: 0.8rem 0 1.5rem;
    border-bottom: 1px solid ${({ theme }) => theme.colors.black};
`;

const ProfileImage = styled.div<{ onClick?: () => void }>`
    width: 67px;
    height: 67px;
    border-radius: 50%;
    overflow: hidden;

    img {
        min-width: 100%;
        height: 100%;
        object-fit: cover;
    }

    ${({ onClick }) =>
        onClick &&
        css`
            cursor: pointer;
        `}
`;

const ImageBio = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;

    ${ProfileImage} {
        margin-right: 2rem;
        flex-shrink: 0;
    }

    h2 {
        font-family: 'Ruluko';
        font-weight: normal;
        margin-bottom: 0.3rem;
        font-size: 2rem;
    }
`;

const SubscriptionDetail = styled.div`
    display: flex;

    small {
        font-weight: 500;
        font-size: 1.3rem;
        line-height: 1.2;

        &:not(:first-child) {
            margin-left: 1rem;
        }
    }
`;

const ProfileInstaLink = styled.a`
    width: 3.8rem;
    height: 3.8rem;
    margin-left: auto;
`;

const ProfileBio = styled.div<{ noMargin?: boolean }>`
    max-width: 600px;

    p {
        font-size: ${({ theme }) => theme.font.size.level5.small};
        ${({ noMargin }) => noMargin && 'margin: 0;'}
    }
`;

const ProfileActions = styled.div`
    display: flex;
    gap: 1.5rem;
`;

const Body = styled(Constrain)`
    flex: 1;
    padding: 1.8rem;
    overflow: auto;

    ${media.l`
        padding: 3rem 1.8rem;
    `}
`;

const WorkoutPlaceholder = styled.div<{ num: number }>`
    text-align: right;

    .main {
        aspect-ratio: 1.1/1;
        border-radius: 3px;
        background-color: ${({ theme, num }) =>
            lighten(num, theme.colors.grey6)};
        margin-bottom: 1rem;

        @supports not (aspect-ratio: 1.1/1) {
            position: relative;

            &::before {
                float: left;
                padding-top: 77.25%;
                content: '';
            }
            &::after {
                display: block;
                content: '';
                clear: both;
            }
        }
    }

    .footer {
        width: 80px;
        margin-left: auto;
        height: 16px;
        border-radius: 3px;
        background-color: ${({ theme, num }) =>
            lighten(num, theme.colors.grey6)};
        margin-bottom: 1rem;
    }
`;

const Well = styled.div`
    border-radius: 3px;
    background-color: ${({ theme }) => theme.colors.grey6};
    padding: 2rem 5rem;
    text-align: center;
    max-width: 30rem;
    margin: 0 auto;
`;

const CreateWell = styled(Well)`
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1.1/1;
    max-width: none;
    margin-bottom: 1.5rem;

    @supports not (aspect-ratio: 1.1/1) {
        position: relative;

        &::before {
            float: left;
            padding-top: 95.4%;
            content: '';
        }
        &::after {
            display: block;
            content: '';
            clear: both;
        }
    }

    span {
        text-decoration: none;
    }

    svg {
        margin-bottom: 1rem;
    }
`;

type Props = {
    profile: api.CreatorProfile;
    workouts: flock.Workout[];
    previewVideo: api.CreatorPreviewVideo | null;
    setLoadedProfile: (updatedProfile: api.CreatorProfile) => void;
};

export const CreatorViewProfile = ({
    profile,
    workouts,
    previewVideo,
    setLoadedProfile,
}: Props) => {
    const { push } = useHistory();
    const [isEditing, setIsEditing] = useState<boolean>(false);

    const refetchProfile = useCallback(async () => {
        try {
            const profileSnapshot = await firestore
                .collection('creatorProfiles')
                .where('slug', '==', profile.slug)
                .get();

            const updatedProfile =
                profileSnapshot.docs[0].data() as api.CreatorProfile;
            setLoadedProfile(updatedProfile);
        } catch (err) {
            console.error(err);
        }
    }, [profile, setLoadedProfile]);

    const handleSuccessfulProfileUpdate = useCallback(
        async (slug?: string) => {
            setIsEditing(false);

            if (slug && slug !== profile.slug) {
                push(slug);
            } else {
                refetchProfile();
            }
        },
        [profile.slug, push, refetchProfile]
    );

    return (
        <AppLayout
            title={`${profile.displayName}'s profile | FLOCK.fitness`}
            description={profile.bio}
        >
            <Wrapper>
                <Header>
                    <Constrain>
                        <ImageBio>
                            <ProfileImage onClick={() => setIsEditing(true)}>
                                <img src={profile.image} alt="Profile" />
                            </ProfileImage>

                            <div>
                                <h2>{profile.displayName}</h2>

                                <SubscriptionDetail>
                                    <small>
                                        {profile.numberOfSubscribers}
                                        {(profile.numberOfSubscribers === 1 &&
                                            ' subscriber') ||
                                            ' subscribers'}
                                    </small>
                                </SubscriptionDetail>
                            </div>

                            {profile.instagram.length > 0 && (
                                <ProfileInstaLink
                                    href={`https://instagram.com/${profile.instagram}`}
                                    target="_blank"
                                    data-testid="instagramLink"
                                >
                                    <img
                                        src={instagramLogoPNG}
                                        alt="Instagram logo"
                                    />
                                </ProfileInstaLink>
                            )}
                        </ImageBio>

                        <ProfileBio>
                            <p>{profile.bio}</p>
                        </ProfileBio>

                        <ProfileActions>
                            <TertiaryButton onClick={() => setIsEditing(true)}>
                                Edit profile
                            </TertiaryButton>

                            <TertiaryButton
                                onClick={() => {
                                    push(
                                        `${profile.slug}/manage-preview-video`
                                    );
                                }}
                            >
                                {previewVideo === null ? 'Add' : 'Edit'} preview
                                video
                            </TertiaryButton>
                        </ProfileActions>
                    </Constrain>
                </Header>

                <Body>
                    <WorkoutGrid>
                        {previewVideo && (
                            <PreviewVideo previewVideo={previewVideo} />
                        )}

                        {!workouts.length && (
                            <>
                                <Link
                                    to="/create-workout"
                                    style={{ textDecoration: 'none' }}
                                >
                                    <CreateWell>
                                        <div>
                                            <CirclePlusSVG />
                                            <div>
                                                Create your first workout.
                                            </div>
                                        </div>
                                    </CreateWell>
                                </Link>

                                {[0.01, 0.02, 0.03, 0.04].map((num, i) => (
                                    <WorkoutPlaceholder num={num} key={i}>
                                        <div className="main"></div>
                                        <div className="footer"></div>
                                    </WorkoutPlaceholder>
                                ))}
                            </>
                        )}

                        {workouts
                            .filter(
                                (w) =>
                                    (w.asset.status === 'ready' ||
                                        w.asset.status === 'asset_created') &&
                                    w.status === 'published'
                            )
                            .sort(
                                (w1, w2) =>
                                    w2.createdAt.toDate().getTime() -
                                    w1.createdAt.toDate().getTime()
                            )
                            .map((workout, i) => (
                                <Workout workout={workout} key={i} />
                            ))}
                    </WorkoutGrid>
                </Body>
            </Wrapper>

            <Modal
                isOpen={isEditing}
                title="Edit profile"
                onClose={() => setIsEditing(false)}
            >
                <EditProfile
                    profile={profile}
                    onSuccess={handleSuccessfulProfileUpdate}
                />
            </Modal>
        </AppLayout>
    );
};
