import React from 'react';
import AppLayout from 'src/components/layouts/AppLayout';
import { Constrain } from 'src/components/ui/Constrain';
import { CreatorHome } from './Creator';
import { SubscriberHome } from './Subscriber';
import { AddToHomeScreen } from 'src/components/AddToHomeScreen/AddToHomeScreen';
import AuthLayout from 'src/components/layouts/AuthLayout';
import styled from 'src/styles/styled-components';
import { LoginForm } from 'src/components/LoginForm';
import flockMockup from 'src/images/flock-mockup.png';
import { media } from 'src/utils/mediaQueries';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { useUserStore } from 'src/store/userStore';

const Container = styled(Constrain)`
    max-width: 88rem;
    height: 100%;
    display: flex;
    position: relative;

    ${media.l`
        align-items: center;

        &:before {
            content: '';
            position: absolute;
            left: 50%;
            width: 1px;
            top: 2rem;
            bottom: 2rem;
            background-color: ${({ theme }) => theme.colors.grey5};
        }
    `}
`;

const Left = styled.div`
    display: none;
    flex: 1;
    width: 50%;
    margin-right: 10rem;
    position: relative;
    height: 354px;

    ${media.l`
        display: block;
    `}

    & img {
        position: absolute;
        left: -6rem;
        width: calc(100% + 7rem);
        right: 0;
        top: 50%;
        transform: translateY(-50%);

        ${media.xl`
            left: -20rem;
            width: calc(100% + 21rem);
        `}
    }
`;

const Right = styled.div`
    width: 50%;
    flex: 1;

    & > div {
        margin: 0 auto;
        max-width: 45rem;
    }

    .or-hr {
        position: relative;
        text-align: center;
        margin: 2rem 0;
        color: ${({ theme }) => theme.colors.grey3};

        &:before {
            content: '';
            position: absolute;
            z-index: -2;
            top: 45%;
            left: 0;
            right: 0;
            height: 1px;
            background-color: ${({ theme }) => theme.colors.grey5};
        }

        &:after {
            content: '';
            position: absolute;
            top: 42%;
            z-index: -1;
            left: 50%;
            width: 4rem;
            background-color: white;
            height: 3px;
            transform: translateX(-50%);
        }
    }

    .reset-password {
        text-align: center;
    }

    .learn-about-creators {
        margin-bottom: 4rem;
        padding: 1rem 2rem;
        background: #e9f8fa;
        border-radius: 3px;
        color: #007588;

        a {
            color: #007588;
        }

        div {
            margin-bottom: 3px;
        }
    }

    ${media.l`
        padding-left: 10rem;
    `}
`;

const StyledLink = styled(Link)`
    text-decoration-thickness: 2px;
    text-decoration-color: ${({ theme }) => theme.colors.turquoise};
`;

export const Home = () => {
    const { user, userType } = useUserStore();
    const { push } = useHistory();

    return (
        <>
            {!user && (
                <AuthLayout>
                    <Container>
                        <Left>
                            <img
                                src={flockMockup}
                                alt="FLOCK.fitness on display in a Desktop and mobile"
                            />
                        </Left>
                        <Right>
                            <div>
                                <div className="learn-about-creators">
                                    <div>
                                        <strong>Don't have an account?</strong>
                                    </div>
                                    <Link to="/creator">
                                        Learn more about how{' '}
                                        <strong>FLOCK</strong> works for
                                        creators &gt;
                                    </Link>
                                </div>

                                <LoginForm onSuccess={() => push('/')} />

                                <div className="or-hr">OR</div>

                                <div className="reset-password">
                                    <StyledLink to="/reset-password/request">
                                        Forgot your password?
                                    </StyledLink>
                                </div>
                            </div>
                        </Right>
                    </Container>
                </AuthLayout>
            )}

            {user && (
                <AppLayout
                    title={`Home | FLOCK.fitness`}
                    description="The home of your FLOCK.fitness"
                >
                    {userType === 'creator' && <CreatorHome />}
                    {userType === 'subscriber' && <SubscriberHome />}

                    <AddToHomeScreen />
                </AppLayout>
            )}
        </>
    );
};
