import React, { FunctionComponent } from 'react';
import AppLayout from 'src/components/layouts/AppLayout';
import { Constrain } from 'src/components/ui/Constrain';
import styled from 'src/styles/styled-components';

const Wrapper = styled.div`
    h1 {
        font-size: ${({ theme }) => theme.font.size.level2.small};
        font-weight: bold;
    }
`;

export const HealthDisclaimer: FunctionComponent = () => {
    return (
        <AppLayout description="Health considerations to make when using FLOCK.fitness">
            <Wrapper>
                <Constrain>
                    <h1>Health disclaimer</h1>

                    <p>
                        FLOCK.fitness strongly recommends that you consult with
                        your doctor before beginning any exercise program. You
                        should be in good physical condition and be able to
                        participate in the exercise. FLOCK.fitness is not a
                        licensed medical care provider and represents that it
                        has no expertise in diagnosing, examining, or treating
                        medical conditions of any kind, or in determining the
                        effect of any specific exercise on a medical condition.
                    </p>

                    <p>
                        You should understand that when participating in any
                        exercise or exercise program, there is the possibility
                        of physical injury. If you engage in this exercise or
                        exercise program, you agree that you do so at your own
                        risk, are voluntarily participating in these activities,
                        assume all risk of injury to yourself, and agree to
                        release and discharge FLOCK.fitness from any and all
                        claims or causes of action, known or unknown, arising
                        out of FLOCK.fitness’s negligence.
                    </p>
                </Constrain>
            </Wrapper>
        </AppLayout>
    );
};
