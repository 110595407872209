import React, { FunctionComponent } from 'react';
import { RenderLeafProps } from 'slate-react';

export const Leaf: FunctionComponent<RenderLeafProps> = ({
    attributes,
    children,
    leaf,
}) => {
    if (leaf.bold) {
        children = <strong>{children}</strong>;
    }

    if (leaf.italic) {
        children = <em>{children}</em>;
    }

    if (leaf.underline) {
        children = <u>{children}</u>;
    }

    return <span {...attributes}>{children}</span>;
};
