import styled from 'src/styles/styled-components';
import React, { FunctionComponent } from 'react';
import { StyledInputError } from './Input';
import tickSVG from 'src/svgs/icons/tick.svg';

interface InputProps
    extends React.DetailedHTMLProps<
        React.InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
    > {
    label: JSX.Element | string;
    error: string | null;
}

export const Label = styled.label`
    display: block;
    font-weight: 500;
    font-size: ${({ theme }) => theme.font.size.level5.small};
    padding-left: 2.8rem;

    &:before {
        content: '';
        position: absolute;
        left: 0;
        width: 1.4rem;
        height: 1.4rem;
        border: 2px solid ${({ theme }) => theme.colors.black};
        border-radius: 3px;
    }

    &:after {
        content: '';
        position: absolute;
        left: 3px;
        width: 1.2rem;
        height: 1.2rem;
        top: 40%;
        transform: translateY(-50%);
        background: url('${tickSVG}');
        background-repeat: no-repeat;
        background-position: center center;
        opacity: 0;
        transition: opacity 200ms;
    }
`;

const HiddenInput = styled.input`
    position: absolute;
    left: -9999px;
`;

export const CheckboxInput: FunctionComponent<InputProps> = styled(
    ({ label, error, name, className, ...props }: InputProps) => (
        <div className={className}>
            <HiddenInput
                name={name}
                id={`${name}-id`}
                type="checkbox"
                {...(props as any)}
            />
            <Label htmlFor={`${name}-id`}>{label}</Label>
            {error && <StyledInputError>{error}</StyledInputError>}
        </div>
    )
)<InputProps>`
    position: relative;
    margin-bottom: 2rem;

    input[type='checkbox']:checked + label:after {
        opacity: 1;
    }
`;
