import React from 'react';
import { Field, FieldProps, Formik, FormikProps } from 'formik';
import { Input } from 'src/components/ui/Input/Input';
import { FormActions } from 'src/components/ui/FormActions';
import { PrimaryButton } from 'src/components/ui/Button/PrimaryButton';
import { Text } from 'src/components/ui/Text';
import { Header } from 'src/components/ui/Header';
import { BasicNameInformation, ToNullable } from './Onboarding';
import * as Yup from 'yup';

export const nameValidationSchema = Yup.object().shape({
    firstName: Yup.string().required('Please enter your first name'),
    lastName: Yup.string().required('Please enter your last name'),
});

export const INITIAL_NAME_VALUES = {
    firstName: '',
    lastName: '',
};

type Props = {
    basicNameInfo: ToNullable<BasicNameInformation>;
    handleNameEntry: (values: BasicNameInformation) => void;
};

export const OnboardingNameForm = ({
    basicNameInfo,
    handleNameEntry,
}: Props) => {
    return (
        <>
            <Header size="level3" alignment="center" spacing="medium">
                Thanks for joining us!
            </Header>
            <Text alignment="center" spacing="medium">
                There’s a few steps you’ll need to take to complete your setup
                but for now <strong>let’s start with your name</strong>.
            </Text>
            <Formik
                initialValues={
                    basicNameInfo.firstName !== null &&
                    basicNameInfo.lastName !== null
                        ? ({ ...basicNameInfo } as BasicNameInformation)
                        : { ...INITIAL_NAME_VALUES }
                }
                validationSchema={nameValidationSchema}
                onSubmit={(values) => handleNameEntry(values)}
            >
                {({
                    submitCount,
                    handleSubmit,
                    isSubmitting,
                }: FormikProps<typeof INITIAL_NAME_VALUES>) => (
                    <form onSubmit={handleSubmit} noValidate>
                        <Field name="firstName">
                            {({ field, meta }: FieldProps) => (
                                <Input
                                    label="First name"
                                    type="text"
                                    error={
                                        (submitCount > 0 && meta.error) || null
                                    }
                                    {...field}
                                />
                            )}
                        </Field>

                        <Field name="lastName">
                            {({ field, meta }: FieldProps) => (
                                <Input
                                    label="Last name"
                                    type="text"
                                    error={
                                        (submitCount > 0 && meta.error) || null
                                    }
                                    {...field}
                                />
                            )}
                        </Field>

                        <FormActions>
                            <PrimaryButton
                                type="submit"
                                isSubmitting={isSubmitting}
                            >
                                Continue
                            </PrimaryButton>
                        </FormActions>
                    </form>
                )}
            </Formik>
        </>
    );
};
