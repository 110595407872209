import React from 'react';
import AppLayout from 'src/components/layouts/AppLayout';
import { Constrain } from 'src/components/ui/Constrain';
import styled, { css } from 'src/styles/styled-components';
import instagramLogoPNG from 'src/images/instagram-icon.png';
import { media } from 'src/utils/mediaQueries';
import { WorkoutGrid } from 'src/components/Workout/WorkoutGrid';
import { Workout } from 'src/components/Workout/Workout';
import { PreviewVideo } from 'src/components/PreviewVideo/PreviewVideo';
import { SecondaryButton } from 'src/components/ui/Button/SecondaryButton';
import { getSymbolFromCurrencyCode } from 'src/utils/getSymbolFromCurrencyCode';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const Header = styled.header`
    background-color: white;
    padding: 0.8rem 0 1.5rem;
    border-bottom: 1px solid ${({ theme }) => theme.colors.black};
`;

const ProfileImage = styled.div<{ isClickable?: boolean }>`
    width: 67px;
    height: 67px;
    border-radius: 50%;
    overflow: hidden;

    img {
        min-width: 100%;
        height: 100%;
        object-fit: cover;
    }

    ${({ isClickable }) =>
        isClickable &&
        css`
            cursor: pointer;
        `}
`;

const ImageBio = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;

    ${ProfileImage} {
        margin-right: 2rem;
        flex-shrink: 0;
    }

    h2 {
        font-family: 'Ruluko';
        font-weight: normal;
        margin-bottom: 0.3rem;
        font-size: 2rem;
    }
`;

const SubscriptionDetail = styled.div`
    display: flex;

    small {
        font-weight: 500;
        font-size: 1.3rem;
        line-height: 1.2;

        &:not(:first-child) {
            margin-left: 1rem;
        }
    }
`;

const ProfileInstaLink = styled.a`
    width: 3.8rem;
    height: 3.8rem;
    margin-left: auto;
`;

const ProfileBio = styled.div<{ noMargin?: boolean }>`
    max-width: 600px;

    p {
        font-size: ${({ theme }) => theme.font.size.level5.small};
        ${({ noMargin }) => noMargin && 'margin: 0;'}
    }
`;

const Body = styled(Constrain)`
    flex: 1;
    padding: 1.8rem;
    overflow: auto;

    ${media.l`
        padding: 3rem 1.8rem;
    `}
`;

type Props = {
    profile: api.CreatorProfile;
    workouts: flock.Workout[];
    previewVideo: api.CreatorPreviewVideo | null;
    isSubscribed: boolean;
    isSubscribing: boolean;
    handleSubscribe: () => void;
};

export const SubscriberViewProfile = ({
    profile,
    workouts,
    previewVideo,
    isSubscribed,
    isSubscribing,
    handleSubscribe,
}: Props) => {
    return (
        <AppLayout
            title={`${profile.displayName}'s profile | FLOCK.fitness`}
            description={profile.bio}
        >
            <Wrapper>
                <Header>
                    <Constrain>
                        <ImageBio>
                            <ProfileImage>
                                <img src={profile.image} alt="Profile" />
                            </ProfileImage>

                            <div>
                                <h2>{profile.displayName}</h2>

                                <SubscriptionDetail>
                                    <small>
                                        {profile.numberOfSubscribers}
                                        {(profile.numberOfSubscribers === 1 &&
                                            ' subscriber') ||
                                            ' subscribers'}
                                    </small>
                                </SubscriptionDetail>
                            </div>

                            {profile.instagram.length > 0 && (
                                <ProfileInstaLink
                                    href={`https://instagram.com/${profile.instagram}`}
                                    target="_blank"
                                    data-testid="instagramLink"
                                >
                                    <img
                                        src={instagramLogoPNG}
                                        alt="Instagram logo"
                                    />
                                </ProfileInstaLink>
                            )}
                        </ImageBio>

                        <ProfileBio>
                            <p>{profile.bio}</p>
                        </ProfileBio>

                        {profile.products.length > 0 && !isSubscribed && (
                            <SecondaryButton
                                onClick={() => handleSubscribe()}
                                isSubmitting={isSubscribing}
                                data-testid="subscribeBtn"
                            >
                                Access all workouts for{' '}
                                {getSymbolFromCurrencyCode(
                                    profile.products[0].currencyCode
                                )}
                                {profile.products[0].amount / 100}
                                /month
                            </SecondaryButton>
                        )}
                    </Constrain>
                </Header>

                <Body>
                    <WorkoutGrid>
                        {previewVideo && (
                            <PreviewVideo previewVideo={previewVideo} />
                        )}

                        {workouts
                            .filter(
                                (w) =>
                                    w.asset.status === 'ready' &&
                                    w.status === 'published'
                            )
                            .sort(
                                (w1, w2) =>
                                    w2.createdAt.toDate().getTime() -
                                    w1.createdAt.toDate().getTime()
                            )
                            .map((workout, i) => (
                                <Workout
                                    workout={workout}
                                    key={i}
                                    locked={!isSubscribed}
                                />
                            ))}
                    </WorkoutGrid>
                </Body>
            </Wrapper>
        </AppLayout>
    );
};
