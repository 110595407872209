import { Field, FieldProps, Formik, FormikProps } from 'formik';
import React, { useState } from 'react';
import { PrimaryButton } from 'src/components/ui/Button/PrimaryButton';
import {
    Input,
    InputHint,
    StyledInputLabel,
} from 'src/components/ui/Input/Input';
import { LoadingPage } from 'src/components/ui/LoadingPage';
import { Select } from 'src/components/ui/Select';
import { functions } from 'src/firebase';
import styled from 'src/styles/styled-components';
import { ReactComponent as CircleAlertSVG } from 'src/svgs/icons/circle-alert.svg';
import { GoToDashboardButton } from 'src/components/GoToDashboardButton';
import * as Yup from 'yup';
import { Header } from 'src/components/ui/Header';
import { Text } from 'src/components/ui/Text';

import { media } from 'src/utils/mediaQueries';
import { Tooltip } from 'src/components/ui/Tooltip';
import { useCreatorStore } from 'src/store/creatorStore';
import { isCreatorRequiredToVerify } from 'src/store/selectors/isCreatorRequiredToVerify';
import { isCreatorPendingVerification } from 'src/store/selectors/isCreatorPendingVerification';

const SubscriptionInput = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    select {
        width: 9rem;
        margin-right: 1rem;
    }

    ${Select}, ${Input} {
        margin-bottom: 0;
    }

    input {
        width: 9rem;
        margin-right: 1rem;
    }
`;

const SubscriptionPreview = styled.div`
    display: inline-flex;
    font-weight: 500;

    .amount {
        display: inline-block;
        margin-right: 0.7rem;
    }
`;

const Alert = styled.div`
    border-radius: 3px;
    background-color: ${({ theme }) => theme.colors.pinkRed};
    color: white;
    display: flex;
    padding: 0.9rem 1.1rem;
    font-weight: 500;
    box-shadow: ${({ theme }) => theme.effects.redShadow};
    margin-bottom: 1.8rem;

    & > svg {
        margin-right: 1rem;
    }
`;

const Notification = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.paleYellow};
    padding: 0.9rem 1.1rem;
    border-radius: 3px;
    box-shadow: ${({ theme }) => theme.effects.greyShadow};
    margin-bottom: 1.8rem;

    & > div {
        display: flex;
        align-items: center;
        font-weight: 500;
        margin-bottom: 1rem;

        svg {
            margin-right: 1rem;
        }
    }

    h5 {
        font-size: 1.4rem;
        font-weight: 500;
        margin-bottom: 0.5rem;
        line-height: 1.3;
    }

    p {
        font-size: 1.4rem;
        margin: 0;
        line-height: 1.3;
    }
`;

const PriceExample = styled.div`
    font-size: 1.2rem;

    ${media.m`
        font-size: 1.4rem;
    `}

    p {
        margin-bottom: 0.3rem;
    }

    .intro {
        margin-bottom: 0.6rem;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;

        & > li {
            margin-bottom: 1rem;

            &:not(:first-child) {
                margin-left: 1.5rem;
            }
        }
    }
`;

const Notice = styled.div`
    padding: 1.5rem 1.3rem;
    font-size: 1.4rem;
    background-color: ${({ theme }) => theme.colors.grey6};

    strong {
        font-weight: 500;
    }
`;

const Error = styled.p`
    color: ${({ theme }) => theme.colors.pinkRed};
    text-align: center;
    margin: 1.2rem 0 -1.2rem;
`;

const Button = styled(PrimaryButton)`
    flex: 1;
    width: 100%;
    margin: 2rem 0;
`;

export type Currency = {
    code: 'GBP' | 'USD';
    symbol: '£' | '$';
};

export const currencies: Currency[] = [
    {
        code: 'GBP',
        symbol: '£',
    },
    {
        code: 'USD',
        symbol: '$',
    },
];

const INITIAL_VALUES = {
    price: '' as any as number,
    currency: currencies[0].code,
};

const validationSchema = Yup.object().shape({
    price: Yup.number().integer('Must be a whole number').max(30).min(6),
});

function formatPrice(value: number) {
    return value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

function amountWithoutVAT(price: number): number {
    return price / 1.2;
}

export const CreatorSettings = () => {
    const [formError, setFormError] = useState<string | null>(null);
    const creator = useCreatorStore((state) => state.creator);
    const requiresVerification = useCreatorStore(isCreatorRequiredToVerify);
    const pendingVerification = useCreatorStore(isCreatorPendingVerification);
    const loading = useCreatorStore((state) => state.loading);
    const creatorProfile = useCreatorStore((state) => state.creatorProfile);

    const createSubscriptionFn = functions.httpsCallable(
        'setSubscriptionPrice'
    );

    const subscriptionProduct =
        (creatorProfile && creatorProfile.products[0]) || false;

    if (loading) {
        return <LoadingPage />;
    }

    return (
        <>
            {requiresVerification && (
                <Alert>
                    <CircleAlertSVG stroke="white" />
                    <div>Verify Identity</div>

                    <GoToDashboardButton>Fix on dashboard</GoToDashboardButton>
                </Alert>
            )}
            {pendingVerification && (
                <Notification>
                    <div>
                        <CircleAlertSVG stroke="#333333" />
                        <div>ID verification pending</div>
                    </div>

                    <h5>
                        We’ll notify you via email when your account has been
                        verified
                    </h5>
                    <p>
                        In the meantime you can create workouts, your profile is
                        live but you won't receive payouts until you have been
                        verified.
                    </p>
                </Notification>
            )}

            {creator && creator.stripeAccount && !subscriptionProduct && (
                <Alert>
                    <CircleAlertSVG stroke="white" />
                    Set a subscription price
                </Alert>
            )}

            <Header>My subscription</Header>
            {creator && creator.stripeAccount && (
                <Formik
                    initialValues={
                        subscriptionProduct
                            ? {
                                  price: subscriptionProduct.amount / 100,
                                  currency: subscriptionProduct.currencyCode,
                              }
                            : INITIAL_VALUES
                    }
                    validationSchema={validationSchema}
                    onSubmit={async (values, actions) => {
                        setFormError(null);

                        try {
                            await createSubscriptionFn({
                                creatorId: creator!.id,
                                amount: values.price,
                                currencyCode: values.currency,
                            });
                        } catch (err: any) {
                            setFormError(err.message);
                        }
                    }}
                >
                    {({
                        values,
                        submitCount,
                        handleSubmit,
                        isSubmitting,
                    }: FormikProps<typeof INITIAL_VALUES>) => {
                        const currencySymbol = currencies.find(
                            (currency) => currency.code === values.currency
                        )!.symbol;

                        return (
                            <form onSubmit={handleSubmit} noValidate>
                                <StyledInputLabel hasHint>
                                    Subscription price (per month)
                                </StyledInputLabel>
                                {!subscriptionProduct && (
                                    <InputHint>
                                        Set the price you would like your
                                        subscribers to pay per month.
                                    </InputHint>
                                )}

                                {subscriptionProduct && (
                                    <InputHint>
                                        Get in touch with us to update your
                                        subscription price
                                    </InputHint>
                                )}

                                <SubscriptionInput>
                                    {!subscriptionProduct && (
                                        <>
                                            <Field name="currency">
                                                {({
                                                    field,
                                                    meta,
                                                }: FieldProps) => (
                                                    <Select
                                                        options={currencies.map(
                                                            (currency) => ({
                                                                value: currency.code,
                                                                label: currency.code,
                                                            })
                                                        )}
                                                        {...field}
                                                        disabled={
                                                            !!subscriptionProduct
                                                        }
                                                    />
                                                )}
                                            </Field>

                                            <Field name="price">
                                                {({
                                                    field,
                                                    meta,
                                                }: FieldProps) => (
                                                    <Input
                                                        type="number"
                                                        placeholder="--.--"
                                                        error={
                                                            (submitCount > 0 &&
                                                                meta.error) ||
                                                            null
                                                        }
                                                        {...field}
                                                        min={7}
                                                        max={40}
                                                        step={1}
                                                        disabled={
                                                            !!subscriptionProduct
                                                        }
                                                        data-testid="price"
                                                    />
                                                )}
                                            </Field>
                                        </>
                                    )}

                                    <SubscriptionPreview>
                                        <span className="amount">
                                            {currencySymbol}
                                            {values.price || '--'}/month
                                        </span>
                                        {!subscriptionProduct && values.price && (
                                            <Tooltip>
                                                <PriceExample>
                                                    <p className="intro">
                                                        <strong>
                                                            FLOCK.fitness
                                                        </strong>{' '}
                                                        is responsible for
                                                        collecting VAT (where
                                                        applicable) on your
                                                        subscriptions.
                                                    </p>

                                                    <p>
                                                        <strong>
                                                            A subscriber based
                                                            in the UK (and must
                                                            pay 20% UK VAT)
                                                        </strong>
                                                    </p>

                                                    <ul data-testid="example1">
                                                        <li>
                                                            You receive:{' '}
                                                            {currencySymbol}
                                                            {values.price
                                                                ? formatPrice(
                                                                      Math.floor(
                                                                          (values.price -
                                                                              (values.price -
                                                                                  amountWithoutVAT(
                                                                                      values.price
                                                                                  ) +
                                                                                  (values.price -
                                                                                      (values.price -
                                                                                          amountWithoutVAT(
                                                                                              values.price
                                                                                          ))) *
                                                                                      0.2)) *
                                                                              100
                                                                      ) / 100
                                                                  )
                                                                : '--'}
                                                        </li>
                                                        <li>
                                                            <strong>
                                                                FLOCK
                                                            </strong>{' '}
                                                            receives:{' '}
                                                            {currencySymbol}
                                                            {values.price
                                                                ? formatPrice(
                                                                      (values.price -
                                                                          (values.price -
                                                                              amountWithoutVAT(
                                                                                  values.price
                                                                              ))) *
                                                                          0.2
                                                                  )
                                                                : '--'}
                                                        </li>
                                                        <li>
                                                            VAT paid:{' '}
                                                            {currencySymbol}
                                                            {values.price
                                                                ? formatPrice(
                                                                      values.price -
                                                                          amountWithoutVAT(
                                                                              values.price
                                                                          )
                                                                  )
                                                                : '--'}
                                                        </li>
                                                    </ul>

                                                    <p>
                                                        <strong>
                                                            A subscriber based
                                                            outside of the UK/EU
                                                            (no VAT)
                                                        </strong>
                                                    </p>

                                                    <ul data-testid="example2">
                                                        <li>
                                                            You receive:{' '}
                                                            {currencySymbol}
                                                            {values.price
                                                                ? formatPrice(
                                                                      values.price *
                                                                          0.8
                                                                  )
                                                                : '--'}
                                                        </li>
                                                        <li>
                                                            <strong>
                                                                FLOCK
                                                            </strong>{' '}
                                                            receives:{' '}
                                                            {currencySymbol}
                                                            {values.price
                                                                ? formatPrice(
                                                                      values.price *
                                                                          0.2
                                                                  )
                                                                : '--'}
                                                        </li>
                                                    </ul>
                                                </PriceExample>
                                            </Tooltip>
                                        )}
                                    </SubscriptionPreview>
                                </SubscriptionInput>

                                {!subscriptionProduct && (
                                    <Notice>
                                        <strong>
                                            You will need to get in touch with
                                            us to update your subscription price
                                            once it’s been set
                                        </strong>
                                    </Notice>
                                )}

                                {formError && <Error>{formError}</Error>}

                                {!subscriptionProduct && (
                                    <Button
                                        type="submit"
                                        isSubmitting={isSubmitting}
                                    >
                                        Save
                                    </Button>
                                )}
                            </form>
                        );
                    }}
                </Formik>
            )}

            {creator && !creator.stripeAccount && (
                <Text spacing="medium" color="black">
                    You need to link your bank information before you can set a
                    price for your subscription.
                </Text>
            )}
        </>
    );
};
