import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { RouteComponentProps } from 'react-router-dom';
import config from './config';

if (config.MODE === 'prod') {
    ReactGA.initialize('G-GJJL5J1TN5');
}

const withTracker = <P extends RouteComponentProps>(
    WrappedComponent: React.ComponentType<P>,
    options = {}
) => {
    const trackPage = (page: string) => {
        if (config.MODE === 'dev') {
            console.info('Skip tracking');
            return;
        }

        ReactGA.set({ page, ...options });
        ReactGA.send('pageview');
    };

    return (props: P) => {
        useEffect(() => {
            trackPage(props.location.pathname);
        }, [props.location.pathname]);

        return <WrappedComponent {...props} />;
    };
};

export default withTracker;
