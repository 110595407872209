import React, { FunctionComponent, useState } from 'react';
import styled from 'src/styles/styled-components';
import { ReactComponent as ChevronRightSVG } from 'src/svgs/icons/chevron-right.svg';
import { motion, Variants } from 'framer-motion';

const FAQItemWrapper = styled.div<{ isOpen: boolean }>`
    border-width: 1px;
    border-style: solid;
    border-color: ${({ isOpen, theme }) =>
        isOpen ? theme.colors.blue : '#dddddd'};
    border-radius: 3px;
    margin-bottom: 2rem;
    overflow: hidden;
    cursor: pointer;
    padding: 2rem;

    .heading {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        h4 {
            margin: 0;
            font-size: 1.6rem;
            text-align: left;
            font-weight: 500;
            transition: margin-bottom 400ms;
            margin-bottom: ${({ isOpen }) => (isOpen ? '1rem' : '0rem')};
        }

        svg {
            transition: transform 200ms;
            transform: ${({ isOpen }) =>
                isOpen ? 'rotate(90deg)' : 'rotate(0deg)'};
        }
    }

    p {
        margin: 0;
        font-size: 1.4rem;
    }
`;

const variants: Variants = {
    open: { opacity: 1, height: 'auto' },
    closed: { opacity: 0, height: 0 },
};

export const FAQItem: FunctionComponent<{
    question: string;
    answer: string;
}> = ({ question, answer }) => {
    const [toggle, setToggle] = useState<boolean>(false);

    return (
        <FAQItemWrapper isOpen={toggle} onClick={() => setToggle(!toggle)}>
            <div className="heading">
                <h4>{question}</h4>
                <ChevronRightSVG />
            </div>

            <motion.p
                animate={toggle ? 'open' : 'closed'}
                variants={variants}
                transition={{ ease: 'anticipate', duration: 0.4 }}
                dangerouslySetInnerHTML={{ __html: answer }}
            ></motion.p>
        </FAQItemWrapper>
    );
};
