import { Route, Switch } from 'react-router';
import { useRouteMatch } from 'react-router-dom';
import { RequestNewPassword } from './RequestNewPassword';
import { ResetNewPassword } from './ResetPassword';

export const ResetPassword = () => {
    let match = useRouteMatch();

    return (
        <Switch>
            <Route
                path={`${match.path}/request`}
                component={RequestNewPassword}
            />
            <Route path={`${match.path}/reset`} component={ResetNewPassword} />
        </Switch>
    );
};
