import React, {
    FunctionComponent,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { Modal } from 'src/components/ui/Modal';
import { CreateWorkoutForm } from 'src/components/CreateWorkoutForm/CreateWorkoutForm';
import { LoadingPage } from 'src/components/ui/LoadingPage';
import { PreviewWorkout } from './PreviewWorkout';
import { firestore } from 'src/firebase';
import { useCreatorStore } from 'src/store/creatorStore';

enum DISPLAY_STATE {
    create,
    loading,
    preview,
}

export const CreateWorkout: FunctionComponent = () => {
    const { creatorProfile } = useCreatorStore();
    const [isOpen, setIsOpen] = useState(true);
    const [displayState, setDisplayState] = useState<DISPLAY_STATE>(
        DISPLAY_STATE.create
    );
    const [draftWorkout, setDraftWorkout] = useState<flock.Workout | null>(
        null
    );
    const [createdWorkoutId, setCreatedWorkoutId] = useState<string | null>(
        null
    );
    const history = useHistory();

    const handleSuccessfulSave = useCallback(
        async (workoutId: string) => {
            // Skip loading state if editing a workout (updates should come through automatically)
            if (createdWorkoutId === workoutId) {
                setDisplayState(DISPLAY_STATE.preview);
                return;
            }

            setDisplayState(DISPLAY_STATE.loading);
            setCreatedWorkoutId(workoutId);
        },
        [createdWorkoutId]
    );

    useEffect(() => {
        if (createdWorkoutId !== null) {
            return firestore
                .collection('workouts')
                .doc(createdWorkoutId)
                .onSnapshot((workoutDocument) => {
                    setDraftWorkout(workoutDocument.data() as flock.Workout);
                    setDisplayState(DISPLAY_STATE.preview);
                });
        }
    }, [createdWorkoutId]);

    const publish = useCallback(async (draftWorkout) => {
        await firestore
            .collection('workouts')
            .doc(draftWorkout.id)
            .set({ status: 'published' }, { merge: true });

        setIsOpen(false);
    }, []);

    return (
        <Modal
            isOpen={isOpen}
            title="Create a workout"
            onClose={() => setIsOpen(false)}
            onAfterClose={() => history.push(`/${creatorProfile?.slug}`)}
        >
            {displayState === DISPLAY_STATE.create && (
                <CreateWorkoutForm
                    draftWorkout={draftWorkout}
                    onSuccess={handleSuccessfulSave}
                />
            )}

            {displayState === DISPLAY_STATE.loading && <LoadingPage />}

            {displayState === DISPLAY_STATE.preview && draftWorkout && (
                <PreviewWorkout
                    onBack={() => setDisplayState(DISPLAY_STATE.create)}
                    draftWorkout={draftWorkout}
                    publish={async () => await publish(draftWorkout)}
                />
            )}
        </Modal>
    );
};
