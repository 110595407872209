/* tslint:disable:object-literal-sort-keys */
const theme = {
    colors: {
        white: '#ffffff',
        grey0: '#d9d9d9',
        grey1: '#333333',
        grey3: '#828282',
        grey5: '#E0E0E0',
        grey6: '#F2F2F2',
        dark: '#4f4f4f',
        text: '#000000',
        black: '#000000',
        turquoise: '#47FFB2',
        pinkRed: '#D4366A',
        paleYellow: '#ffefb6',
        blue: '#25B5CC',
        blue02: '#6AB1BE',
        darkBlue: '#007588',
    },
    font: {
        type: '"Roboto", sans-serif',
        size: {
            level1: { small: '3rem', large: '3.2rem' },
            level2: { small: '2.6rem', large: '3rem' },
            level3: { small: '2.2rem', large: '2.6rem' },
            level4: { small: '1.6rem', large: '1.8rem' },
            level5: { small: '1.4rem', large: '1.6rem' },
            level6: { small: '1.2rem', large: '1.4rem' },
            base: '10px',
        },
    },
    fontSerif: {
        type: '"Ruluko", serif',
        size: {
            level1: '3rem',
            level2: '2.4rem',
            level3: '1.8rem',
            level4: '1.6rem',
            level5: '1.4rem',
            level6: '1.2rem',
            base: '10px',
        },
    },
    effects: {
        redShadow: '0px 0px 7px rgba(212, 54, 106, 0.6)',
        greyShadow: '0px 0px 7px rgba(100, 100, 100, 0.2)',
        blueShadow: '0px 0px 7px rgba(37, 181, 204, 0.4)',
    },
    spacing: {
        small: '10px',
        medium: '20px',
        large: '30px',
        xLarge: '40px',
        xxLarge: '50px',
    },
};

export default theme;
