import styled from 'src/styles/styled-components';

export const Notice = styled.div`
    padding: 1.5rem 1.3rem;
    font-size: 1.4rem;
    background-color: ${({ theme }) => theme.colors.grey6};
    border-radius: 3px;

    strong {
        font-weight: 500;
    }
`;

export const BlueNotice = styled.div`
    background-color: ${({ theme }) => theme.colors.blue};
    box-shadow: ${({ theme }) => theme.effects.blueShadow};
    border-radius: 3px;
    padding: 1rem 1.2rem;
    color: white;
    font-weight: 500;
    margin-bottom: 2rem;

    a {
        color: white;
    }
`;

export const RedNotice = styled.div`
    background-color: ${({ theme }) => theme.colors.pinkRed};
    box-shadow: ${({ theme }) => theme.effects.redShadow};
    border-radius: 3px;
    padding: 1rem 1.2rem;
    color: white;
    font-weight: 500;
    margin-bottom: 2rem;

    a {
        color: white;
    }
`;
