import { Editor, Transforms, Element as SlateElement } from 'slate';
import { BlockFormats, LIST_TYPES } from '../RichTextEditor';
import { isBlockActive } from './isBlockActive';

export const toggleBlock = (editor: Editor, format: BlockFormats) => {
    const isActive = isBlockActive(editor, format);
    const isList = LIST_TYPES.includes(format);

    Transforms.unwrapNodes(editor, {
        match: (n) =>
            LIST_TYPES.includes(
                ((!Editor.isEditor(n) &&
                    SlateElement.isElement(n) &&
                    n.type) as string | false) || ''
            ),
        split: true,
    });
    const newProperties: Partial<SlateElement> = {
        type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    };
    Transforms.setNodes(editor, newProperties);

    if (!isActive && isList) {
        const block = { type: format, children: [] };
        Transforms.wrapNodes(editor, block);
    }
};
