import React from 'react';
import config from 'src/config';
import styled from 'src/styles/styled-components';
import videojs, { VideoJsPlayer } from 'video.js';
import PlayActionSVGSRC from 'src/svgs/icons/play-action.svg';

type Props = {
    workoutOrPreview: flock.Workout | api.CreatorPreviewVideo;
    autoPlay: boolean;
};

class VideoPlayer extends React.Component<Props> {
    public player!: VideoJsPlayer;
    public videoNode: any;

    componentDidMount() {
        // instantiate Video.js
        const videoJsOptions = {
            autoplay: this.props.autoPlay,
            controls: true,
            fill: true,
            // aspectRatio: '1:1',
            // aspectRatio: this.props.workout.asset.aspect_ratio,
            sources: [
                {
                    src: `https://stream.mux.com/${
                        this.props.workoutOrPreview.asset.playback_ids![0].id
                    }.m3u8`,
                    type: 'application/x-mpegURL',
                },
            ],
            plugins: {
                mux: {
                    debug: config.MODE !== 'prod',
                    data: {
                        env_key:
                            config.MODE === 'prod'
                                ? '9l50htliodqgl4gpr4htboeej'
                                : '377pti0goc9cfo893tvoldqsm',

                        // Metadata
                        player_name: 'Workout player',
                        player_init_time: new Date(),
                    },
                },
            },
        };

        this.player = videojs(
            this.videoNode,
            videoJsOptions,
            function onPlayerReady() {
                console.log('onPlayerReady');
            }
        );

        // this.player.play()!.then().catch();
    }

    // destroy player on unmount
    componentWillUnmount() {
        if (this.player) {
            this.player.dispose();
        }
    }

    // wrap the player in a div with a `data-vjs-player` attribute
    // so videojs won't create additional wrapper in the DOM
    // see https://github.com/videojs/video.js/pull/3856
    render() {
        return (
            <div data-vjs-player>
                <video
                    ref={(node) => (this.videoNode = node)}
                    className="video-js"
                ></video>
            </div>
        );
    }
}

export const FlockVideoPlayer = styled(({ className, ...props }) => {
    return (
        <div className={className}>
            <VideoPlayer {...props} />
        </div>
    );
})<Props>`
    height: 100%;
    width: 100%;

    .video-js .vjs-big-play-button {
        background: none;
        border: 0;
        padding: 0;
        width: 70px;
        height: 70px;
        border-radius: 50%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        box-shadow: 0px 0px 12px 4px rgba(0, 0, 0, 0.2);

        & .vjs-icon-placeholder:before {
            content: '';
            background-image: url('${PlayActionSVGSRC}');
            background-repeat: no-repeat;
            background-size: 70px;
            background-position: 55% calc(50% - 0px);
            border: none !important;
            box-shadow: none !important;
        }
    }
`;
