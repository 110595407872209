import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import {
    Route,
    Switch,
    useHistory,
    useLocation,
    useRouteMatch,
} from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Constrain } from 'src/components/ui/Constrain';
import { firestore } from 'src/firebase';
import { useUserStore } from 'src/store/userStore';
import firebase from 'firebase/app';
import styled from 'src/styles/styled-components';
import { useCreatorStore } from 'src/store/creatorStore';
import { INITIAL_NAME_VALUES, OnboardingNameForm } from './OnboardingNameForm';
import { OnboardingInstagramForm } from './OnboardingInstagramForm';
import { ProgressBar } from 'src/components/ui/ProgressBar';
import { Header } from 'src/components/ui/Header';
import { Text } from 'src/components/ui/Text';
import { Loading } from 'src/components/ui/Loading';
import { Center } from 'src/components/ui/Center';

const Wrapper = styled(Constrain)`
    padding-top: 50px;
`;

const variants = {
    enter: (direction: number) => {
        return {
            x: direction > 0 ? '10%' : '-10%',
            opacity: 0,
        };
    },
    center: {
        zIndex: 1,
        x: 0,
        opacity: 1,
    },
    exit: (direction: number) => {
        return {
            zIndex: 0,
            x: direction < 0 ? '10%' : '-10%',
            opacity: 0,
        };
    },
};

const transition = {
    type: 'spring',
    duration: 0.2,
};

const AnimatedPage = (props: { direction: number; children: ReactNode }) => {
    return (
        <motion.div
            initial="enter"
            animate="center"
            exit="exit"
            transition={transition}
            variants={variants}
            custom={props.direction}
        >
            {props.children}
        </motion.div>
    );
};

export type ToNullable<T> = {
    [P in keyof T]: T[P] | null;
};

export type BasicNameInformation = {
    firstName: string;
    lastName: string;
};

export type BasicProfileInformation = BasicNameInformation & {
    instagram: string;
};

const CreatorOnboardingPage = () => {
    const location = useLocation();
    const { path, url } = useRouteMatch();
    const { push } = useHistory();
    const user = useUserStore((state) => state.user);
    const creatorProfile = useCreatorStore((state) => state.creatorProfile);
    const workouts = useCreatorStore((state) => state.workouts);

    // Local state
    const [direction, setDirection] = useState<1 | -1>(1);
    const [basicNameInfo, setNameInfo] = useState<
        ToNullable<BasicNameInformation>
    >({
        firstName: null,
        lastName: null,
    });

    if (!user) {
        throw new Error('User not logged in');
    }

    // Navigate and animate
    const pushWithDirection = useCallback(
        (path: string, direction: 1 | -1) => {
            setDirection(direction);
            push(path);
        },
        [push]
    );

    // Redirect to the name form if name fields are null (refresh on /instagram)
    useEffect(() => {
        if (
            (location.pathname.includes(`${url}/instagram`),
            basicNameInfo.firstName === null || basicNameInfo.lastName === null)
        ) {
            pushWithDirection(url, -1);
        }
    }, [
        basicNameInfo.firstName,
        basicNameInfo.lastName,
        path,
        url,
        pushWithDirection,
        location.pathname,
    ]);

    // Redirect to home if creator profile exists and workouts isn't null
    useEffect(() => {
        if (creatorProfile && workouts) {
            push('/');
        }
    }, [creatorProfile, workouts, push]);

    const handleNameEntry = useCallback(
        (values: typeof INITIAL_NAME_VALUES) => {
            setNameInfo({ ...basicNameInfo, ...values });
            pushWithDirection(`${url}/instagram`, 1);
        },
        [basicNameInfo, pushWithDirection, url]
    );

    const createProfile = useCallback(
        async (data: BasicProfileInformation) => {
            try {
                await firestore
                    .collection('creators')
                    .doc(user.uid)
                    .set(
                        {
                            id: user.uid,
                            ...data,
                            createdAt:
                                firebase.firestore.FieldValue.serverTimestamp(),
                            instagram: data.instagram.replace('@', ''),
                        },
                        { merge: true }
                    );

                pushWithDirection(`${url}/creating-profile`, 1);
            } catch (err) {
                console.error('Failed to set profile info', err);
            }
        },
        [pushWithDirection, url, user.uid]
    );

    const skipInstagram = useCallback(async () => {
        await createProfile({
            ...basicNameInfo,
            instagram: '',
        } as BasicProfileInformation);

        pushWithDirection(`${url}/creating-profile`, 1);
    }, [createProfile, basicNameInfo, pushWithDirection, url]);

    // Progress bar state
    let progress = 0;
    switch (location.pathname) {
        case `${path}`:
            progress = 1;
            break;
        case `${path}/instagram`:
            progress = 2;
            break;
        case `${path}/creating-profile`:
            progress = 3;
            break;
    }

    return (
        <Wrapper tiny>
            <ProgressBar progress={progress} total={3} spacing="xLarge" />
            <AnimatePresence exitBeforeEnter>
                <Switch location={location} key={location.pathname}>
                    <Route exact path={path}>
                        <AnimatedPage direction={direction}>
                            <OnboardingNameForm
                                basicNameInfo={basicNameInfo}
                                handleNameEntry={handleNameEntry}
                            />
                        </AnimatedPage>
                    </Route>
                    <Route path={`${path}/instagram`}>
                        <AnimatedPage direction={direction}>
                            <OnboardingInstagramForm
                                basicNameInfo={
                                    basicNameInfo as BasicNameInformation
                                }
                                createProfile={createProfile}
                                skipInstagram={skipInstagram}
                            />
                        </AnimatedPage>
                    </Route>

                    <Route path={`${path}/creating-profile`}>
                        <AnimatedPage direction={direction}>
                            <Header alignment="center" spacing="small">
                                Setting up your profile
                            </Header>
                            <Text alignment="center" spacing="xxLarge">
                                This will only take a few seconds...
                            </Text>
                            <Center>
                                <Loading size="large" />
                            </Center>
                        </AnimatedPage>
                    </Route>
                </Switch>
            </AnimatePresence>
        </Wrapper>
    );
};

export default CreatorOnboardingPage;
