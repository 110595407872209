import { CreatorState } from '../creatorStore';

function isSetupComplete(currentlyDue: string[]): boolean {
    if (currentlyDue.length === 0) {
        return true;
    }

    return !currentlyDue.some(
        (due) =>
            [
                'external_account',
                'individual.dob.day',
                'individual.dob.month',
                'individual.dob.year',
                'individual.phone',
                'tos_acceptance.date',
                'tos_acceptance.ip',
            ].indexOf(due) > -1
    );
}

export const isCreatorSetupCompleteSelector = (state: CreatorState) => {
    if (state.creator === null) {
        return false;
    }

    if (!state.creator.stripeAccount) {
        return false;
    }

    return (
        (state.creator!.stripeAccount?.requirements?.currently_due &&
            isSetupComplete(
                state.creator!.stripeAccount?.requirements?.currently_due
            )) ||
        false
    );
};
