import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Constrain } from 'src/components/ui/Constrain';
import { LoadingPage } from 'src/components/ui/LoadingPage';
import { Notice } from 'src/components/ui/Notice';
import { Workout } from 'src/components/Workout/Workout';
import { WorkoutGrid } from 'src/components/Workout/WorkoutGrid';
import { firestore } from 'src/firebase';
import { useSubscriberStore } from 'src/store/subscriberStore';
import styled from 'src/styles/styled-components';

const Wrapper = styled.div`
    padding-top: 1rem;
`;

const Heading = styled.h2`
    font-weight: bold;
    font-size: 2.4rem;
    margin: 0;
    margin-left: -0.1rem;
`;

const ProfileLink = styled(Link)`
    img {
        width: 4rem;
        height: 4rem;
        object-fit: cover;
        border-radius: 50%;
    }
`;

const Distribute = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
`;

export const SubscriberHome = () => {
    const { subscriptions } = useSubscriberStore();
    const [workouts, setWorkouts] = useState<flock.Workout[] | null>(null);

    const isOverduePayment =
        (subscriptions &&
            subscriptions.length > 0 &&
            subscriptions.some(
                (subscription) =>
                    subscription.stripeSubscription?.status === 'past_due'
            )) ||
        false;

    useEffect(() => {
        async function fetchWorkouts() {
            let workoutsQuery = firestore.collection(
                'workouts'
            ) as firebase.default.firestore.Query;

            if (!subscriptions || subscriptions.length === 0) {
                setWorkouts([]);
                return;
            }

            subscriptions!.map(async (subscription) => {
                workoutsQuery = workoutsQuery.where(
                    'profileId',
                    '==',
                    subscription.profile!.id
                );
            });

            return workoutsQuery
                .where('status', '==', 'published')
                .where('asset.status', '==', 'ready')
                .onSnapshot((docSnapshot) => {
                    let workoutArr: flock.Workout[] = [];

                    docSnapshot.docs.forEach((doc) =>
                        workoutArr.push(doc.data() as flock.Workout)
                    );

                    setWorkouts(workoutArr);
                });
        }

        if (subscriptions) {
            fetchWorkouts();
        }
    }, [subscriptions]);

    return (
        <Constrain>
            <Wrapper>
                {subscriptions?.length === 0 && (
                    <Notice>
                        You don't currently have any active subscriptions but
                        you can visit a FLOCK profile to create a new
                        subscription. As of now, you'll need to visit a profile
                        direct from a creators social media account but in the
                        future you'll be able to search for and find new
                        creators within FLOCK.
                    </Notice>
                )}

                {subscriptions && subscriptions.length > 0 && (
                    <Distribute>
                        <Heading>Workouts</Heading>

                        <div>
                            {subscriptions.map(
                                (subscription, i) =>
                                    subscription.profile && (
                                        <ProfileLink
                                            to={subscription.profile.slug}
                                            key={i}
                                        >
                                            <img
                                                src={subscription.profile.image}
                                                alt={`${subscription.profile.displayName}'s profile`}
                                            />
                                        </ProfileLink>
                                    )
                            )}
                        </div>
                    </Distribute>
                )}

                {!workouts && <LoadingPage />}

                {workouts && (
                    <WorkoutGrid>
                        {workouts
                            .sort(
                                (w1, w2) =>
                                    w2.createdAt.toDate().getTime() -
                                    w1.createdAt.toDate().getTime()
                            )
                            .map((workout, i) => (
                                <Workout
                                    key={i}
                                    workout={workout}
                                    locked={isOverduePayment}
                                />
                            ))}
                    </WorkoutGrid>
                )}
            </Wrapper>
        </Constrain>
    );
};
