import { functions } from 'src/firebase';

export async function goToCompleteOnboarding(
    user: firebase.default.User,
    stripeAccountId: string
): Promise<void> {
    const getOnboardingLink = functions.httpsCallable(
        'createStripeAccountLink'
    );

    const stripeResult = await getOnboardingLink({
        accountId: stripeAccountId,
        userId: user.uid,
    });

    window.location.assign(stripeResult.data.url);
}
