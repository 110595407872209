import styled, { css } from 'src/styles/styled-components';
import React from 'react';
import { StyledInputError, StyledInputLabel } from './Input/Input';
import chevronDownSVG from 'src/svgs/icons/chevron-down.svg';

interface SelectProps
    extends React.DetailedHTMLProps<
        React.SelectHTMLAttributes<HTMLSelectElement>,
        HTMLSelectElement
    > {
    label?: string;
    error?: string | null;
    options: { value: string; label: string }[];
    pleaseSelect?: true;
}

const StyledSelect = styled.select`
    border-radius: 3px;
    border: solid 1px ${({ theme }) => theme.colors.grey3};
    display: block;
    width: 100%;
    padding: 1.1rem 1.4rem;
    font-size: ${({ theme }) => theme.font.size.level4.small};
    background-color: white;
    background-image: url('${chevronDownSVG}');
    background-repeat: no-repeat;
    background-position: right 1rem center;
    appearance: none;
    color: ${({ theme }) => theme.colors.black};

    &:focus {
        border-color: ${({ theme }) => theme.colors.turquoise};
    }
`;

export const Select = styled(
    ({
        label,
        error,
        name,
        className,
        options,
        ref,
        pleaseSelect,
        ...props
    }: SelectProps) => (
        <div className={className}>
            {label && (
                <StyledInputLabel htmlFor={`${name}-id`}>
                    {label}
                </StyledInputLabel>
            )}
            <StyledSelect name={name} id={`${name}-id`} {...props}>
                {pleaseSelect && <option value="null">Please select</option>}
                {options.map(({ value, label: optionLabel }, index) => (
                    <option value={value} key={index}>
                        {optionLabel}
                    </option>
                ))}
            </StyledSelect>
            {error && <StyledInputError>{error}</StyledInputError>}
        </div>
    )
)<SelectProps>`
    position: relative;
    margin-bottom: 2.4rem;

    ${({ error }) =>
        error &&
        css`
            ${StyledSelect} {
                border-color: ${({ theme }) => theme.colors.pinkRed};
            }
        `}
`;
