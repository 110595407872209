import styled, { css } from 'src/styles/styled-components';
import { media } from 'src/utils/mediaQueries';

export const FormActions = styled.div<{ stack?: true }>`
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    margin-bottom: 3rem;

    ${media.m`
        margin-top: 4rem;
        margin-bottom: 4rem;
    `}

    ${({ stack }) =>
        stack
            ? css`
                  flex-direction: column;

                  & > *:not(:last-child) {
                      margin-bottom: 2rem;
                  }
              `
            : css`
                  & > * {
                      flex: 1;

                      &:not(:first-child) {
                          margin-left: 2rem;
                      }

                      ${media.m`
                            flex: 0;
                            flex-basis: 28rem;
                      `}
                  }
              `}
`;
