import styled from 'src/styles/styled-components';
import React, { FunctionComponent } from 'react';

interface InputProps
    extends React.DetailedHTMLProps<
        React.InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
    > {
    label: JSX.Element | string;
}

export const Label = styled.label`
    display: block;
    font-size: ${({ theme }) => theme.font.size.level5.small};
    padding-left: 2.8rem;

    &:before {
        content: '';
        position: absolute;
        left: 0;
        width: 1.6rem;
        height: 1.6rem;
        border: 2px solid ${({ theme }) => theme.colors.grey1};
        border-radius: 50%;
        top: 50%;
        transform: translateY(-50%);
    }

    &:after {
        content: '';
        position: absolute;
        background-color: ${({ theme }) => theme.colors.grey1};
        left: 1rem;
        width: 1rem;
        height: 1rem;
        top: 50%;
        transform: translate(-50%, -50%) scale(0.6);
        border-radius: 50%;
        opacity: 0;
        transition: opacity 200ms;
    }
`;

const HiddenInput = styled.input`
    position: absolute;
    left: -9999px;
`;

export const RadioInput: FunctionComponent<InputProps> = styled(
    ({ label, name, className, id, ...props }: InputProps) => (
        <div className={className}>
            <HiddenInput
                name={name}
                id={`${id}-id`}
                type="radio"
                {...(props as any)}
            />
            <Label htmlFor={`${id}-id`}>{label}</Label>
        </div>
    )
)<InputProps>`
    position: relative;
    margin-bottom: 1rem;

    input[type='radio']:checked + label:after {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }
`;
