import React, { FunctionComponent, useEffect } from 'react';
import { Constrain } from 'src/components/ui/Constrain';
import styled from 'src/styles/styled-components';
import { ReactComponent as FacebookSVG } from './images/Facebook.svg';
import { ReactComponent as TwitterSVG } from './images/Twitter.svg';
import { ReactComponent as InstagramSVG } from './images/Instagram.svg';
import whiteFlockLogoSrc from './images/FLOCK.Fitness-logo-white.svg';
import benefitsImageSrc from './images/benefits-mobile.png';
import gymOwnersMonthly from './images/gym-owners-monthly.jpeg';
import backgroundImageSrc from './images/background.jpg';
import mainScreenshotImageSrc from './images/main-screenshot.png';
import earnImageSrc from './images/earn.png';
import { media, mediaMax } from 'src/utils/mediaQueries';
import { FAQItem } from './ui/FAQItem';
import { Benefit } from './ui/Benefit';
import { Link, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Heading } from 'src/components/ui/Heading';
import { CreateCreatorAccount } from 'src/components/CreateAccount/CreateCreatorAccount';
import { useUserStore } from 'src/store/userStore';

const Wrapper = styled.div`
    p {
        color: #444;
    }

    .header {
        background: url(${backgroundImageSrc});
        background-size: cover;
        background-position: center center;
        color: white;
    }

    .masthead {
        padding: 2rem 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2rem;

        ${media.m`
            padding: 4rem 0;
        `}

        img {
            max-width: 15rem;

            ${media.m`
                max-width: 25rem;
            `}
        }

        a {
            background: ${({ theme }) => theme.colors.turquoise};
            padding: 1rem 1.5rem;
            color: #000;
            border-radius: 3px;
            font-weight: 500;
            text-decoration: none;
        }
    }

    .value-prop {
        padding: 4rem 0 0;
        margin-bottom: -4rem;

        ${media.m`
            display: flex;
            align-items: center;
        `}

        & > div {
            ${media.m`
                width: 60%;
            `}
        }

        img {
            max-width: 28rem;
            display: block;
            margin: 0 auto;
        }

        h1 {
            font-size: 3rem;

            ${media.l`
                font-size: 5.2rem;
            `}
        }

        p {
            color: white;
            margin-bottom: 3rem;
            font-size: 2rem;

            ${media.l`
                font-size: 2.4rem;
            `}
        }
    }

    .earn {
        background: #3b4048;
        padding: 10rem 0 6rem;
        color: white;
        margin-bottom: 2rem;

        ${media.m`
            margin-bottom: 4rem;
        `}

        ${Constrain} {
            display: flex;
            flex-direction: column;

            ${media.m`
                flex-direction: row;
                align-items: center;

                & > div {
                    order: 1;
                }
            `}
        }

        h2 {
            ${media.m`
                font-size: 3rem;
            `}
        }

        img {
            max-width: 50rem;
            margin-bottom: 2rem;
            order: 2;
            margin-top: 2rem;

            ${media.m`
                margin-right: 6rem;
                order: 1;
                margin-top: 0;
                            margin-bottom: 2rem;
            `}
        }

        p {
            color: white;

            ${media.m`
                font-size: 1.8rem;
            `}
        }
    }

    .benefits {
        padding: 3rem 0;
        background: white;
        margin-bottom: 3rem;

        .description {
            max-width: 80rem;
            margin: 0 auto;
            margin-bottom: 6rem;
        }

        ${media.m`
            margin-bottom: 7rem;
        `}

        .benefits-grid {
            img {
                display: none;
            }

            ${media.m`
                display: flex;
                justify-content: space-between;
                align-items: center;

                & > * {
                    width: 44%;
                }

                img {
                    display: block;
                }
            `}

            ${media.l`
                img {
                    width: 58%;
                    margin-left: -10%;
                }
            `}
        }
    }

    .pricing {
        background: #3b4048;
        color: white;
        text-align: center;
        padding: 3rem 0;
        margin-bottom: 6rem;

        h2 {
            margin-top: 0;
        }

        ${media.m`
            padding: 5rem 0;
            margin-bottom: 10rem;
        `}

        p {
            color: white;
        }
    }

    .register-interest {
        margin-bottom: 6rem;

        ${media.m`
            margin-bottom: 10rem;
        `}

        .description {
            text-align: center;

            ${media.m`
                margin-bottom: 4rem;
            `}
        }

        .grecaptcha-badge {
            display: none;
        }
    }

    .sign-up {
        margin-bottom: 6rem;

        ${media.m`
            margin-bottom: 10rem;
        `}
    }

    .faq,
    .as-seen-in {
        margin-bottom: 6rem;

        ${media.m`
            margin-bottom: 10rem;
        `}
    }

    .as-seen-in__logos {
        display: flex;
        justify-content: center;

        img {
            max-width: 80px;
            max-width: 80px;
        }
    }

    .footer {
        background-color: #3b4048;
        color: white;
        padding: 3rem 0;
        font-weight: 500;

        ${media.m`       
            ${Constrain} {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        `}
    }

    .footer-links {
        margin-bottom: 5px;

        a {
            color: white;

            &:not(:last-child) {
                margin-right: 1.5rem;
            }
        }
    }

    .social-links {
        ${mediaMax.m`
            margin-top: 2rem;
        `}

        ${media.m`
            display: inline-flex;
        `}

        a {
            display: inline-flex;
            background-color: rgba(255, 255, 255, 0.9);
            padding: 1.5rem;
            border-radius: 50%;

            &:not(:first-child) {
                margin-left: 1rem;
            }

            svg {
                width: 2rem;
                height: 2rem;
            }
        }
    }

    .h2 {
        font-size: 3rem;
        text-align: center;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
`;

export const CreatorLanding: FunctionComponent = () => {
    // const recaptchaRef = useRef<ReCAPTCHA>();
    // const [waitingListSuccess, setWaitingListSuccess] = useState<
    //     null | boolean
    // >(false);

    const { push } = useHistory();
    const { user } = useUserStore();

    useEffect(() => {
        if (user) {
            push('/creator/onboarding');
        }
    }, [user, push]);

    return (
        <Wrapper>
            <Helmet>
                <title>How FLOCK works for Creators | FLOCK.fitness</title>

                <meta
                    name="description"
                    content="We're re-imagining the way you share your workouts. The subscription platform for paywalled fitness content. Monetise your fitness content through FLOCK."
                />
            </Helmet>

            <header className="header">
                <Constrain wide>
                    <div className="masthead">
                        <img src={whiteFlockLogoSrc} alt="FLOCK.fitness logo" />

                        <a
                            href="#sign-up"
                            onClick={(e) => {
                                e.preventDefault();
                                document
                                    .querySelector('#sign-up')
                                    ?.scrollIntoView({
                                        behavior: 'smooth',
                                        block: 'center',
                                        inline: 'nearest',
                                    });
                            }}
                        >
                            Start your journey
                        </a>
                    </div>

                    <div className="value-prop">
                        <div>
                            <h1>
                                Monetise your fitness content through FLOCK.
                            </h1>
                            <p>
                                Re-imagining the way you share your workouts.
                                The subscription platform for paywalled fitness
                                content.
                            </p>
                        </div>

                        <img
                            src={mainScreenshotImageSrc}
                            alt="FLOCK.fitness running on an iPhone"
                        />
                    </div>
                </Constrain>
            </header>

            <div className="earn">
                <Constrain wide>
                    <img src={earnImageSrc} alt="Earn with FLOCK" />

                    <div>
                        <h2>
                            <strong>Earn</strong> while you{' '}
                            <strong>burn</strong>
                        </h2>

                        <p>
                            Create unique workout content for your subscribers
                            and get paid with a monthly subscription model, all
                            in one place.
                        </p>

                        <p>
                            Take the headache out of building your own platform.
                            FLOCK provides a new way for creators to engage
                            directly with their audience, take payments and
                            provide exclusive content without the overhead of
                            building, maintaining and marketing their own app.
                        </p>
                    </div>
                </Constrain>
            </div>

            <div className="benefits">
                <Constrain>
                    <h2 className="h2">
                        Benefits of <strong>FLOCK</strong>
                    </h2>

                    <p className="description">
                        All the benefits of your own content distribution
                        platform without the headache of creating your own,
                        FLOCK is free to join, with no setup fees, so you can
                        start earning from day one.
                        <br />
                        <br />
                        <strong>FLOCK</strong>.fitness enables creators to earn
                        while they burn, this is an exciting opportunity to earn
                        recurring income without having to rely on making money
                        through brand sponsorship and partnerships.
                    </p>

                    <div className="benefits-grid">
                        <img
                            src={benefitsImageSrc}
                            alt="FLOCK.Fitness in use on a mobile"
                            className="benefits-image"
                        />

                        <div>
                            <Benefit>
                                {/* <GoogleSVG /> */}
                                <div>
                                    <h4>Use just one app</h4>
                                    <p>
                                        Easily manage your subscriptions and
                                        payments all in one place
                                    </p>
                                </div>
                            </Benefit>

                            <Benefit>
                                {/* <GoogleSVG /> */}
                                <div>
                                    <h4>A new way to make money</h4>
                                    <p>
                                        Build upon what you are already
                                        offering, with a better return on
                                        investment.
                                    </p>
                                </div>
                            </Benefit>

                            <Benefit>
                                {/* <GoogleSVG /> */}
                                <div>
                                    <h4>Recurring income</h4>
                                    <p>
                                        Earn a passive income from your fitness
                                        content, it’s easy.
                                    </p>
                                </div>
                            </Benefit>

                            <Benefit>
                                {/* <GoogleSVG /> */}
                                <div>
                                    <h4>It’s all taken care of</h4>
                                    <p>
                                        Tech, privacy and legals all taken care
                                        of for you, we’ve got you.
                                    </p>
                                </div>
                            </Benefit>
                        </div>
                    </div>
                </Constrain>
            </div>

            <div className="pricing">
                <Constrain narrow>
                    <h2 className="h2">Transparent pricing</h2>

                    <p>
                        In order to survive and thrive, the FLOCK platform will
                        charge a commission on the charges you make to your
                        subscribers. You retain 80% of your subscription income
                        and FLOCK retains 20%.
                    </p>

                    <p>
                        <strong>FLOCK.fitness</strong>’s share of commission
                        will enable us to make improvements that will help you
                        to grow your following, offer new features and be more
                        successful. <strong>We’re in this together</strong>.
                    </p>
                </Constrain>
            </div>

            <div className="sign-up" id="sign-up">
                <Constrain narrow>
                    <Heading>
                        Start <span>your journey</span>
                    </Heading>

                    <CreateCreatorAccount />
                </Constrain>
            </div>

            <div className="as-seen-in">
                <h2 className="h2">As seen in</h2>

                <Constrain narrow>
                    <div className="as-seen-in__logos">
                        <a
                            href="https://issuu.com/gymownermonthly/docs/gom_aug21_issue_full/52"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                src={gymOwnersMonthly}
                                alt="Gym Owners monthly magazine"
                            />
                        </a>
                    </div>
                </Constrain>
            </div>

            <div className="faq">
                <h2 className="h2">FAQs</h2>

                <Constrain narrow>
                    {[
                        {
                            question: 'How do I get paid?',
                            answer: 'In order to pay you, we may need to verify your identity. You will then be able to go to the settings section of the app and select the option to view your earnings. Payouts to creators are made on the 25th of each month.',
                        },
                        {
                            question: 'What is Stripe?',
                            answer: 'Stripe is the secure payment platform we use for taking payments and paying our creators. Stripe has been audited by a PCI-certified auditor and is certified to PCI Service Provider Level 1. This is the most stringent level of certification available in the payments industry.',
                        },
                        {
                            question:
                                'How much should my subscription price be?',
                            answer: 'You can set your subscription price to anything from £8 to £50. The subscription price should depend on the quality of the workout content and how often you plan to post your content.',
                        },
                        {
                            question: 'Who owns my content?',
                            answer: 'You do - create and retain complete ownership of your content. We just make sure it’s safe and secure.',
                        },
                        {
                            question: 'What are the terms of using the app?',
                            answer: 'All of the legal and T’s & C’s are taken care of. You can <a href="https://flock.fitness/terms">view them here</a>. No need to worry about data privacy and security - read our <a href="https://flock.fitness/privacy">privacy policy</a> here.',
                        },
                        {
                            question:
                                'Do I need to be tech-savvy to start my FLOCK?',
                            answer: 'No technical expertise required. Get started within minutes. If you’ve got any questions or concerns we’d be more than happy to walk through the app with you, contact us to book a session.',
                        },
                        {
                            question: 'How do I share my FLOCK?',
                            answer: 'FLOCK acts as a bolt on to your existing social media. So the best place to promote your FLOCK is your existing social media. Add the link to your unique FLOCK handle in your bio and do regular ‘Swipe up’s in Instagram stories. For more information on <a href="https://blog.flock.fitness/promoting-your-flock/" target="_blank" rel="noreferrer">promoting your FLOCK</a> you can check out <a href="https://blog.flock.fitness/" target="_blank">our blog</a>.',
                        },
                        {
                            question: 'How do I grow my FLOCK?',
                            answer: 'The way to successfully attract and retain your subscribers is to create exclusive fitness content and extend further than what we see on mainstream social platforms to add value to your subscribers. Quality videos with quality content are key.',
                        },
                        {
                            question: 'Can I use music in my workout videos?',
                            answer: 'There is an endless supply of music libraries to choose from and it’s often difficult to select the right one.  We hope to support music from streaming services like Spotify in the near future, but in the meantime, we suggest trying these royalty-free music platforms: <a href="https://www.epidemicsound.com/" target="_blank" rel="noreferrer">Epidemic Sounds</a> or <a href="https://artlist.io/" target="_blank" rel="noreferrer">Artlist</a>. Read more about this on <a href="https://blog.flock.fitness/how-to-select-the-right-music-for-your-workout-videos/" target="_blank" rel="noreferrer">our blog</a>.',
                        },
                        {
                            question:
                                'Can I post sponsored content onto FLOCK?',
                            answer: 'You may post any content that is not a direct competitor (for example, a gym or fitness app). But supplements, apparel and protein shakes are all ok. Content should always be providing value to your subscribers so any short product advertisements may be removed and will likely result in a reduction in the number of subscribers.',
                        },
                    ].map(({ question, answer }, key) => (
                        <FAQItem
                            question={question}
                            answer={answer}
                            key={key}
                        />
                    ))}
                </Constrain>
            </div>

            <div className="footer">
                <Constrain>
                    <div>
                        <div className="footer-links">
                            <a
                                href="https://blog.flock.fitness"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Our blog
                            </a>

                            <Link to="/">Login</Link>
                            <Link to="/terms">Terms</Link>
                            <Link to="/privacy">Privacy policy</Link>
                        </div>
                        Copyright FLOCK.fitness &copy;{' '}
                        {new Date().getFullYear()}. All rights reserved.
                    </div>
                    <div className="social-links">
                        <a
                            href="https://www.instagram.com/flockfitnessapp/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <InstagramSVG />
                        </a>

                        <a
                            href="https://www.facebook.com/FLOCKfitness-100838418546798/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <FacebookSVG />
                        </a>

                        <a
                            href="https://twitter.com/FLOCKfitnessapp"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <TwitterSVG />
                        </a>
                    </div>
                </Constrain>
            </div>
        </Wrapper>
    );
};
