import React from 'react';
import { motion } from 'framer-motion';
import styled, { css } from 'src/styles/styled-components';
import theme from 'src/styles/theme';

const Wrapper = styled.div<{ spacing?: keyof typeof theme.spacing }>`
    background-color: ${({ theme }) => theme.colors.grey6};
    border-radius: 2px;
    height: 4px;

    ${({ spacing, theme }) =>
        spacing &&
        css`
            margin-bottom: ${theme.spacing[spacing]};
        `}
`;

const Bar = styled(motion.div)`
    background-color: ${({ theme }) => theme.colors.blue};
    border-radius: 2px;
    height: 100%;
`;

type Props = {
    progress: number;
    total: number;
    spacing?: keyof typeof theme.spacing;
};

export const ProgressBar = (props: Props) => {
    const variants = {
        enter: {
            width: 0,
        },
        animate: {
            width: `${(props.progress / props.total) * 100}%`,
        },
    };
    return (
        <Wrapper spacing={props.spacing}>
            <Bar variants={variants} initial="enter" animate="animate" />
        </Wrapper>
    );
};
