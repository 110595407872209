import React, { FunctionComponent, useState } from 'react';
import { PrimaryButton } from 'src/components/ui/Button/PrimaryButton';
import { FormActions } from 'src/components/ui/FormActions';
import styled from 'src/styles/styled-components';
import { media } from 'src/utils/mediaQueries';
import { Workout } from 'src/components/Workout/Workout';
import { TertiaryButton } from 'src/components/ui/Button/TertiaryButton';
import { PreviewVideo } from 'src/components/PreviewVideo/PreviewVideo';

const Wrapper = styled.div`
    max-width: 500px;
    margin: 0 auto;

    ${media.l`
        ${Workout} {
            display: block;
        }
    `}

    button {
        font-size: ${({ theme }) => theme.font.size.level3.small};
        padding: 0.8rem;
    }
`;

type Props = {
    onBack: () => void;
    publish: (previewVideoId: string) => Promise<void>;
    previewVideo: api.CreatorPreviewVideo;
    isDraft: boolean;
};

export const ReviewPreviewVideo: FunctionComponent<Props> = ({
    onBack,
    previewVideo,
    isDraft,
    publish,
}) => {
    const [submitting, setSubmitting] = useState(false);

    return (
        <Wrapper>
            <PreviewVideo previewVideo={previewVideo} />

            <div>
                <FormActions>
                    <TertiaryButton onClick={onBack}>Edit</TertiaryButton>

                    {isDraft && (
                        <PrimaryButton
                            onClick={async () => {
                                setSubmitting(true);
                                await publish(previewVideo.id);
                                setSubmitting(false);
                            }}
                            isSubmitting={submitting}
                        >
                            Publish
                        </PrimaryButton>
                    )}
                </FormActions>
            </div>
        </Wrapper>
    );
};
