import styled from 'src/styles/styled-components';
import { media } from 'src/utils/mediaQueries';

export const Benefit = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 2rem;

    ${media.m`
        margin-bottom: 4rem;
    `}

    svg {
        height: 4rem;
        width: 4rem;
        margin-right: 2rem;
        flex-shrink: 0;
    }

    h4 {
        margin-bottom: 0.5rem;
        font-size: 1.6rem;
        font-weight: 500;
    }

    p {
        color: #888;
        font-size: 1.4rem;
        margin: 0;
    }
`;
