import create from 'zustand';
import produce from 'immer';

type UserType = 'creator' | 'subscriber';

type UserStore = {
    loading: boolean;
    user: firebase.default.User | null;
    userType: UserType | null;
    setLoading: (loading: boolean) => void;
    setUser: (
        user: firebase.default.User | null,
        userType: UserType | null
    ) => void;
};

export const useUserStore = create<UserStore>((set, get) => ({
    loading: true,
    user: null,
    userType: null,
    setLoading: (loading: boolean) => {
        set((baseState) =>
            produce(baseState, (state) => {
                state.loading = loading;
            })
        );
    },
    setUser: (
        user: firebase.default.User | null,
        userType: UserType | null
    ) => {
        set((baseState) =>
            produce(baseState, (state) => {
                state.user = user;
                state.userType = userType;
            })
        );
    },
}));
