import React, { ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'src/styles/styled-components';
import { media } from 'src/utils/mediaQueries';
import logo from 'src/images/FLOCK.fitness-logo.svg';
import { Link } from 'react-router-dom';

type Props = {
    children?: ReactNode;
    description?: string;
    title?: string;
};

const Body = styled.div`
    min-height: 100%;
    display: grid;
    grid-template-rows: auto 1fr auto;
`;

const Header = styled.header`
    padding-top: 4rem;
    text-align: center;
    margin-bottom: 2rem;

    ${media.l`
        padding-top: 6rem;
        margin-bottom: 4rem;
    `}
`;

const Content = styled.div`
    margin-bottom: 2rem;
    /* width: 100%; */
    /* max-width: 450px; */
`;

const Logo = styled.img`
    width: 13rem;

    ${media.l`
        width: 25rem;
    `}
`;

const Footer = styled.footer`
    position: relative;
    width: 100%;
    text-align: center;
    padding: 1.5rem;
    color: ${({ theme }) => theme.colors.grey3};
    font-size: 1.4rem;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        width: 15rem;
        left: 50%;
        transform: translateX(-50%);
        height: 1px;
        background-color: ${({ theme }) => theme.colors.grey5};

        ${media.l`
            width: 20rem;
        `}
    }
`;

const FooterLinks = styled.div`
    margin-bottom: 2rem;

    & > * {
        color: ${({ theme }) => theme.colors.grey3};
        font-size: 1.4rem;
        font-weight: 600;

        &:not(:first-child) {
            margin-left: 2rem;
        }
    }
`;

const AuthLayout = ({
    children,
    title = 'FLOCK.fitness',
    description = 'FLOCK.fitness - create workouts, get subscribers and get paid',
}: Props) => (
    <>
        <Helmet>
            <title>{title}</title>
            <meta charSet="utf-8" />

            <meta name="description" content={description} />
        </Helmet>

        <Body>
            <Header>
                <Logo src={logo} alt="FLOCK.fitness logo" />
            </Header>

            <Content>{children}</Content>

            <Footer>
                <FooterLinks>
                    <a
                        href="https://blog.flock.fitness"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Our blog
                    </a>
                    <Link to="/terms">Terms</Link>
                    <Link to="/privacy">Privacy</Link>
                    <Link to="/creator">Become a creator</Link>
                </FooterLinks>

                <div className="copyright">
                    Copyright FLOCK.fitness &copy; 2021
                </div>
            </Footer>
        </Body>
    </>
);

export default AuthLayout;
