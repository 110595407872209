import React, { FunctionComponent, useState } from 'react';
import { firestore, functions } from 'src/firebase';
import styled from 'src/styles/styled-components';
import { createUpload } from '@mux/upchunk';
import { StyledInputError } from 'src/components/ui/Input/Input';
import { getVideoFileLength } from 'src/utils/getVideoFileLength';
import { CoverThumbnailUpload } from 'src/components/CoverThumbnailUpload/CoverThumbnailUpload';
import { Field, FieldProps, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { FormActions } from 'src/components/ui/FormActions';
import { PrimaryButton } from 'src/components/ui/Button/PrimaryButton';
import { Constrain } from 'src/components/ui/Constrain';
import { VideoUploader } from 'src/components/VideoUploader/VideoUploader';

const FieldWrapper = styled.div`
    position: relative;
`;

async function getUploadLink(
    creatorId: string
): Promise<api.response.PreviewUploadBody> {
    const fetchUploadLink = functions.httpsCallable('uploadPreviewVideo');
    try {
        const result = await fetchUploadLink({ creatorId });
        return result.data;
    } catch (err) {
        console.error(err);
        throw err;
    }
}

async function uploadVideoFile(
    file: File,
    profileId: string,
    progressCb: (progress: number | null) => void,
    onError: (error: string) => void,
    onSuccess: (uploadId: string) => void
) {
    try {
        const { uploadUrl, uploadId } = await getUploadLink(profileId);

        const upload = createUpload({
            endpoint: uploadUrl,
            file,
            chunkSize: 1280, // Uploads the file in ~5mb chunks
        });

        // subscribe to events
        upload.on('error', (err) => {
            progressCb(null);
            onError('Error uploading video, please try again');
        });

        upload.on('progress', (progress) => {
            progressCb(progress.detail);
        });

        upload.on('success', () => {
            onSuccess(uploadId);
        });
    } catch (err) {
        onError("Error starting upload, we've been notified of the problem!");
    }
}

const validationSchema = Yup.object().shape({
    video: Yup.string().required(
        'Please upload your video and wait for it to finish'
    ),
    thumbnail: Yup.string().required('Upload a thumbnail'),
});

function getInitialValuesFromPreview(preview: api.CreatorPreviewVideo): {
    video: string;
    thumbnail: string;
} {
    return {
        video: preview.id,
        thumbnail: preview.thumbnail!,
    };
}

const INITIAL_VALUES = {
    video: undefined,
    thumbnail: '',
};

type Props = {
    profile: api.CreatorProfile;
    draftPreviewVideo: api.CreatorPreviewVideo | null;
    onSuccess(previewVideoId: string): void;
};

export const CreatePreviewVideoForm: FunctionComponent<Props> = ({
    draftPreviewVideo,
    profile,
    onSuccess,
}) => {
    const [videoUploadProgress, setVideoUploadProgress] = useState<
        number | null
    >(null);

    return (
        <Formik
            initialValues={
                draftPreviewVideo
                    ? getInitialValuesFromPreview(draftPreviewVideo)
                    : INITIAL_VALUES
            }
            validationSchema={validationSchema}
            onSubmit={async (values, actions) => {
                try {
                    await firestore
                        .collection('creatorProfiles')
                        .doc(profile.id)
                        .collection('previews')
                        .doc(values.video)
                        .update({
                            thumbnail: values.thumbnail,
                        } as Partial<api.CreatorPreviewVideo>);

                    onSuccess(values.video!);
                } catch (err: any) {
                    throw new Error(err);
                }
            }}
        >
            {({
                submitCount,
                handleSubmit,
                values,
                isSubmitting,
                setFieldValue,
                setErrors,
            }: FormikProps<{
                video: string | undefined;
                thumbnail: string;
            }>) => {
                const setVideoError = (error: string | undefined) =>
                    setErrors({
                        video: error,
                    });

                return (
                    <form onSubmit={handleSubmit} noValidate>
                        <Constrain tiny>
                            <FieldWrapper>
                                <Field name="video">
                                    {({ meta, field }: FieldProps) => (
                                        <VideoUploader
                                            videoId={field.value}
                                            uploadProgress={videoUploadProgress}
                                            clearVideo={() =>
                                                setFieldValue(
                                                    'video',
                                                    undefined
                                                )
                                            }
                                            setError={setVideoError}
                                            error={meta.error}
                                            instructions={
                                                <>
                                                    <p>
                                                        Please make sure this is
                                                        the final version of
                                                        your preview video
                                                        before you upload.
                                                    </p>
                                                    <small>
                                                        (max 2 minutes)
                                                    </small>
                                                </>
                                            }
                                            uploadVideoFile={async (file) => {
                                                setVideoError(undefined);

                                                if (
                                                    (await getVideoFileLength(
                                                        file
                                                    )) > 120
                                                ) {
                                                    setVideoError(
                                                        'Preview videos are limited to 2 minutes'
                                                    );
                                                    return;
                                                }

                                                setVideoUploadProgress(0);

                                                uploadVideoFile(
                                                    file,
                                                    profile.id,
                                                    setVideoUploadProgress,
                                                    setVideoError,
                                                    (uploadId) =>
                                                        setFieldValue(
                                                            'video',
                                                            uploadId
                                                        )
                                                );
                                            }}
                                        />
                                    )}
                                </Field>
                            </FieldWrapper>

                            <FieldWrapper>
                                <Field name="thumbnail">
                                    {({ meta }: FieldProps) => (
                                        <>
                                            <CoverThumbnailUpload
                                                upload={values.thumbnail}
                                                bucket="profile-preview-video-thumbnails"
                                                guide="Upload a photo that best displays your preview video"
                                                onError={(err) => {
                                                    setErrors({
                                                        thumbnail: err,
                                                    });
                                                }}
                                                onClear={() => {
                                                    setFieldValue(
                                                        'thumbnail',
                                                        ''
                                                    );
                                                }}
                                                onSuccess={(thumbnailId) => {
                                                    setFieldValue(
                                                        'thumbnail',
                                                        thumbnailId
                                                    );
                                                }}
                                            />

                                            {submitCount > 0 && meta.error && (
                                                <StyledInputError>
                                                    {meta.error}
                                                </StyledInputError>
                                            )}
                                        </>
                                    )}
                                </Field>
                            </FieldWrapper>

                            <FormActions>
                                <PrimaryButton
                                    type="submit"
                                    isSubmitting={isSubmitting}
                                >
                                    Save &amp; preview
                                </PrimaryButton>
                            </FormActions>
                        </Constrain>
                    </form>
                );
            }}
        </Formik>
    );
};
