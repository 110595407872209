const dev = {
    URL: 'https://staging.flock.fitness',
    API_KEY: 'AIzaSyANWvXnnrdjm5ETC8hgto9_W8bk_hVzAAY',
    AUTH_DOMAIN: 'flock-fitness-staging.firebaseapp.com',
    PROJECT_ID: 'flock-fitness-staging',
    STORAGE_BUCKET: 'flock-fitness-staging.appspot.com',
    MESSAGING_SENDER_ID: '980077959388',
    APP_ID: '1:980077959388:web:914aa41cbce2283d87bc19',
    MEASUREMENT_ID: '',
    MODE: 'dev',
    SENTRY_DSN: '',
    STRIPE_PUBLIC_KEY:
        'pk_test_51IDGQqEJCrzFGDzO9EVv16MWVhqKbPoFELBt4wmtb670NtScVGiMPYBNWTT1pJhDJrIKqwy80dIUFsmbdpluo4Os007Nm8YAj2',
};

const prod = {
    URL: 'https://flock.fitness',
    API_KEY: 'AIzaSyCWFnC8Eh1aNqgWtqpzUTUjE3A8otC_D_Q',
    AUTH_DOMAIN: 'flock-fitness.firebaseapp.com',
    PROJECT_ID: 'flock-fitness',
    STORAGE_BUCKET: 'flock-fitness.appspot.com',
    MESSAGING_SENDER_ID: '717917223810',
    APP_ID: '1:717917223810:web:56f435a8f625894e5b2d9b',
    MEASUREMENT_ID: 'G-GJJL5J1TN5',
    MODE: 'prod',
    SENTRY_DSN:
        'https://e68fc931dde3498daa2454cfef7241ae@o334212.ingest.sentry.io/5701341',
    STRIPE_PUBLIC_KEY:
        'pk_live_51IDGQqEJCrzFGDzOE4QZHaAq30aWE86IJooSSkfykGGB3atOj81yfgH6cpTm9oNr3jWwr4VQldWzMMURHcRxG8OR00jxji3gDv',
};

const config = process.env.REACT_APP_STAGE === 'production' ? prod : dev;

export default config;
