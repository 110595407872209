import React, { FunctionComponent } from 'react';
import { RenderElementProps } from 'slate-react';

export const Element: FunctionComponent<RenderElementProps> = ({
    attributes,
    children,
    element,
}) => {
    switch (element.type) {
        case 'bulleted-list':
            return <ul {...attributes}>{children}</ul>;
        case 'list-item':
            return <li {...attributes}>{children}</li>;
        default:
            return <p {...attributes}>{children}</p>;
    }
};
