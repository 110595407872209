import '@stripe/stripe-js';
import React from 'react';
import ReactDOM from 'react-dom';
import { setAppElement } from 'react-modal';
import App from './App';
import { CreatorProvider } from './contexts/creator';
import { GoingToStripeProvider } from './contexts/goingToStripe';
import { SubscriberProvider } from './contexts/subscriber';
import { AuthProvider } from './contexts/user';
// import reportWebVitals from './reportWebVitals';
import GlobalStyles from './styles/GlobalStyles';
import { ThemeProvider } from './styles/styled-components';
import theme from './styles/theme';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import config from './config';
import ReactPixel from 'react-facebook-pixel';

if (config.SENTRY_DSN.length) {
    Sentry.init({
        dsn: config.SENTRY_DSN,
        release: 'flock-fitness@' + process.env.npm_package_version,
        integrations: [new Integrations.BrowserTracing()],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}

ReactPixel.init('373653020784676');
// ReactPixel.revokeConsent();
ReactPixel.pageView();
ReactPixel.fbq('track', 'PageView');

setAppElement('#root');

ReactDOM.render(
    <React.StrictMode>
        <AuthProvider>
            <CreatorProvider>
                <SubscriberProvider>
                    <GoingToStripeProvider>
                        <ThemeProvider theme={theme}>
                            <GlobalStyles />

                            <App />
                        </ThemeProvider>
                    </GoingToStripeProvider>
                </SubscriberProvider>
            </CreatorProvider>
        </AuthProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
