import React from 'react';
import styled from 'src/styles/styled-components';
import { Header } from 'src/components/ui/Header';
import { Text } from 'src/components/ui/Text';
import { Distribute } from 'src/components/ui/Distribute';
import { TertiaryButton } from '../ui/Button/TertiaryButton';
import { ReactComponent as ShareSVG } from 'src/svgs/icons/share.svg';
import { useNotificationStore } from 'src/store/notificationStore';
import { useCreatorStore } from 'src/store/creatorStore';

const Wrapper = styled.div`
    padding: 1.5rem;
    background-color: #d4f0f5;
    border-radius: 3px;
    box-shadow: ${({ theme }) => theme.effects.blueShadow};
    border: 1px solid rgba(0, 117, 136, 0.2);

    button {
        border-width: 2px;
        display: flex;
        align-items: center;
        padding-left: 8px;
        padding-right: 8px;

        svg {
            height: 16px;
            width: 16px;
            margin-left: 8px;
        }
    }
`;

const ShareLink = styled(Text.withComponent('span'))`
    flex-shrink: 1;
    margin-right: 1rem;
    text-decoration: underline;
    word-break: break-all;
    transition: opacity 200ms;

    :hover {
        opacity: 0.8;
        cursor: pointer;
    }
`;

export const ReferACreator = () => {
    const { trigger } = useNotificationStore();
    const { creator } = useCreatorStore();
    const url = `https://${window.location.host}/creator/signup/${creator?.referralCode}`;

    const copyShareURLToClipboard = () => {
        navigator.clipboard.writeText(url);
        trigger({ text: 'Copied to clipboard' });
    };

    const shareInviteURL = () => {
        if (typeof navigator.share === 'function') {
            navigator.share({
                url,
                title: 'Join me on FLOCK.fitness',
            });
        } else {
            copyShareURLToClipboard();
        }
    };

    return (
        <Wrapper>
            <Header color="darkBlue">Earn more with referrals 🤑</Header>

            <Text color="darkBlue" spacing="small">
                Refer a creator to join FLOCK.fitness and{' '}
                <strong>earn 1%</strong> of their subscription revenue. Share
                your personal sign up link:
            </Text>

            <Distribute>
                <ShareLink
                    color="darkBlue"
                    weight="bold"
                    size="level6"
                    onClick={copyShareURLToClipboard}
                >
                    {url}
                </ShareLink>

                <TertiaryButton
                    backgroundColor="darkBlue"
                    color="white"
                    borderColor="blue02"
                    style={{ alignSelf: 'center' }}
                    data-testid="shareButton"
                    onClick={shareInviteURL}
                >
                    Share <ShareSVG stroke="white" />
                </TertiaryButton>
            </Distribute>
        </Wrapper>
    );
};
