import React from 'react';
import { Field, FieldProps, Formik, FormikProps } from 'formik';
import { Input } from 'src/components/ui/Input/Input';
import { FormActions } from 'src/components/ui/FormActions';
import { PrimaryButton } from 'src/components/ui/Button/PrimaryButton';
import { BasicNameInformation, BasicProfileInformation } from './Onboarding';
import styled from 'src/styles/styled-components';
import * as Yup from 'yup';
import { Text } from 'src/components/ui/Text';
import { Header } from 'src/components/ui/Header';
import { StylelessButton } from 'src/components/ui/Button/StylelessButton';
import { media } from 'src/utils/mediaQueries';

export const SkipButton = styled(StylelessButton)`
    color: ${({ theme }) => theme.colors.darkBlue};
    font-size: ${({ theme }) => theme.font.size.level4.small};
    font-weight: bold;
    text-align: center;
    padding: 1.2rem;

    ${media.l`
        font-size: ${({ theme }) => theme.font.size.level4.large};
    `}
`;

const instagramValidationSchema = Yup.object().shape({
    instagram: Yup.string()
        .matches(
            /^@(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/,
            'Check your instagram handle (remove any spaces)'
        )
        .required('Please enter your instagram to continue'),
});

const INSTA_INITIAL_VALUES = {
    instagram: '',
};

export type Props = {
    createProfile: (data: BasicProfileInformation) => void;
    skipInstagram: () => void;
    basicNameInfo: BasicNameInformation;
};

export const OnboardingInstagramForm = (props: Props) => {
    return (
        <>
            <Header size="level3" alignment="center" spacing="medium">
                Hi {props.basicNameInfo.firstName} 👋
            </Header>

            <Text alignment="center" spacing="medium">
                <strong>Enter your instagram handle</strong> if you have one.
                We’ll link to it from your profile and create your FLOCK profile
                using the same handle
            </Text>

            <Formik
                initialValues={{ ...INSTA_INITIAL_VALUES }}
                validationSchema={instagramValidationSchema}
                onSubmit={(values) => {
                    props.createProfile({
                        ...(props.basicNameInfo as BasicNameInformation),
                        ...values,
                    });
                }}
            >
                {({
                    submitCount,
                    handleSubmit,
                    isSubmitting,
                }: FormikProps<typeof INSTA_INITIAL_VALUES>) => (
                    <form onSubmit={handleSubmit} noValidate>
                        <Field name="instagram">
                            {({ field, meta, form }: FieldProps) => (
                                <Input
                                    {...field}
                                    label="Instagram handle"
                                    type="text"
                                    placeholder="@"
                                    onChange={(changeEvent) => {
                                        const input = changeEvent.target.value;
                                        if (input === '') {
                                            form.setFieldValue('instagram', '');
                                        } else if (input.startsWith('@')) {
                                            form.setFieldValue(
                                                'instagram',
                                                input
                                            );
                                        } else {
                                            form.setFieldValue(
                                                'instagram',
                                                `@${input}`
                                            );
                                        }
                                    }}
                                    error={
                                        (submitCount > 0 && meta.error) || null
                                    }
                                />
                            )}
                        </Field>

                        <FormActions stack>
                            <PrimaryButton
                                type="submit"
                                isSubmitting={isSubmitting}
                            >
                                Continue
                            </PrimaryButton>

                            <SkipButton
                                type="button"
                                onClick={props.skipInstagram}
                            >
                                Skip this step
                            </SkipButton>
                        </FormActions>
                    </form>
                )}
            </Formik>
        </>
    );
};
