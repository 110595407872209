import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { ActiveLink } from 'src/components/ui/ActiveLink';
import styled from 'src/styles/styled-components';
import { ReactComponent as PlusSVG } from 'src/svgs/icons/plus.svg';
import { ReactComponent as HomeSVG } from 'src/svgs/icons/home.svg';
import { ReactComponent as SettingsCogSVG } from 'src/svgs/icons/settings-cog.svg';
import { useCreatorStore } from 'src/store/creatorStore';
import { useUserStore } from 'src/store/userStore';

export const NavWrapper = styled.nav`
    padding: 0.6rem 0;
    background-color: white;
    text-align: right;

    display: flex;
    align-items: center;

    a {
        display: inline-flex;
        margin-left: 1rem;
        width: 3.2rem;
        height: 3.2rem;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: ${({ theme }) => theme.colors.grey6};
    }
`;
export const ProfileNavLink = styled(ActiveLink)`
    display: inline-flex;
    border-radius: 50%;
    overflow: hidden;
    width: 3.6rem;
    height: 3.6rem;

    img {
        min-height: 100%;
        object-fit: cover;
    }
`;

export const Nav: FunctionComponent = () => {
    const { creatorProfile } = useCreatorStore();
    const { userType } = useUserStore();

    return (
        <NavWrapper>
            {userType === 'creator' && (
                <Link to={`/create-workout`}>
                    <PlusSVG />
                </Link>
            )}

            <Link to={`/`}>
                <HomeSVG />
            </Link>

            <Link to="/settings">
                <SettingsCogSVG />
            </Link>

            {userType === 'creator' && creatorProfile && (
                <ProfileNavLink to={`/${creatorProfile.slug}`}>
                    <img src={creatorProfile.image} alt="Profile" />
                </ProfileNavLink>
            )}
        </NavWrapper>
    );
};
