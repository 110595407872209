import React, { FunctionComponent, useCallback, useState } from 'react';
import { createUpload } from '@mux/upchunk';
import { isOfVideoType } from 'src/utils/isOfVideoType';
import { VideoUploader } from '../VideoUploader/VideoUploader';
import { functions } from 'src/firebase';
import { useUserStore } from 'src/store/userStore';

async function getDraftWorkoutData(
    creatorId: string
): Promise<api.response.AssetUploadBody> {
    const fetch = functions.httpsCallable('upload');
    try {
        const result = await fetch({ creatorId });
        const workoutDraft: api.Workout = result.data;
        return workoutDraft;
    } catch (err) {
        console.error(err);
        throw err;
    }
}

const loadVideo = (file: File): Promise<HTMLVideoElement> =>
    new Promise((resolve, reject) => {
        try {
            let video = document.createElement('video');
            video.preload = 'metadata';

            video.onloadedmetadata = function () {
                resolve(video);
            };

            video.onerror = function () {
                reject('Invalid video. Please select a video file.');
            };

            video.src = window.URL.createObjectURL(file);
        } catch (e) {
            reject(e);
        }
    });

async function getVideoFileLength(file: File): Promise<number> {
    const video = await loadVideo(file);
    return video.duration;
}

type Props = {
    workout: string;
    error?: string;
    onError: (error: string | undefined) => void;
    onSuccess: (workoutId: string) => void;
    clearError: () => void;
    clearWorkout: () => void;
};

export const WorkoutVideoUploader: FunctionComponent<Props> = ({
    error,
    onSuccess,
    onError,
    clearError,
    clearWorkout,
    workout,
}) => {
    const [uploadProgress, setUploadProgress] = useState<number | null>(
        workout ? 100 : null
    );
    const { user } = useUserStore();

    const uploadVideoFile = useCallback(
        async (file: File) => {
            clearError();

            if (!isOfVideoType(file)) {
                onError('Please only upload mpeg/mp4 videos');
                return;
            }

            if ((await getVideoFileLength(file)) > 4200) {
                onError('Max video length is 70 minutes');
                return;
            }

            setUploadProgress(0);

            const draftWorkout = await getDraftWorkoutData(user!.uid);

            const upload = createUpload({
                endpoint: draftWorkout.asset.uploadUrl,
                file,
                chunkSize: 5120, // Uploads the file in ~5mb chunks
            });

            // subscribe to events
            upload.on('error', (err) => {
                setUploadProgress(null);
                onError('Error uploading video, please try again');

                console.error('💥 🙀', err.detail);
            });

            upload.on('progress', (progress) => {
                setUploadProgress(progress.detail);
            });

            upload.on('success', () => {
                onSuccess(draftWorkout.id);
            });
        },
        [onSuccess, onError, user, clearError]
    );

    return (
        <VideoUploader
            videoId={workout}
            uploadProgress={uploadProgress}
            clearVideo={() => {
                setUploadProgress(null);
                clearWorkout();
            }}
            setError={onError}
            error={error}
            uploadVideoFile={async (file) => {
                clearError();
                setUploadProgress(0);

                uploadVideoFile(file);
            }}
            instructions={
                <>
                    <p>
                        Please make sure this is the final version of your
                        workout before you upload.
                    </p>
                    <small>20 - 40 minutes recommended (max 70 minutes)</small>
                </>
            }
        />
    );
};
