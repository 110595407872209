import styled from 'src/styles/styled-components';

export const Constrain = styled.div<{
    wide?: boolean;
    narrow?: boolean;
    tiny?: boolean;
    noPadding?: boolean;
}>`
    max-width: ${({ wide, narrow, tiny }) =>
        (wide && '120rem') ||
        (narrow && '72rem') ||
        (tiny && '45rem') ||
        '100rem'};
    margin: 0 auto;
    width: 100%;
    padding: ${({ noPadding }) => (noPadding && '0') || '0 1.8rem'};
`;
