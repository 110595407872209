import { Field, FieldProps, Formik, FormikProps } from 'formik';
import React, { useState } from 'react';
import AuthLayout from 'src/components/layouts/AuthLayout';
import { PrimaryButton } from 'src/components/ui/Button/PrimaryButton';
import { Constrain } from 'src/components/ui/Constrain';
import { FormActions } from 'src/components/ui/FormActions';
import { Input } from 'src/components/ui/Input/Input';
import { BlueNotice } from 'src/components/ui/Notice';
import config from 'src/config';
import { auth } from 'src/firebase';
import styled from 'src/styles/styled-components';
import { media } from 'src/utils/mediaQueries';
import * as Yup from 'yup';

export const Header = styled.h2`
    margin-bottom: 0.5rem;
    font-weight: 600;
    font-size: 20px;
    color: ${({ theme }) => theme.colors.grey1};

    ${media.l`
        font-size: ${({ theme }) => theme.font.size.level2.small};
    `}
`;

const validationSchema = Yup.object().shape({
    emailAddress: Yup.string()
        .email('Please enter a valid email address')
        .required('Please enter a valid email address'),
});

const INITIAL_VALUES = {
    emailAddress: '',
};

export const RequestNewPassword = () => {
    const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
    return (
        <AuthLayout title={`Request a new password | FLOCK.fitness`}>
            <Constrain tiny>
                <Header>Forgot your password?</Header>

                {hasSubmitted && (
                    <BlueNotice>
                        If you have an account with the registered email address
                        then we’ve sent you a link to reset your password.
                        Please check your email.
                    </BlueNotice>
                )}

                <Formik
                    initialValues={{ ...INITIAL_VALUES }}
                    validationSchema={validationSchema}
                    onSubmit={async (values, actions) => {
                        setHasSubmitted(false);
                        try {
                            await auth.sendPasswordResetEmail(
                                values.emailAddress,
                                {
                                    url: `${config.URL}/reset-password/reset`,
                                    handleCodeInApp: true,
                                }
                            );
                        } catch (err) {}
                        setHasSubmitted(true);
                    }}
                >
                    {({
                        submitCount,
                        handleSubmit,
                        isSubmitting,
                    }: FormikProps<typeof INITIAL_VALUES>) => (
                        <form onSubmit={handleSubmit} noValidate>
                            <Field name="emailAddress">
                                {({ field, meta }: FieldProps) => (
                                    <Input
                                        label="Email address"
                                        type="email"
                                        placeholder="Enter your email address"
                                        error={
                                            (submitCount > 0 && meta.error) ||
                                            null
                                        }
                                        {...field}
                                    />
                                )}
                            </Field>

                            <FormActions>
                                <PrimaryButton
                                    type="submit"
                                    isSubmitting={isSubmitting}
                                >
                                    Request password reset email
                                </PrimaryButton>
                            </FormActions>
                        </form>
                    )}
                </Formik>
            </Constrain>
        </AuthLayout>
    );
};
