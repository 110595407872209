import React from 'react';
import { Normalize } from 'styled-normalize';
import { createGlobalStyle } from './styled-components';
import theme from './theme';

const BodyStyles = createGlobalStyle`
    html,
    body {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-family: ${theme.font.type};
        font-size: ${theme.font.size.base};
        line-height: 1.5;
        color: ${theme.colors.black};
        height: 100%;
    }

    * {
        box-sizing: border-box;
    }

    body {
        background: #fff;
        font-size: ${theme.font.size.level4.small};
    }

    #__next {
        height: 100%;
    }

    img {
        width: 100%;
    }

    h1 {
        font-size: ${theme.font.size.level1.small};
        font-weight: normal;
    }

    h2 {
        font-size: ${theme.font.size.level2.small};
        font-weight: normal;
    }

    h3 {
        font-size: ${theme.font.size.level3.small};
        font-weight: normal;
    }

    a {
        color: ${theme.colors.black};
    }
`;

const MarginReset = createGlobalStyle`
    h1, h2, h3, h4, h5, h6, p {
        margin-top: 0;
    }
`;

const GlobalStyles = () => (
    <>
        <Normalize />
        <BodyStyles />
        <MarginReset />
    </>
);

export default GlobalStyles;
