import React from 'react';
import styled from 'src/styles/styled-components';
import { Loading } from './Loading';

export const LoadingPage = styled(({ className }) => (
    <div className={className}>
        <Loading />
    </div>
))`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
`;
