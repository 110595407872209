import React, {
    useEffect,
    useContext,
    createContext,
    useCallback,
    ReactNode,
} from 'react';
import { useCreatorStore } from 'src/store/creatorStore';
import { useSubscriberStore } from 'src/store/subscriberStore';
import { useUserStore } from 'src/store/userStore';
import { delay } from 'src/utils/delay';
import { auth } from '../firebase';

type UserType = 'creator' | 'subscriber';

const AuthContext = createContext<{
    logout: () => void;
}>({} as any);

async function getUserType(user: firebase.default.User): Promise<UserType> {
    const token = await user.getIdTokenResult(true);

    if (!token.claims.creator && !token.claims.subscriber) {
        await delay(500);
        return getUserType(user);
    }

    return token.claims.creator ? 'creator' : 'subscriber';
}

export function AuthProvider({ children }: { children: ReactNode }) {
    const { setUser, setLoading } = useUserStore();
    const resetCreatorState = useCreatorStore((state) => state.resetState);
    const resetSubscriberState = useSubscriberStore(
        (state) => state.resetState
    );

    const logout = useCallback(() => {
        auth.signOut();
        resetCreatorState();
        resetSubscriberState();
    }, [resetCreatorState, resetSubscriberState]);

    useEffect(() => {
        return auth.onAuthStateChanged(async (user) => {
            if (user) {
                const userTypeFromToken = await getUserType(user);
                setUser(user, userTypeFromToken);
            } else {
                setUser(null, null);
            }

            setLoading(false);
        });
    }, [setUser, setLoading]);

    return (
        <AuthContext.Provider value={{ logout }}>
            {children}
        </AuthContext.Provider>
    );
}

export const useAuth = () => {
    return useContext(AuthContext);
};
