import React, {
    FunctionComponent,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { LoadingPage } from 'src/components/ui/LoadingPage';
import { Modal } from 'src/components/ui/Modal';
import { functions, firestore } from 'src/firebase';
import { useCreatorStore } from 'src/store/creatorStore';
import { CreatePreviewVideoForm } from './CreatePreviewVideoForm';
import { ReviewPreviewVideo } from './ReviewPreviewVideo';

const publishPreviewVideo = functions.httpsCallable('publishPreviewVideo');

enum DISPLAY_STATE {
    create,
    loading,
    preview,
}

export const ManagePreviewVideoModal: FunctionComponent = () => {
    const history = useHistory();
    const creatorProfilePreview = useCreatorStore(
        (state) => state.creatorProfilePreview
    );
    const { loading, creatorProfile } = useCreatorStore();

    const [displayState, setDisplayState] = useState<DISPLAY_STATE>(
        DISPLAY_STATE.create
    );
    const [draftPreviewVideoId, setDraftPreviewVideoId] = useState<
        string | null
    >(creatorProfilePreview?.id || null);
    const [loadedDraftPreviewVideo, setLoadedDraftPreviewVideo] =
        useState<api.CreatorPreviewVideo | null>(null);
    const [isOpen, setIsOpen] = useState(true);

    const handleSuccessfulSave = useCallback(
        async (previewVideoId: string) => {
            // Skip loading state if editing a workout (updates should come through automatically)
            if (draftPreviewVideoId === previewVideoId) {
                setDisplayState(DISPLAY_STATE.preview);
                return;
            }

            setDisplayState(DISPLAY_STATE.loading);
            setDraftPreviewVideoId(previewVideoId);
        },
        [draftPreviewVideoId]
    );

    useEffect(() => {
        if (creatorProfilePreview) {
            setDisplayState(DISPLAY_STATE.loading);
            setDraftPreviewVideoId(creatorProfilePreview.id);
        }
    }, [creatorProfilePreview, loading]);

    useEffect(() => {
        if (draftPreviewVideoId !== null) {
            return firestore
                .collection('creatorProfiles')
                .doc(creatorProfile!.id)
                .collection('previews')
                .doc(draftPreviewVideoId)
                .onSnapshot((previewVideoDoc) => {
                    setLoadedDraftPreviewVideo(
                        previewVideoDoc.data() as api.CreatorPreviewVideo
                    );
                    setDisplayState(DISPLAY_STATE.preview);
                });
        }
    }, [draftPreviewVideoId, creatorProfile, loading]);

    const publish = useCallback(
        async (draftPreviewVideoId: string) => {
            await publishPreviewVideo({
                profileId: creatorProfile!.id,
                previewVideoId: draftPreviewVideoId,
            } as api.request.PublishPreviewVideoBody);

            setIsOpen(false);
        },
        [creatorProfile]
    );

    if (!creatorProfile) {
        return <div>Profile not found.</div>;
    }

    return (
        <>
            <Modal
                isOpen={isOpen}
                customHeader={
                    <>
                        <h2>Preview video</h2>
                    </>
                }
                onClose={() => setIsOpen(false)}
                onAfterClose={() => history.push(`/${creatorProfile?.slug}`)}
            >
                {displayState === DISPLAY_STATE.loading && <LoadingPage />}

                {displayState === DISPLAY_STATE.create && (
                    <CreatePreviewVideoForm
                        draftPreviewVideo={loadedDraftPreviewVideo}
                        profile={creatorProfile}
                        onSuccess={handleSuccessfulSave}
                    />
                )}

                {displayState === DISPLAY_STATE.preview &&
                    loadedDraftPreviewVideo && (
                        <ReviewPreviewVideo
                            previewVideo={loadedDraftPreviewVideo}
                            onBack={() => setDisplayState(DISPLAY_STATE.create)}
                            isDraft={
                                loadedDraftPreviewVideo.id !==
                                creatorProfilePreview?.id
                            }
                            publish={publish}
                        />
                    )}
            </Modal>
        </>
    );
};
