import React, { useEffect, useContext, createContext } from 'react';
import { useCreatorStore } from 'src/store/creatorStore';
import { useUserStore } from 'src/store/userStore';
import { firestore } from 'src/firebase';

const CreatorContext = createContext<{}>({} as any);

export function CreatorProvider({ children }: { children: any }) {
    const { user, userType } = useUserStore();
    const {
        setLoading,
        setCreatorProfile,
        creatorProfile,
        setWorkouts,
        workouts,
        creator,
        setCreator,
        setCreatorProfilePreview,
    } = useCreatorStore();

    // handle has things currently due that aren't related to verification
    // show banner to complete onboarding
    // prevent visit dashboard from working (show an error that they need to complete onboarding first)

    // Fetch creator entity
    useEffect(() => {
        function fetchCreator(userId: string) {
            return firestore
                .collection('creators')
                .doc(userId)
                .onSnapshot((doc) => {
                    setCreator(doc.data() as api.Creator);
                });
        }

        if (user && userType === 'creator') {
            return fetchCreator(user.uid);
        }
    }, [setCreator, setLoading, user, userType]);

    // Fetch creator profile and preview video entities
    useEffect(() => {
        function fetchCreatorProfile(userId: string) {
            return firestore
                .collection('creatorProfiles')
                .doc(userId)
                .onSnapshot(async (doc) => {
                    if (!doc.exists) {
                        setLoading(false);
                    }

                    setCreatorProfile(doc.data() as api.CreatorProfile);

                    const profilePreviewQuery = await doc.ref
                        .collection('previews')
                        .where('status', '==', 'published')
                        .get();

                    if (profilePreviewQuery && profilePreviewQuery.size === 1) {
                        setCreatorProfilePreview(
                            profilePreviewQuery.docs[0].data() as any
                        );
                    }
                });
        }

        if (user && userType === 'creator') {
            return fetchCreatorProfile(user.uid);
        }
    }, [
        setCreatorProfile,
        setCreatorProfilePreview,
        setLoading,
        user,
        userType,
    ]);

    // Fetch a creators workouts
    useEffect(() => {
        function fetchCreatorWorkouts(userId: string) {
            return firestore
                .collection('workouts')
                .where('profileId', '==', userId)
                .where('status', '==', 'published')
                .onSnapshot(
                    (snapshot) => {
                        const fetchedWorkouts: flock.Workout[] = [];
                        snapshot.forEach(function (doc) {
                            fetchedWorkouts.push(doc.data() as flock.Workout);
                        });

                        setWorkouts(fetchedWorkouts);
                    },
                    (err) => {
                        console.error(err);
                    }
                );
        }

        if (user && userType === 'creator' && creatorProfile) {
            return fetchCreatorWorkouts(user.uid);
        }
    }, [
        creatorProfile,
        setCreatorProfile,
        setLoading,
        setWorkouts,
        user,
        userType,
    ]);

    useEffect(() => {
        if (creator && creatorProfile && workouts) {
            setLoading(false);
        }
    }, [creator, creatorProfile, workouts, setLoading]);

    return (
        <CreatorContext.Provider value={{}}>{children}</CreatorContext.Provider>
    );
}

export const useCreator = () => {
    return useContext(CreatorContext);
};
