import React, { ReactNode } from 'react';
import styled from 'src/styles/styled-components';
import { media } from 'src/utils/mediaQueries';
import { Constrain } from '../ui/Constrain';
import { Helmet } from 'react-helmet';
import logo from 'src/images/FLOCK.fitness-logo.svg';
import { Nav } from '../Nav/Nav';
import { NotificationBanner } from '../NotificationBanner/NotificationBanner';
import { Link, useHistory } from 'react-router-dom';
import { useUserStore } from 'src/store/userStore';

const Header = styled.header`
    padding: 0.8rem 0;
    text-align: center;
    line-height: 1;
    min-height: 6rem;

    ${Constrain} {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        min-height: 4.4rem;
    }
`;

const Logo = styled.img`
    width: 10rem;

    &:hover {
        cursor: pointer;
    }

    ${media.l`
        width: 12rem;
    `}
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const ScrollArea = styled.div`
    flex: 1;
    overflow: auto;
`;

const LinkUnderline = styled(Link)`
    font-weight: 500;
    text-decoration: underline;
    padding: 0.5rem 0;
    text-decoration-color: ${({ theme }) => theme.colors.turquoise};
    text-decoration-thickness: 3px;
    text-decoration-skip-ink: none;
`;

type Props = {
    children?: ReactNode;
    title?: string;
    description: string;
};

const AppLayout = ({
    children,
    title = 'This is the default title',
    description = 'Welcome to FLOCK.fitness',
}: Props) => {
    const { user } = useUserStore();
    const { push } = useHistory();
    const isLoggedIn = !!user;

    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta charSet="utf-8" />

                <meta name="description" content={description} />
            </Helmet>

            <Body>
                <NotificationBanner />

                <Header>
                    <Constrain>
                        <Logo
                            src={logo}
                            alt="FLOCK.fitness logo"
                            onClick={() => push('/')}
                        />

                        {isLoggedIn && <Nav />}
                        {!isLoggedIn && (
                            <LinkUnderline to="/">Log in</LinkUnderline>
                        )}
                    </Constrain>
                </Header>

                <ScrollArea>{children}</ScrollArea>
            </Body>
        </>
    );
};

export default AppLayout;
