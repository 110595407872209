import { Field, FieldProps, Formik, FormikProps } from 'formik';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import AuthLayout from 'src/components/layouts/AuthLayout';
import { PrimaryButton } from 'src/components/ui/Button/PrimaryButton';
import { Constrain } from 'src/components/ui/Constrain';
import { FormActions } from 'src/components/ui/FormActions';
import { Input } from 'src/components/ui/Input/Input';
import { BlueNotice, RedNotice } from 'src/components/ui/Notice';
import { auth } from 'src/firebase';
import styled from 'src/styles/styled-components';
import { media } from 'src/utils/mediaQueries';
import * as Yup from 'yup';
import { ReactComponent as RightArrowSVG } from 'src/svgs/icons/right-arrow.svg';

export const Header = styled.h2`
    margin-bottom: 0.5rem;
    font-weight: 600;
    font-size: 20px;
    color: ${({ theme }) => theme.colors.grey1};

    ${media.l`
        font-size: ${({ theme }) => theme.font.size.level2.small};
    `}
`;

const LoginLink = styled(Link)`
    text-decoration: none;
    font-weight: 500;

    u {
        text-decoration: underline;
        text-decoration-color: ${({ theme }) => theme.colors.turquoise};
        text-decoration-thickness: 2px;
    }

    svg {
        margin-left: 1rem;
    }
`;

const AlignRight = styled.div`
    text-align: right;
`;

const validationSchema = Yup.object().shape({
    password: Yup.string()
        .required('Please enter a password')
        .min(8, 'Please enter a password of at least 8 characters')
        .matches(/\d/, 'Your password contain at least one number'),
    confirmPassword: Yup.string().when('password', {
        is: (value: string) => (value && value.length > 0 ? true : false),
        then: Yup.string()
            .oneOf([Yup.ref('password')], 'Both passwords need to be the same')
            .required('Please confirm your password'),
    }),
});

const INITIAL_VALUES = {
    password: '',
    confirmPassword: '',
};

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export const ResetNewPassword = () => {
    const param = useQuery();
    const verificationCode = param.get('oobCode');
    const [wasSuccessful, setWasSuccessful] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    return (
        <AuthLayout title={`Create a new password | FLOCK.fitness`}>
            <Constrain tiny>
                <Header>Create a new password</Header>

                {wasSuccessful && (
                    <>
                        <BlueNotice>
                            You new password has been saved! You can now use
                            your new password to log in.
                        </BlueNotice>

                        <AlignRight>
                            <LoginLink to="/">
                                Go to <u>login</u>
                                <RightArrowSVG />
                            </LoginLink>
                        </AlignRight>
                    </>
                )}

                {error && <RedNotice>{error}</RedNotice>}

                {!wasSuccessful && (
                    <Formik
                        initialValues={{ ...INITIAL_VALUES }}
                        validationSchema={validationSchema}
                        onSubmit={async (values, actions) => {
                            if (!verificationCode) {
                                throw new Error(
                                    'No verification code supplied'
                                );
                            }

                            try {
                                await auth.confirmPasswordReset(
                                    verificationCode,
                                    values.password
                                );

                                setWasSuccessful(true);
                            } catch (err) {
                                setError(
                                    'Looks like this reset link has expired, please try again'
                                );
                            }
                        }}
                    >
                        {({
                            submitCount,
                            handleSubmit,
                            isSubmitting,
                        }: FormikProps<typeof INITIAL_VALUES>) => (
                            <form onSubmit={handleSubmit} noValidate>
                                <Field name="password">
                                    {({ field, meta }: FieldProps) => (
                                        <Input
                                            label="Password"
                                            type="password"
                                            placeholder="Enter a password"
                                            error={
                                                (submitCount > 0 &&
                                                    meta.error) ||
                                                null
                                            }
                                            {...field}
                                        />
                                    )}
                                </Field>

                                <Field name="confirmPassword">
                                    {({ field, meta }: FieldProps) => (
                                        <Input
                                            label="Confirm password"
                                            type="password"
                                            placeholder="Confirm your password"
                                            error={
                                                (submitCount > 0 &&
                                                    meta.error) ||
                                                null
                                            }
                                            {...field}
                                        />
                                    )}
                                </Field>

                                <FormActions>
                                    <PrimaryButton
                                        type="submit"
                                        isSubmitting={isSubmitting}
                                    >
                                        Set new password
                                    </PrimaryButton>
                                </FormActions>
                            </form>
                        )}
                    </Formik>
                )}
            </Constrain>
        </AuthLayout>
    );
};
