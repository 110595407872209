import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { createEditor, Node } from 'slate';
import {
    Slate,
    Editable,
    withReact,
    RenderLeafProps,
    RenderElementProps,
} from 'slate-react';
import styled from 'src/styles/styled-components';
import { Leaf } from './Leaf';
import { ReactComponent as BulletListSVG } from 'src/svgs/icons/bullet-list.svg';
import { StyledInputLabel } from '../Input/Input';
import { media } from 'src/utils/mediaQueries';
import { Element } from './Element';
import { MarkButton, BlockButton } from './EditorButton';

type Props = {
    label: string;
    hint?: string;
    value: Node[];
    setValue: (value: Node[]) => void;
};

export type LeafFormats = 'bold' | 'italic' | 'underline';
export type BlockFormats = 'bulleted-list';

export const LIST_TYPES = ['bulleted-list'];

const LabelInputWrapper = styled.div`
    -webkit-column-break-inside: avoid; /* Chrome, Safari, Opera */
    page-break-inside: avoid; /* Firefox */
    break-inside: avoid;
`;

const Wrapper = styled.div`
    appearance: none;
    border-radius: 3px;
    border: solid 1px ${({ theme }) => theme.colors.grey3};
    display: block;
    width: 100%;
    font-size: ${({ theme }) => theme.font.size.level5.small};
    position: relative;
    overflow: hidden;
    margin-bottom: 2rem;

    ${media.m`
        font-size: ${({ theme }) => theme.font.size.level4.small};
        margin-bottom: 3rem;
    `}
`;

const ToolbarGroup = styled.div`
    padding: 0.5rem 1rem;
    display: inline-flex;

    & > *:not(:first-child) {
        margin-left: 0.5rem;
    }
`;

const Toolbar = styled.div`
    display: flex;
    border-bottom: solid 1px ${({ theme }) => theme.colors.grey3};
`;

const EditorWrapper = styled.div`
    padding: 0.7rem 1rem;
    height: 15rem;
    overflow-y: auto;

    ${media.m`
        height: 30rem;
    `}

    & > div {
        min-height: 10rem;
    }

    ul {
        margin: 1rem 0;
        padding-left: 2rem;
    }

    p {
        margin-bottom: 0.5rem;
    }
`;

const Hint = styled.small`
    display: block;
    margin-bottom: 0.7rem;

    ${media.m`
        font-size: ${({ theme }) => theme.font.size.level5.small};
    `}
`;

export const RichTextEditor: FunctionComponent<Props> = ({
    label,
    hint,
    value,
    setValue,
}) => {
    const editor = useMemo(() => withReact(createEditor()), []);
    const renderLeaf = useCallback(
        (props: RenderLeafProps) => <Leaf {...props} />,
        []
    );
    const renderElement = useCallback(
        (props: RenderElementProps) => <Element {...props} />,
        []
    );

    return (
        <LabelInputWrapper>
            <StyledInputLabel hasHint={!!hint}>{label}</StyledInputLabel>

            {hint && <Hint>{hint}</Hint>}

            <Wrapper>
                <Slate
                    editor={editor}
                    value={value}
                    onChange={(newValue) => setValue(newValue)}
                >
                    <Toolbar>
                        <ToolbarGroup>
                            <MarkButton format="bold">
                                <strong>B</strong>
                            </MarkButton>
                            <MarkButton format="italic">
                                <em style={{ marginLeft: -2 }}>I</em>
                            </MarkButton>
                            <MarkButton format="underline">
                                <u>U</u>
                            </MarkButton>
                        </ToolbarGroup>

                        <ToolbarGroup>
                            <BlockButton format="bulleted-list">
                                <BulletListSVG />
                            </BlockButton>
                        </ToolbarGroup>
                    </Toolbar>

                    <EditorWrapper>
                        <Editable
                            renderLeaf={renderLeaf}
                            renderElement={renderElement}
                            placeholder=" - Sit ups (30 seconds)"
                            spellCheck
                        />
                    </EditorWrapper>
                </Slate>
            </Wrapper>
        </LabelInputWrapper>
    );
};
