import { Editor, Element as SlateElement } from 'slate';
import { BlockFormats } from '../RichTextEditor';

export const isBlockActive = (editor: Editor, format: BlockFormats) => {
    const [match] = Editor.nodes(editor, {
        match: (n) =>
            !Editor.isEditor(n) &&
            SlateElement.isElement(n) &&
            n.type === format,
    }) as any;

    return !!match;
};
