import React, { FunctionComponent } from 'react';
import styled from 'src/styles/styled-components';
import 'firebase/functions';
import { StyledInputError, StyledInputLabel } from '../ui/Input/Input';
import { ReactComponent as UploadSVG } from '../../svgs/icons/upload.svg';
import { ReactComponent as CircleTickIcon } from '../../svgs/icons/circle-tick.svg';
import { Loading } from '../ui/Loading';
import { media } from 'src/utils/mediaQueries';
import { isOfVideoType } from 'src/utils/isOfVideoType';
import { StylelessButton } from '../ui/Button/StylelessButton';

const Wrapper = styled.div`
    position: relative;
`;

const Label = styled.label`
    display: flex;
    flex-direction: column;
    align-content: center;
    text-align: center;

    span {
        display: inline-flex;
        margin-bottom: 1rem;
        justify-content: center;
        align-items: center;

        ${media.m`
            font-size: ${({ theme }) => theme.font.size.level3.small};
        `}
    }

    P {
        margin: 0 auto;
        max-width: 90%;
        margin-bottom: 1rem;
        font-size: ${({ theme }) => theme.font.size.level5.small};

        ${media.m`
            font-size: ${({ theme }) => theme.font.size.level4.small};
        `}
    }

    svg {
        width: 2rem;
        height: 2rem;
        margin-right: 1rem;
    }
`;

const Uploading = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
        display: block;

        ${media.m`
            font-size: ${({ theme }) => theme.font.size.level3.small};
        `}
    }

    svg {
        margin-left: 2rem;
    }

    p {
        margin: 0;
        margin-top: 0.4rem;
        font-size: ${({ theme }) => theme.font.size.level6.small};

        ${media.m`
            font-size: ${({ theme }) => theme.font.size.level5.small};
        `}
    }
`;

const UploadedWrapper = styled.div`
    text-align: right;
    font-size: 14px;

    ${Uploading} {
        margin-bottom: 1.5rem;
    }
`;

const VideoUpload = styled.div`
    border-radius: 3px;
    border: solid 1px ${({ theme }) => theme.colors.grey3};
    padding: 1.6rem;
    margin-bottom: 2.4rem;

    ${media.m`
    padding: 2rem;
        `}
`;

const HiddenInput = styled.input`
    position: absolute;
    left: -9999px;
`;

const LinkButton = styled(StylelessButton)`
    text-decoration: underline;
`;

type Props = {
    videoId?: string;
    uploadProgress: number | null;
    instructions: JSX.Element;
    setError: (error: string | undefined) => void;
    error?: string;
    uploadVideoFile(file: File): Promise<void>;
    clearVideo(): void;
};

export const VideoUploader: FunctionComponent<Props> = ({
    videoId,
    uploadProgress,
    uploadVideoFile,
    instructions,
    error,
    setError,
    clearVideo,
}) => {
    const isUntouched = uploadProgress === null && !videoId;
    const isStarting = uploadProgress === 0;
    const isUploading =
        uploadProgress !== null && uploadProgress > 0 && uploadProgress < 100;
    const isComplete = uploadProgress === 100;

    return (
        <Wrapper>
            <StyledInputLabel>Video upload</StyledInputLabel>

            <VideoUpload>
                {isUntouched && (
                    <Label htmlFor="uploadVideo">
                        <span>
                            <UploadSVG />
                            Upload video
                        </span>

                        {instructions}
                    </Label>
                )}

                {uploadProgress !== null && !videoId && (
                    <Uploading>
                        <div>
                            {isStarting && <span>Starting upload</span>}
                            {isUploading && (
                                <span>
                                    Uploading {uploadProgress.toFixed(0)}%
                                </span>
                            )}
                            {isComplete && <span>Upload complete</span>}
                            {(isStarting || isUploading) && (
                                <p>
                                    Please leave this page open until the upload
                                    has completed.
                                </p>
                            )}
                        </div>
                        {(isStarting || isUploading) && <Loading />}
                        {isComplete && (
                            <CircleTickIcon style={{ stroke: '#54F1AF' }} />
                        )}
                    </Uploading>
                )}

                {videoId && (
                    <UploadedWrapper>
                        <Uploading>
                            <span>Upload complete</span>
                            <CircleTickIcon
                                style={{
                                    stroke: '#54F1AF',
                                }}
                            />
                        </Uploading>
                        <LinkButton onClick={clearVideo}>
                            Upload a different video
                        </LinkButton>
                    </UploadedWrapper>
                )}

                <HiddenInput
                    type="file"
                    accept="video/mp4,video/mpeg,video/quicktime"
                    id="uploadVideo"
                    data-testid="uploadVideo"
                    onChange={async (e) => {
                        if (e.target.files?.length === 0) {
                            return;
                        }

                        const file: File = e.target.files![0];

                        if (!isOfVideoType(file)) {
                            setError('Please only upload mpeg/mp4/mov videos');
                            return;
                        }

                        uploadVideoFile(file);
                    }}
                />

                {error && <StyledInputError>{error}</StyledInputError>}
            </VideoUpload>
        </Wrapper>
    );
};
