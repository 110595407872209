import { darken } from 'polished';
import React, { FunctionComponent } from 'react';
import styled from 'src/styles/styled-components';
import { Loading } from '../Loading';

interface ButtonProps
    extends React.DetailedHTMLProps<
        React.ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
    > {
    isSubmitting?: boolean;
}

export const StyledPrimaryButton = styled.button`
    appearance: none;
    background-color: ${({ theme }) => theme.colors.turquoise};
    border: solid 2px ${({ theme }) => darken(0.17, theme.colors.turquoise)};
    border-radius: 3px;
    padding: 1.2rem;
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    color: ${({ theme }) => theme.colors.black};

    svg {
        width: 30px;
        height: 16px;
    }

    &:hover {
        cursor: pointer;
    }
`;

export const PrimaryButton: FunctionComponent<ButtonProps> = ({
    children,
    isSubmitting,
    ref,
    ...props
}) => {
    return (
        <StyledPrimaryButton
            {...props}
            disabled={isSubmitting}
            data-testid="primaryButton"
        >
            {(isSubmitting && <Loading />) || children}
        </StyledPrimaryButton>
    );
};
