import { CreatorState } from '../creatorStore';

export const isCreatorPendingVerification = (state: CreatorState) => {
    if (state.creator === null) {
        return false;
    }

    return (
        state.creator.stripeAccount?.requirements?.pending_verification?.some(
            (requirement: string) =>
                requirement.includes('verification.additional_document')
        ) ||
        state.creator.stripeAccount?.requirements?.pending_verification?.some(
            (requirement: string) =>
                requirement.includes('verification.document')
        ) ||
        false
    );
};
