import React from 'react';
import { useHistory } from 'react-router-dom';
import { CreateSubscriberAccount } from 'src/components/CreateAccount/CreateSubscriberAccount';
import AuthLayout from 'src/components/layouts/AuthLayout';
import { Constrain } from 'src/components/ui/Constrain';
import { Heading } from 'src/components/ui/Heading';
import styled from 'src/styles/styled-components';

const Wrapper = styled.div`
    padding: 1rem 0;
`;

const SubscriberSignUp = () => {
    const history = useHistory();

    return (
        <AuthLayout title="Create a free subscriber account | FLOCK.fitness">
            <Constrain tiny>
                <Wrapper>
                    <Heading>
                        Create your subscriber account and join a FLOCK
                    </Heading>
                </Wrapper>
                <Wrapper>
                    <CreateSubscriberAccount
                        onSuccess={() => history.push('/')}
                    />
                </Wrapper>
            </Constrain>
        </AuthLayout>
    );
};

export default SubscriberSignUp;
