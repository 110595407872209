import styled, { css } from 'src/styles/styled-components';
import React from 'react';
import TextareaAutosize from 'react-autosize-textarea/lib';
import { StyledInputError, StyledInputLabel } from './Input';

interface TextareaProps extends TextareaAutosize.Props {
    label?: string;
    hint?: string;
    error: string | null;
    charLimit?: number;
}

const StyledTextarea = styled(TextareaAutosize)`
    appearance: none;
    border-radius: 3px;
    border: solid 1px ${({ theme }) => theme.colors.grey3};
    display: block;
    width: 100%;
    padding: 1.1rem 1.4rem;
    font-size: ${({ theme }) => theme.font.size.level4.small};
    padding-right: 3rem;

    &:focus {
        border-color: ${({ theme }) => theme.colors.turquoise};
    }

    &::placeholder {
        color: ${({ theme }) => theme.colors.grey0};
    }
`;

const CharLimit = styled.span`
    display: inline-block;
    position: absolute;
    right: 0.7rem;
    bottom: 0.5rem;
    font-size: 1.2rem;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.dark};
`;

export const Textarea = styled(
    ({
        label,
        error,
        name,
        hint,
        className,
        charLimit,
        ...props
    }: TextareaProps) => (
        <div className={className}>
            {label && (
                <StyledInputLabel htmlFor={`${name}-id`}>
                    {label}
                </StyledInputLabel>
            )}
            <StyledTextarea
                name={name}
                id={`${name}-id`}
                {...(props as any)}
                rows={2}
                maxLength={charLimit}
            />
            {error && <StyledInputError>{error}</StyledInputError>}
            {charLimit && (
                <CharLimit>
                    {charLimit - (props.value! as string).length}
                </CharLimit>
            )}
        </div>
    )
)<TextareaProps>`
    position: relative;
    margin-bottom: 2.4rem;

    ${({ error }) =>
        error &&
        css`
            ${StyledTextarea} {
                border-color: ${({ theme }) => theme.colors.pinkRed};
            }
        `}

    ${({ hint }) =>
        hint &&
        css`
            ${StyledInputLabel} {
                margin-bottom: 0.2rem;
            }
        `}
`;
