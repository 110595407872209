import styled from 'src/styles/styled-components';

export const StylelessButton = styled.button`
    appearance: none;
    border: 0;
    margin: 0;
    padding: 0;
    background: none;

    &:hover {
        cursor: pointer;
    }
`;
