/* eslint-disable react-hooks/rules-of-hooks */
import { Field, FieldProps, Formik, FormikProps } from 'formik';
import { darken } from 'polished';
import React, { FunctionComponent, useCallback, useState } from 'react';
import * as Yup from 'yup';
import { PrimaryButton } from '../ui/Button/PrimaryButton';
import { FormActions } from '../ui/FormActions';
import { Input } from '../ui/Input/Input';
import { Textarea } from '../ui/Input/Textarea';
import { Loading } from '../ui/Loading';
import { ReactComponent as CameraSVG } from '../../svgs/icons/camera.svg';
import { ReactComponent as UploadSVG } from '../../svgs/icons/upload.svg';

import { firestore, storage } from 'src/firebase';

import styled, { css } from 'src/styles/styled-components';
import { uploadImageToStorage } from 'src/utils/uploadImageToStorage';

function isOfImageType(file: File): boolean {
    return ['image/png', 'image/jpeg'].includes(file.type);
}

const Error = styled.div`
    color: ${({ theme }) => theme.colors.pinkRed};
    font-weight: 500;
    text-align: center;
    margin-bottom: 2rem;
`;

const ProfileImage = styled.div<{ isLoading: boolean }>`
    display: inline-flex;
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 2rem;
    position: relative;

    &:before {
        content: '';
        position: absolute;
        background-color: rgba(255, 255, 255, 0.6);
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        opacity: 0;
        transition: opacity 300ms;

        ${({ isLoading }) =>
            isLoading &&
            css`
                opacity: 1;
            `}
    }

    img {
        min-width: 100%;
        min-height: 100%;
        object-fit: cover;
    }

    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 40px;
    }
`;

const ImageOptionsWrapper = styled.div`
    text-align: center;
    margin-bottom: 2rem;
`;

const validationSchema = Yup.object().shape({
    displayName: Yup.string()
        .min(5, 'Display name must be at least 5 characters')
        .max(45, 'Display name is too long')
        .required('Please enter a display name'),
    slug: Yup.string()
        .matches(
            /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/gim,
            'No special character or spaces allowed'
        )
        .required('You must specify a slug'),
    instagram: Yup.string().matches(
        /^@(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/,
        'Check your instagram handle (remove any spaces)'
    ),
    bio: Yup.string().required('Please enter a bio'),
});

const ButtonLabel = styled.label`
    appearance: none;
    background-color: white;
    border: solid 1px ${({ theme }) => darken(0.17, theme.colors.black)};
    border-radius: 3px;
    padding: 0.2rem 1rem;
    text-align: center;
    font-weight: normal;
    font-size: ${({ theme }) => theme.font.size.level5.small};
    display: inline-flex;
    align-items: center;
    cursor: pointer;

    svg {
        margin-right: 0.5rem;
    }
`;

const HiddenInput = styled.input`
    position: absolute;
    left: -9999px;
`;

const ProfileActions = styled.div`
    display: flex;
    justify-content: center;

    & > div:first-child {
        margin-right: 1rem;
    }
`;

const Form = styled.form`
    max-width: 500px;
    margin: 0 auto;
`;

type Props = {
    profile: api.CreatorProfile;
    onSuccess: (newSlug?: string) => void;
};

type Values = Omit<api.CreatorProfile, 'id'>;

export const EditProfile: FunctionComponent<Props> = ({
    profile,
    onSuccess,
}) => {
    const [error, setError] = useState<string | null>(null);
    const [storageRef] = useState(storage.ref());
    const [isUploading, setIsUploading] = useState(false);

    return (
        <Formik
            initialValues={{
                ...profile,
                instagram:
                    profile.instagram.length > 1 ? `@${profile.instagram}` : '',
            }}
            validationSchema={validationSchema}
            onSubmit={async (values, actions) => {
                try {
                    //check slug hasn't been used
                    const profilesWithSlugSnapshot = await firestore
                        .collection('creatorProfiles')
                        .where('slug', '==', values.slug)
                        .get();

                    if (
                        profile.slug !== values.slug &&
                        !profilesWithSlugSnapshot.empty
                    ) {
                        actions.setErrors({
                            slug: 'This profile handle already exists',
                        });
                        actions.setSubmitting(false);
                        return;
                    }

                    await firestore
                        .collection('creatorProfiles')
                        .doc(profile.id)
                        .set(
                            {
                                ...values,
                                instagram: values.instagram.replace('@', ''),
                            },
                            { merge: true }
                        );

                    actions.setSubmitting(false);

                    onSuccess(values.slug);
                } catch (err) {
                    setError('Failed to save profile');
                }
            }}
        >
            {({
                submitCount,
                handleSubmit,
                isSubmitting,
                values,
                setErrors,
                setFieldValue,
                errors,
            }: FormikProps<Values>) => {
                const uploadFile = useCallback(
                    async (id: string, file: File) => {
                        if (!isOfImageType(file)) {
                            setErrors({
                                image: 'Please only upload an image',
                            });
                            return;
                        }

                        if (file.size > 7 * 1024 * 1024) {
                            setErrors({
                                image: 'Please upload a image that is less than 7MB',
                            });
                            return;
                        }

                        const photoOfIdRef = storageRef.child(
                            `profile-images/${id}/`
                        );

                        try {
                            const { downloadUrl } = await uploadImageToStorage(
                                photoOfIdRef,
                                file
                            );

                            setFieldValue('image', downloadUrl);
                        } catch (err) {
                            setErrors({
                                image: 'Failed to upload image',
                            });
                        }
                    },
                    [setErrors, setFieldValue]
                );
                return (
                    <Form onSubmit={handleSubmit} noValidate>
                        <ImageOptionsWrapper>
                            <ProfileImage isLoading={isUploading}>
                                <img src={values.image} alt="profile" />

                                {isUploading && <Loading />}
                            </ProfileImage>

                            <ProfileActions>
                                <div>
                                    <ButtonLabel htmlFor="upload">
                                        <UploadSVG />
                                        Upload
                                    </ButtonLabel>
                                    <HiddenInput
                                        type="file"
                                        name="uploadImage"
                                        accept="image/*"
                                        id="upload"
                                        onChange={async (e) => {
                                            if (e.target.files?.length === 0) {
                                                return;
                                            }

                                            setIsUploading(true);
                                            const file: File =
                                                e.target.files![0];
                                            await uploadFile(profile.id, file);
                                            setIsUploading(false);
                                        }}
                                    />
                                </div>

                                <div>
                                    <ButtonLabel htmlFor="capture">
                                        <CameraSVG />
                                        Take photo
                                    </ButtonLabel>
                                    <HiddenInput
                                        type="file"
                                        name="captureImage"
                                        accept="image/*"
                                        capture="camera"
                                        id="capture"
                                        onChange={async (e) => {
                                            if (e.target.files?.length === 0) {
                                                return;
                                            }

                                            setIsUploading(true);
                                            const file: File =
                                                e.target.files![0];
                                            await uploadFile(profile.id, file);
                                            setIsUploading(false);
                                        }}
                                    />
                                </div>
                            </ProfileActions>
                        </ImageOptionsWrapper>
                        {errors.image && <Error>{errors.image}</Error>}

                        <Field name="displayName">
                            {({ field, meta }: FieldProps) => (
                                <Input
                                    label="Display name"
                                    type="text"
                                    placeholder="Enter your display name"
                                    error={
                                        (submitCount > 0 && meta.error) || null
                                    }
                                    {...field}
                                />
                            )}
                        </Field>

                        <Field name="slug">
                            {({ field, meta }: FieldProps) => (
                                <Input
                                    label="Profile handle"
                                    type="text"
                                    hint={`Your unique profile handle will be used when sharing your profile. Changing this will break links you have already shared. Your share link: https://flock.fitness/${field.value}`}
                                    placeholder="Enter your profile handle"
                                    error={
                                        (submitCount > 0 && meta.error) || null
                                    }
                                    {...field}
                                />
                            )}
                        </Field>

                        <Field name="instagram">
                            {({ field, meta, form }: FieldProps) => (
                                <Input
                                    {...field}
                                    hint="Enter your instagram to add a link to your profile"
                                    type="text"
                                    placeholder="@"
                                    onChange={(changeEvent) => {
                                        const input = changeEvent.target.value;
                                        if (input === '') {
                                            form.setFieldValue('instagram', '');
                                        } else if (input === '@') {
                                            form.setFieldValue('instagram', '');
                                        } else if (input.startsWith('@')) {
                                            form.setFieldValue(
                                                'instagram',
                                                input
                                            );
                                        } else {
                                            form.setFieldValue(
                                                'instagram',
                                                `@${input}`
                                            );
                                        }
                                    }}
                                    error={
                                        (submitCount > 0 && meta.error) || null
                                    }
                                />
                            )}
                        </Field>

                        <Field name="bio">
                            {({ field, meta }: FieldProps) => (
                                <Textarea
                                    label="Bio"
                                    placeholder="Enter your bio"
                                    charLimit={200}
                                    error={
                                        (submitCount > 0 && meta.error) || null
                                    }
                                    {...field}
                                />
                            )}
                        </Field>

                        {error && <Error>{error}</Error>}

                        <FormActions>
                            <PrimaryButton
                                type="submit"
                                isSubmitting={isSubmitting}
                            >
                                Save
                            </PrimaryButton>
                        </FormActions>
                    </Form>
                );
            }}
        </Formik>
    );
};
