import React, { FunctionComponent } from 'react';
import AuthLayout from 'src/components/layouts/AuthLayout';
import { Constrain } from 'src/components/ui/Constrain';
import styled from 'src/styles/styled-components';

const Wrapper = styled.div`
    h1 {
        font-size: ${({ theme }) => theme.font.size.level2.small};
        font-weight: bold;
    }
`;

export const Privacy: FunctionComponent = () => {
    return (
        <AuthLayout title="Privacy statement | FLOCK.fitness">
            <Wrapper>
                <Constrain narrow>
                    <h1>Privacy</h1>

                    <p>
                        The Company known as Flock App Ltd (company number
                        13072668) (“We”, “Us”, “Our”) take privacy, and the
                        security of personal data, very seriously, and We are
                        committed to ensuring that We safeguard the privacy and
                        personal data of Our Website visitors and those with
                        whom We communicate through that Website, whether by
                        email or electronically, at all times and in the best
                        way possible.
                    </p>
                    <p>
                        This privacy policy contains important information for
                        You. It explains:
                    </p>
                    <ul>
                        <li>who We are;</li>
                        <li>Our Website;</li>
                        <li>what personal information We collect about You;</li>
                        <li>
                            how, when and why We collect, store, use and share
                            Your personal data;
                        </li>
                        <li>how We keep Your personal data secure;</li>
                        <li>for how long We keep Your personal data;</li>
                        <li>Your rights in relation to Your personal data;</li>
                        <li>issues relating to marketing; and</li>
                        <li>
                            how to contact Us or the relevant supervisory
                            authorities should You have a complaint.
                        </li>
                    </ul>
                    <p>
                        Flock App Ltd (company number 13072668) is a company
                        registered in England and Wales and Our registered
                        office is at Flat 62 Decorum Apartments, 3 Wenlock Road,
                        London, England, N1 7FA. We own and operate the Website
                        You can find at http://flock.fitness (the “Website”).
                        Our principal contact for data protection purposes is
                        James Dowell who may be contacted at
                        james@flock.fitness.
                    </p>
                    <p>
                        When We collect, use and process personal data We are
                        subject to the provisions of the United Kingdom General
                        Data Protection Regulation (UK GDPR), and the Data
                        Protection Act 2018, and We are responsible as what is
                        described as a ‘controller’ of that personal information
                        for the purposes of those laws. In other words, We are
                        primarily responsible for that data and are the ‘natural
                        or legal person, public authority, agency or other body
                        which, alone or jointly with others, determines the
                        purposes and means of the processing of personal data’.{' '}
                    </p>
                    <p>
                        If You have any questions about the use to which We put
                        Your data, please contact our Data Protection Officer
                        whose details are set out in paragraph 1.3 above.{' '}
                    </p>
                    <p>
                        This policy relates to Your use of Our Website and
                        electronic communications. Please note that Our Website
                        may link to other third-party websites that may also
                        gather information about You. Third-party websites will
                        operate in accordance with their own separate privacy
                        policies, and We have no control over any personal data
                        that they may acquire, store and use. For privacy
                        information relating to these other third-party
                        websites, You should consult their privacy policies as
                        appropriate.{' '}
                    </p>
                    <p>
                        We are committed to preserving the privacy of Your data
                        so that We can:{' '}
                    </p>
                    <ul>
                        <li>deliver service of a high quality to clients;</li>
                        <li>
                            at all times comply with the law and the various
                            regulations that We are subject to;
                        </li>
                        <li>
                            meet the expectations of clients, employees and
                            third parties; and{' '}
                        </li>
                        <li>protect Our reputation.</li>
                    </ul>
                    <p>
                        In this policy, please note the use of the following
                        terms:
                    </p>
                    <ul>
                        <li>
                            personal data has the meaning given to it by the UK
                            GDPR and means any information relating to an
                            identified or identifiable individual (known as a
                            data subject);
                        </li>
                        <li>
                            processing means any operation or actions performed
                            on personal data; for example, collection,
                            recording, organisation, structuring, storing,
                            altering, deleting or otherwise using personal data.
                        </li>
                        <li>
                            We, Us and Our refers Flock App Ltd and its
                            directors;
                        </li>
                        <li>
                            You and Your refers to the person who is accessing
                            Our Website and whose data is processed;
                        </li>
                    </ul>

                    <h2>YOUR PERSONAL DATA</h2>
                    <p>
                        Personal data is collected about You whenever You access
                        Our Website, register with Us, contact Us, send Us
                        feedback, purchase services via Our Website, post
                        material to Our Website, complete forms on Our Website,
                        take part in customer surveys, participate in
                        competitions via Our Website, submit reviews to or via
                        Our Website.
                    </p>
                    <p>
                        Personal data is collected either directly (for example
                        when You register with Us, contact Us, purchase products
                        or services from Us, complete forms or submit reviews on
                        or via Our Website) or indirectly (for example where You
                        are browsing Our Website through the use of ‘Cookies’).
                    </p>
                    <p>
                        The personal data We receive, and process is dependent
                        on how You access Our Website, what You do while You are
                        accessing it, and what You perceive the end result of
                        accessing Our Website will be.
                    </p>
                    <p>
                        In general, the sorts of data that We can acquire,
                        depending on the circumstances, include:
                    </p>
                    <ul>
                        <li>
                            Your name, address, email, telephone number and
                            other contact details;
                        </li>
                        <li>Your date of birth;</li>
                        <li>Your bank account or other financial details;</li>
                        <li>
                            details of any feedback You give Us, and this may be
                            by phone, email, post or via social media;
                        </li>
                        <li>
                            information about the services We provide to You;
                        </li>
                        <li>
                            Your account details, such as username, login
                            details;
                        </li>
                        <li>
                            Your IP address, the browser You use, Your operating
                            system;
                        </li>
                        <li>
                            the pages of Our Website, or other resources on that
                            Website, that You have accessed and when You
                            accessed them; and
                        </li>
                        <li>
                            details of any documents or other resources that You
                            have downloaded from Our Website.
                        </li>
                    </ul>
                    <p>In general terms, We may use this personal data to:</p>
                    <ul>
                        <li>create and manage Your account with Us;</li>
                        <li>verify Your identity;</li>
                        <li>provide goods and services to You;</li>
                        <li>
                            customise Our Website and its content to Your
                            particular preferences;
                        </li>
                        <li>
                            notify You of any changes to Our Website or to Our
                            services that may affect You;
                        </li>
                        <li>improve Our services;</li>
                        <li>receive Your reviews and respond to them.</li>
                    </ul>
                    <p>
                        Please note that it is important that the personal data
                        We hold about You is accurate and current. Please keep
                        Us informed if Your personal details change during Your
                        relationship with Us.
                    </p>
                    <p>
                        Please note that Our Website is not intended for use by
                        children under the age of 18 years of age, and We do not
                        knowingly collect or use personal information relating
                        to children.
                    </p>

                    <h2>THE PURPOSES FOR WHICH YOUR INFORMATION IS USED</h2>
                    <p>
                        Data protection law requires that We only use Your
                        personal data for the purposes for which it was
                        acquired, or where We have a proper reason for using it.
                        Those reasons may include the following:
                    </p>
                    <ul>
                        <li>
                            Where You have given consent to the use of Your
                            personal data for one or more specific purposes.
                        </li>
                        <li>
                            Where the use is necessary for the performance of a
                            contract to which You are party, or in order to take
                            steps at Your request prior to entering into a
                            contract.
                        </li>
                        <li>
                            Where the use is necessary for compliance with a
                            legal obligation that We are subject to.
                        </li>
                        <li>
                            Where the use is necessary in order to protect Your
                            vital interests or those of another person.
                        </li>
                        <li>
                            Where the use is necessary for the performance of a
                            task carried out in the public interest, or in the
                            exercise of official authority vested in Us.
                        </li>
                        <li>
                            Where the use is necessary for the purposes of Our
                            legitimate interests or those of a third party,
                            except where those interests are overridden by Your
                            interests or fundamental rights and freedoms which
                            require protection of personal data, in particular
                            where You or the relevant person is a child.
                        </li>
                    </ul>

                    <p>
                        The reasons set out above represent the general position
                        as to the purposes for which data may be used. The
                        specific position in relation to Your personal data,
                        however, is that We may use it for the following
                        purposes:{' '}
                    </p>
                    <ul>
                        <li>
                            In order to supply goods and/or services to You
                            through or via Our Website. This processing relies
                            on the performance of a contract between Us and the
                            steps needed to deliver those contractual services.
                        </li>
                        <li>
                            To prevent or detect fraud, either against You or
                            against any other person involved in any matter in
                            which You are involved. This will help to prevent
                            any damage either to You, a third party, or to Us.
                            This processing relies on factors related to Our
                            legitimate interests in processing the data.
                        </li>
                        <li>
                            To preserve the confidentiality of
                            commercially-sensitive information, and for Our
                            legitimate interests or those of a third party in
                            relation to the protection of Our, or another’s,
                            intellectual property and other
                            commercially-valuable information. This processing
                            relies on Your consent where this has been given, or
                            on factors related to Our legitimate interests in
                            processing the data (in that We are seeking to
                            monitor and improve Our Website and/or the
                            services/products We provide).
                        </li>
                        <li>
                            In connection with credit control and credit
                            reference checks in relation to the services We
                            perform or the products We supply. This processing
                            relies on Your consent where this has been given, on
                            factors related to Our legitimate interests in
                            processing the data (in that We are seeking to
                            provide services as part of Our business, or the
                            performance of a contract between Us, and the steps
                            needed to deliver those contractual services).
                        </li>
                        <li>
                            To analyse the use made of Our Website. Here We may
                            make use of Your IP address, where You are based,
                            the type and version of the browser You use, details
                            of Your operating system, how You came to Our
                            Website (for example whether You were referred from
                            another Website or from a search engine), how long
                            You remained on Our Website, the number of pages on
                            Our Website that You viewed, how You moved around
                            Our Website, the links that You followed, and
                            whether any of those links were used to leave Our
                            Website. This processing relies on Your consent
                            where this has been given, or on factors related to
                            Our legitimate interests in processing the data (in
                            that We are seeking to monitor and improve Our
                            Website and/or the services/products We provide).
                        </li>
                        <li>
                            To improve the operation of Our Website and provide
                            those services which You have requested Us to
                            provide. This may include taking such security
                            measures as are appropriate, backing up the data We
                            hold, and contacting You. This processing relies on
                            Your consent where this has been given, or on
                            factors related to Our legitimate interests in
                            processing the data (in that We are seeking to
                            provide services as part of Our business, or the
                            performance of a contract between Us, and the steps
                            needed to deliver those contractual services).
                        </li>
                        <li>
                            In relation to information which You wish to include
                            in, or post on, Our Website (for example by
                            submitting a review of Our services/products,
                            supplying a blog post or the publishing of other
                            information). This processing relies on Your consent
                            where this has been given, or on factors related to
                            Our legitimate interests in processing the data (in
                            that We are seeking to provide services as part of
                            Our business, or the performance of a contract
                            between Us, and the steps needed to deliver those
                            contractual services).
                        </li>
                        <li>
                            For dealing with an enquiry submitted by You to Us
                            in connection with Our goods and/or services, or in
                            relation to the supply of newsletters, email
                            notifications, product data or general updates. This
                            processing relies on Your consent where this has
                            been given, or on factors related to Our legitimate
                            interests in processing the data (in that We are
                            seeking to provide services as part of Our business,
                            or the performance of a contract between Us, and the
                            steps needed to deliver those contractual services).
                        </li>
                        <li>
                            Where it is necessary for Us to do so in order to
                            establish, exercise or defend a legal claim, whether
                            in court proceedings or in an administrative or
                            out-of-court procedure. This processing relies on
                            factors related to Our legitimate interests in
                            processing the data.
                        </li>
                        <li>
                            In connection with the compliance by Us with a legal
                            obligation that We are subject to, or in order to
                            protect Your, or Our, vital interests, or the vital
                            interests of another natural person.
                        </li>
                    </ul>
                    <p>
                        The purposes set out above will not apply to what is
                        termed ‘special category personal information’. This
                        includes personal information revealing racial or ethnic
                        origin, political opinions, religious beliefs,
                        philosophical beliefs, or trade union membership,
                        genetic and biometric data, and data concerning health,
                        sex life or sexual orientation. We will only ever
                        process information of that nature with Your explicit
                        consent.
                    </p>

                    <h2>CONTACTING YOU</h2>

                    <p>
                        In addition to the general matters, We may also need to
                        send You updates concerning Our services, and about
                        relevant developments in relation to You, Our services,
                        or other related matters which might concern You, or be
                        of interest to You. This may be by post, telephone,
                        email or text, and may include information about the
                        services We offer and information relating to changes in
                        those services.
                    </p>
                    <p>
                        We regard ourselves as having a legitimate interest in
                        processing Your personal data for these purposes, and We
                        take the view that We do not require Your consent in
                        order to do so. From time to time We undertake what are
                        known as ‘legitimate interest assessments’ in order to
                        balance Our interests in contacting You with Your
                        interests in relation to Your data. Where We believe
                        that consent is required, We will contact You
                        specifically for this, and will do so in a clear and
                        transparent manner.
                    </p>
                    <p>
                        Where You have agreed to Us doing so, We may also send
                        You information about third party services in which You
                        have expressed an interest, or which are relevant to any
                        services that We have supplied.
                    </p>
                    <p>
                        Be assured that We treat Your personal data with the
                        utmost respect and will never share it with others for
                        marketing or promotional purposes. You have, at all
                        times, the right to request that We do not contact You
                        for any purpose other than providing Our services. We
                        may, however, require that You confirm Your marketing
                        preferences from time to time so that We can be sure
                        that Your views remain the same, especially in relation
                        to issues such as legal and regulatory updates.
                    </p>

                    <h2>SHARING YOUR DATA WITH OTHERS</h2>

                    <p>
                        Notwithstanding the fact that We will not share Your
                        personal data for marketing purposes, it may be
                        necessary for Us to share Your personal data with others
                        in order to perform Our services for You, to comply with
                        Our contractual obligations to You, to comply with Our
                        legal or regulatory obligations to You, or to comply
                        with any contractual, legal or regulatory obligations
                        that We are subject to. These may include:{' '}
                    </p>
                    <ul>
                        <li>
                            When sharing Your personal data, We will ensure at
                            all times that those with whom it is shared process
                            it in an appropriate manner and take all necessary
                            measures in order to protect it. In doing so We
                            impose contractual obligations on all providers of
                            services to ensure that Your personal data is kept
                            secure. We will only ever allow others to handle
                            Your personal data if We are satisfied that the
                            measures which they take to protect that personal
                            data are satisfactory.
                        </li>
                        <li>
                            Please be aware that, from time to time, We may be
                            required to disclose Your personal data to, and
                            exchange information about You or relating to You
                            with, government, law enforcement and regulatory
                            bodies and agencies in order to comply with Our own
                            legal and regulatory obligations.
                        </li>
                        <li>
                            We may also need to share some personal information
                            with other parties, such as potential buyers of some
                            or all of Our business or during a restructuring.
                            Usually, information will be anonymised, but this
                            may not always be possible. The recipient of the
                            information will be bound by confidentiality
                            obligations.
                        </li>
                        <li>
                            From time to time it may be necessary for Us to
                            share data for statistical purposes. We will always
                            take steps to try to ensure that information shared
                            is anonymised but, where this is not possible, We
                            will require that the recipient of the information
                            keeps it confidential at all times.
                        </li>
                        <li>
                            Other than as set out above, We will not share Your
                            personal data with any other third party.
                        </li>
                    </ul>

                    <h2>HOW YOUR PERSONAL DATA IS KEPT</h2>
                    <p>Your personal data will be kept secure at all times.</p>
                    <p>
                        Some of Your data may be held on our database as well as
                        with Stripe. Where this takes place outside the UK then
                        the provisions set out in Paragraph 8 below will apply.
                    </p>
                    <p>
                        We operate various security measures in order to prevent
                        loss of, or unauthorised access to, Your personal data.
                        In order to ensure this, We restrict access to Your
                        personal data to those with a genuine business need to
                        access it, and We have procedures in place to deal with
                        any suspected data security breach. We will notify You
                        and any applicable regulator of a suspected data
                        security breach where We are legally required to do so.
                    </p>
                    <p>
                        Personal data that is processed by Us will not be
                        retained for any longer than is necessary for that
                        processing, or for purposes relating to or arising from
                        that processing.
                    </p>
                    <p>
                        Where Your personal data is retained after We have
                        finished providing Our services to You, or where the
                        contract with You has ended in any other way, this will
                        generally be for one of the following reasons:
                    </p>
                    <ul>
                        <li>
                            so that We can respond to any questions, complaints
                            or claims made by You or on Your behalf;
                        </li>
                        <li>
                            so that We are able to demonstrate that Your matter
                            was dealt with adequately and that You were treated
                            fairly; or
                        </li>
                        <li>
                            in order to comply with legal and regulatory
                            requirements.
                        </li>
                    </ul>
                    <p>
                        In general, We will retain Your data for only so long as
                        is necessary for the various objectives and purposes
                        contained in this policy. Please note, however, that
                        different periods for keeping Your personal data will
                        apply depending on the type of data being retained and
                        the purpose of its retention.
                    </p>
                    <p>We will also retain Your personal data as follows:</p>
                    <ul>
                        <li>
                            So that We can inform You of updates concerning Our
                            services, and about relevant developments in
                            relation to You, Our services or other related
                            matters which might concern You, or be of interest
                            to You,
                        </li>
                        <li>
                            for such time as is necessary for compliance with a
                            legal obligation that We are subject to, or in order
                            to protect Your vital interests or the vital
                            interests of another natural person.
                        </li>
                        <li>
                            We will delete and/or anonymise any personal data
                            which it is no longer necessary for Us to retain.
                        </li>
                    </ul>

                    <h2>TRANSFERRING YOUR DATA OUTSIDE THE UK</h2>
                    <p>
                        From time to time, it may be necessary for Us to
                        transfer Your personal data outside the UK where, for
                        example, those with whom We need to make contact on Your
                        behalf have offices outside the UK, where electronic
                        services and resources are based outside the UK, or
                        where there is an international element to Your matter.
                        Where this is the case, special rules apply to the
                        protection of Your data.
                    </p>
                    <p>
                        In particular, the web hosting services used by Us are
                        based in the EU.
                    </p>
                    <p>
                        For further information please contact Our Data Manager.
                    </p>

                    <h2>YOUR RIGHTS IN RELATION TO YOUR DATA</h2>

                    <p>
                        Data protection legislation gives You, the data subject,
                        various rights in relation to Your personal data that We
                        hold and process. These rights are exercisable without
                        charge, and We are subject to specific time limits in
                        terms of how quickly We must respond to You. Those
                        rights are, in the main, set out in Articles 12–23 of
                        the UK GDPR. They are as follows:
                    </p>
                    <ul>
                        <li>
                            Right of access—the right to obtain from Us
                            confirmation as to whether or not personal data
                            concerning You is being processed, and, where that
                            is the case, access to that personal data and
                            various other information, including the purpose for
                            the processing, with whom the data is shared, how
                            long the data will be retained, and the existence of
                            various other rights (see below).
                        </li>
                        <li>
                            Right to rectification—the right to obtain from Us,
                            without undue delay, the putting right of inaccurate
                            personal data concerning You.
                        </li>
                        <li>
                            Right to erasure—sometimes referred to as the ‘right
                            to be forgotten’, this is the right for You to
                            request that, in certain circumstances, We delete
                            data relating to You.
                        </li>
                        <li>
                            Right to restrict processing—the right to request
                            that, in certain circumstances, We restrict the
                            processing of Your data.
                        </li>
                        <li>
                            Right to data portability—the right, in certain
                            circumstances, to receive that personal data which
                            You have provided to Us in a structured, commonly
                            used and machine-readable format, and the right to
                            have that personal data transmitted to another
                            controller.
                        </li>
                        <li>
                            Right to object—the right, in certain circumstances,
                            to object to personal data being processed by Us
                            where it is in relation to direct marketing, or in
                            relation to processing supported by the argument of
                            legitimate interest.
                        </li>
                        <li>
                            Right not to be subject to automated decision
                            making—the right not to be subject to a decision
                            based solely on automated processing, including
                            profiling, which produces legal effects concerning
                            You or similarly significantly affects You.
                        </li>
                    </ul>

                    <p>
                        Full details of these rights can be found in the UK GDPR
                        or by reference to guidance produced by the Information
                        Commissioner’s Office.
                    </p>
                    <p>
                        In the event that You wish to exercise any of these
                        rights You may do so by:
                    </p>
                    <ul>
                        <li>
                            contacting Us using any medium You wish, including
                            in writing, by telephone, by text, electronically,
                            or using such social media as We employ for
                            communication purposes;
                        </li>
                        <li>
                            by completing a form which We can supply to You for
                            this purpose; or
                        </li>
                        <li>
                            through a third-party who You have authorised for
                            this purpose.
                        </li>
                    </ul>

                    <h2>ABOUT OUR USE OF COOKIES & SIMILAR TECHNOLOGIES</h2>

                    <strong>WHAT A COOKIE IS</strong>
                    <p>
                        A cookie is a small file that is sent by a web server
                        (where We host Our Website) to a web browser (from where
                        You view Our Website) and which is then stored by the
                        browser. The cookie contains an identifier which is
                        stored in Your browser and then sent back to Our server
                        each time Your browser accesses Our Website. These
                        cookies may either be ‘persistent cookies’ (in which
                        case they will continue to be held by Your browser until
                        they are deleted, or until a specified event/date) or
                        they will be ‘session cookies’ which expire when You
                        close Your browser.
                    </p>
                    <p>
                        Cookies are standard practice to make Your experience
                        better when using Our Website.
                    </p>

                    <strong>USE OF COOKIES</strong>

                    <p>
                        We (and our third-party service providers) use cookies
                        and similar technologies to collect information about
                        and relevant to Your use of the Website.
                    </p>
                    <p>
                        Usually, cookies do not hold any data by which You can
                        be identified, although if We do hold personal data
                        about You (for example, because You have subscribed to a
                        service that We offer) the cookie may be linked to that
                        data.
                    </p>

                    <p>
                        We use Google Analytics. This uses cookies to gather
                        data about how users use Our Website. This data is used
                        to create reports about that use. Further information
                        about Google’s use of data may be obtained from Google’s
                        Website.{' '}
                    </p>
                    <p>
                        If You wish to do so then, usually, You can prevent
                        cookies from being downloaded to Your browser and can
                        delete those that have already been downloaded by
                        changing the settings on your browser. How this may be
                        achieved varies between different browsers. Consult the
                        Website of Your browser provider for more details. You
                        can enable cookies again at any time.
                    </p>
                    <p>
                        However, You should be aware that if You block or delete
                        cookies this may have a detrimental impact on Your
                        ability to access or navigate Our Website, and the
                        services that We provide. It may mean that not all of
                        the facilities on Our Website will be accessible by You
                        or that the Website will not work properly or at all.
                    </p>
                    <p>
                        For further information about the cookies We use please
                        contact James Dowell at james@flock.fitness.
                    </p>

                    <h2>TYPES OF COOKIES</h2>

                    <p>
                        <strong>Strictly necessary cookies:</strong> These
                        cookies are essential for the operation of Our Website
                        and will enable You to move around the Website and use
                        its features, as well as keep the site secure. Without
                        these cookies we will be unable to provide certain
                        services (such as remembering Your login details or the
                        items You placed in your basket and e-billing services)
                        you have asked for.{' '}
                    </p>
                    <p>
                        <strong>Analytics cookies:</strong> These cookies allow
                        Us to recognise and count the number of visitors and
                        generally to see how You are using our Website, for
                        instance the searches you perform, error messages you
                        get and pages that you visit most frequently.
                        Information these cookies collect can be used to improve
                        how the Website works and therefore Your enjoyment of
                        it.
                    </p>
                    <p>
                        <strong>Customization/Functionality cookies:</strong>{' '}
                        These cookies remember You when you return to Our
                        Website and collect specific choices that You make, such
                        as greeting you by user name, and remembering your
                        language, region and other preferences specifically
                        chosen by You.
                    </p>
                    <p>
                        <strong>Targeting/Advertising cookies:</strong> These
                        cookies record your visit to the Website, the pages you
                        have visited and the links you have followed. We use
                        this information to make our Website and the advertising
                        displayed on it more relevant to your interests. We may
                        also share this information with third parties for this
                        purpose.
                    </p>
                    {/* <p>
                        <strong>Social media cookies:</strong> These cookies
                        allow you to share your activity on the Website on
                        social media such as Facebook and Twitter. These cookies
                        are not within our control. Please refer to the privacy
                        policies of the social networks in question for
                        information regarding how their cookies work.
                    </p> */}

                    <h2>KEEPING YOUR DATA SECURE</h2>
                    <p>
                        In order to ensure that Your personal data is kept
                        secure, and to prevent there being any breach of
                        confidentiality, We have put in place security measures
                        which are intended to prevent Your personal data from
                        being accidentally lost or used, or accessed unlawfully.
                        Access to Your personal data is restricted to those with
                        a need to access it, and regard will be had to the need
                        for confidentiality when that personal data is
                        processed.
                    </p>
                    <p>
                        In the event that there is a suspected data security
                        breach You will be notified. Where relevant We will also
                        inform the appropriate regulator (including the
                        Information Commissioner’s Office) of a suspected data
                        security breach where We are legally required to do so.
                    </p>

                    <h2>MAKING A COMPLAINT</h2>
                    <p>
                        If You have any queries as to the acquisition, use,
                        storage or disposal of any personal data relating to You
                        please contact James Dowell at james@flock.fitness
                    </p>
                    <p>
                        We can be contacted at Flock App Ltd, Flat 62 Decorum
                        Apartments, 3 Wenlock Road, London, England, N1 7FA.
                    </p>
                    <p>
                        Despite Our best efforts, inevitably sometimes things do
                        go wrong. If You are unhappy with any aspect of the use
                        and/or protection of Your personal data, You have the
                        right to make a complaint to the Information
                        Commissioner’s Office, who may be contacted in writing
                        at Wycliffe House, Water Lane, Wilmslow, Cheshire, SK9
                        5AF; by telephone on 0303 123 1113; by fax on 01625
                        524510; or online at www.ico.org.uk.
                    </p>
                    <p>
                        <strong>THIS POLICY</strong>
                    </p>
                    <p>
                        This privacy policy was published on 16 April 2021 and
                        last updated on 16 April 2021.
                    </p>
                    <p>
                        The terms and provisions of this privacy policy may be
                        changed, updated and amended from time to time. If We do
                        so during the time when We are providing You with
                        services We will inform You of those changes.
                    </p>
                </Constrain>
            </Wrapper>
        </AuthLayout>
    );
};
