import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'src/contexts/user';

const Logout = () => {
    const { push } = useHistory();
    const { logout } = useAuth();

    useEffect(() => {
        logout();
        push('/');
    }, [push, logout]);

    return <div />;
};

export default Logout;
