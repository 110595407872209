import React from 'react';
import { useHistory } from 'react-router';
import { Constrain } from 'src/components/ui/Constrain';
import { LoginForm } from '../components/LoginForm';

const Login = () => {
    const { push } = useHistory();
    return (
        <Constrain>
            <h1>Login</h1>
            <LoginForm onSuccess={() => push('/')} />
        </Constrain>
    );
};

export default Login;
