import styled from 'src/styles/styled-components';
import React, { FunctionComponent } from 'react';
import { Loading } from '../Loading';
import { media } from 'src/utils/mediaQueries';
import theme from 'src/styles/theme';
import { darken } from 'polished';

type StyledButtonProps = {
    backgroundColor?: keyof typeof theme.colors;
    borderColor?: keyof typeof theme.colors;
    color?: keyof typeof theme.colors;
};

interface ButtonProps
    extends React.DetailedHTMLProps<
        React.ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
    > {
    isSubmitting?: boolean;
    loadingColor?: 'white';
    borderColor?: keyof typeof theme.colors;
    backgroundColor?: keyof typeof theme.colors;
    color?: keyof typeof theme.colors;
}

const StyledButton = styled.button<StyledButtonProps>`
    appearance: none;
    background-color: ${({ backgroundColor, theme }) =>
        backgroundColor ? theme.colors[backgroundColor] : 'white'};
    border: solid 1px;
    border-color: ${({ theme, borderColor }) =>
        borderColor
            ? theme.colors[borderColor]
            : darken(0.17, theme.colors.black)};
    border-radius: 3px;
    padding: 0.5rem 1.8rem;
    text-align: center;
    font-size: ${({ theme }) => theme.font.size.level5.small};
    color: ${({ color, theme }) => theme.colors[color || 'black']};
    font-weight: 500;

    ${media.m`
        font-size: ${({ theme }) => theme.font.size.level5.large};
    `}

    svg {
        width: 30px;
        height: 13px;
    }

    &:hover {
        cursor: pointer;
    }

    &:focus {
        outline: none;
        box-shadow: 0px 0px 3px 1px ${({ theme }) => theme.colors.turquoise};
    }
`;

export const TertiaryButton: FunctionComponent<
    ButtonProps & StyledButtonProps
> = ({ children, isSubmitting, loadingColor, ref, ...props }) => {
    return (
        <StyledButton {...props} disabled={isSubmitting}>
            {(isSubmitting && <Loading color={loadingColor} />) || children}
        </StyledButton>
    );
};
