import styled from 'src/styles/styled-components';
import { media } from 'src/utils/mediaQueries';

export const Heading = styled.h1`
    font-weight: normal;
    font-size: ${({ theme }) => theme.font.size.level2.small};
    text-align: center;

    ${media.m`
        font-size: ${({ theme }) => theme.font.size.level1.small};
    `}

    span {
        position: relative;
        white-space: nowrap;

        &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 3px;
            background-color: ${({ theme }) => theme.colors.turquoise};
            z-index: -1;
        }
    }
`;
