import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import AuthLayout from 'src/components/layouts/AuthLayout';
import { Constrain } from 'src/components/ui/Constrain';
import styled from 'src/styles/styled-components';

const Wrapper = styled.div`
    h1 {
        font-size: ${({ theme }) => theme.font.size.level2.small};
        font-weight: bold;
    }

    h3 {
        font-size: ${({ theme }) => theme.font.size.level3.small};
        font-weight: bold;
    }
`;

export const Terms: FunctionComponent = () => {
    return (
        <AuthLayout title="Terms and conditions | FLOCK.fitness">
            <Wrapper>
                <Constrain narrow>
                    <h1>Terms and conditions of use</h1>

                    <h3>WHAT IS IN THESE TERMS?</h3>
                    <p>
                        These Terms are the rules for using Our website
                        application https://FLOCK.fitness (“the App”). It will
                        form an agreement between You, the user of this App
                        (including any person using this App on Your behalf)
                        (“You/Your”) and Flock App Limited trading as
                        FLOCK.fitness and its affiliates, (“Flock/Us/Our/We”)
                        the owner(s) of the App. Any documents, policies and
                        terms referred to below will be incorporated into these
                        Terms by reference.{' '}
                    </p>

                    <h3>YOUR USE OF THE APP</h3>

                    <p>
                        By subscribing to and using Our App, You confirm Your
                        agreement to comply with and be bound by these Terms of
                        Use, policies and guidelines incorporated in it. If You
                        do not agree to all of these Terms, please do not use
                        this App.
                    </p>
                    <p>
                        We suggest that You print/save a copy of these Terms for
                        future reference, but please note that the Terms may be
                        amended from time to time, and You should always ensure
                        You are referring to the correct and most updated
                        version as published on Our App.
                    </p>
                    <p>
                        These Terms apply to Your use of this App at any time
                        and for whatever purpose. It will not change the terms
                        of any other agreement we may have in place with You.
                    </p>
                    <p>
                        You represent and warrant that You are over the age of
                        18 and are lawfully able to accept these Terms. If you
                        live in a country where 18 is not old enough to legally
                        bind You by a contract like this, You must be old enough
                        to be bound by these Terms. If You are using the App on
                        behalf of any entity, You further represent and warrant
                        that You are authorised to accept these Terms on such
                        entity’s behalf, and that such entity agrees to
                        indemnify Us for violations of these Terms.
                    </p>
                    <p>
                        Any reference to a Consumer will be to an individual
                        acting wholly or mainly outside of their trade,
                        business, craft, or profession.
                    </p>
                    <p>
                        <strong>"Content"</strong> means all works and materials
                        (including without limitation text, graphics, images,
                        audio material, video material, audio-visual material,
                        scripts, software and files) that You submit to Us or
                        Our App for storage or publication on, processing by, or
                        transmission via Our App.
                    </p>
                    <p>
                        <strong>“Creator”</strong> means the Creator of content
                        that opens a Creator account and sells its content to
                        Subscribers by entering into an agreement with them.
                    </p>
                    <p>
                        <strong>“Creator Content”</strong> means all Content
                        that the Creator uploads to Our App for storage or
                        publication on, processing by, or transmission via Our
                        App to provide the Services to You;
                    </p>

                    <h3>ABOUT US</h3>
                    <p>
                        <strong>Company details.</strong> Flock App Limited
                        trading as FLOCK.fitness (company number 13072668) is a
                        company registered in England and Wales and Our
                        registered office is at Flat 62, Decorum Appartments, 3
                        Wenlock Road, Londan, England N1 7FA. Our main trading
                        address is at 160 Carrara Tower, 250 City Road, London,
                        England, EC1V 2AB. We own and operate the website
                        Application which You can find at https://FLOCK.fitness.{' '}
                    </p>
                    <p>
                        <strong>Contacting Us.</strong> To contact Us, email Our
                        customer service team at support@flock.fitness if You
                        would like to give Us formal notice of any matter under
                        these Terms please refer to 13.2.
                    </p>

                    <h3>OTHER TERMS THAT MAY APPLY TO YOU</h3>

                    <p>
                        These Terms of Use also include Our Privacy and Cookie
                        Policy https://FLOCK.fitness/privacy, Creator Terms
                        &amp; Conditions, Subscriber Terms &amp; Conditions and
                        Referral Terms some of which may also apply to Your use
                        of the App (“Additional Terms”) and are incorporated
                        into these Terms by reference.
                    </p>

                    <h3>OUR CONTRACT WITH YOU</h3>

                    <p>
                        <strong>Entire agreement.</strong> We will rely on these
                        Terms and Your order as the agreement between Us as far
                        as is legally permissible. If You require any changes,
                        please make sure that You ask for them in writing to try
                        and help avoid misunderstandings of what we expect of
                        each other. If You are a business, these Terms together
                        with the Additional Terms above represent the entire
                        agreement between You and Us in relation to Your use of
                        the App. You acknowledge that You have not relied on any
                        statement, promise or representation, assurance or
                        warranty that is not set out in these Terms. Our
                        liability to You will also be capped to the value of the
                        Services purchased if You are a business customer.
                    </p>
                    <p>
                        <strong>Transfer of Contract.</strong> We may transfer
                        Our rights and obligations under these Terms to another
                        organisation. We will always let You know if this
                        happens and will make sure that Your rights under this
                        contract are not affected. You may not transfer Your
                        rights to another party without Our prior consent.
                    </p>
                    <p>
                        <strong>Contracting Age.</strong> In order to validly
                        contract with Us You must be 18 years or older. Please
                        do not use this App if You are not 18 years or older.
                    </p>
                    <p>
                        <strong>Location &amp; Language.</strong> We do not
                        represent that the content available on or through Our
                        App is appropriate for use in all locations outside the
                        UK and will be made only in the English language.
                    </p>
                    <p>
                        <strong>Amendments/Changes.</strong> We may change Our
                        Terms from time to time, to reflect any changes to what
                        we offer, Our customer’s needs and Our business
                        priorities. We will try to give You reasonable notice of
                        these changes and We will publish the updated Terms on
                        the App. You should check these Terms regularly and at
                        least every time You use the App to ensure that You are
                        happy with any changes. You will be deemed to have
                        accepted any changes to the Terms if You continue to
                        access or use the App. We may also update and change Our
                        App from time to time. We can change our third- party
                        payment providers with notice to You.
                    </p>
                    <p>
                        <strong>Contacting You.</strong> You consent to
                        receiving communications from Us by e-mail or on the App
                        and to the processing of Your Personal Data as set out
                        in our privacy policy from time to time as can be found
                        at https://FLOCK.fitness/privacy.
                    </p>
                    <p>
                        <strong>Intellectual Property.</strong> We own/license
                        all rights in and to the entirety of the App and its
                        contents, source code, functionality, features and
                        databases (excluding content which may be owned or
                        licensed to the Creators).
                    </p>
                    <p>
                        Once any data relating to Your use is anonymised
                        (meaning it no longer contains any information that can
                        be identified with You), it belongs to Us and We can use
                        it in any way without Your consent.
                    </p>

                    <h3>ACCESSING OUR APP &amp; ITS OPERATION</h3>

                    <p>
                        <strong>App content disclaimer.</strong> We are not
                        obliged in any way to review or moderate any content on
                        our App, however We may do so. Materials and information
                        published on Our App are not intended as advice and
                        should not be relied on as such. All materials and
                        information are for publicity and informational purposes
                        only. We disclaim all liability and responsibility
                        arising from any reliance placed on such information by
                        a business customer. Despite trying, We cannot guarantee
                        that the contents of the App will be free of errors,
                        trojans, viruses, bugs, worms or otherwise make any
                        representations in respect of its quality, accuracy, or
                        completeness of the content available on the App.
                    </p>
                    <p>
                        <strong>Security &amp; Your use of this App.</strong>{' '}
                        You are responsible for maintaining appropriate software
                        on Your computer or device to protect You from any
                        errors, bugs, worms, trojans or viruses. We don’t
                        guarantee that the App will work on Your device or
                        operating system.
                    </p>
                    <p>
                        <strong>Representations.</strong> You represent and
                        warrant that You will comply with all applicable laws
                        and regulations, including, without limitation, those
                        relating to the internet, data, electronic
                        communications, privacy, and the transmission of data
                        applicable to the United Kingdom at the time of use,
                        when using this App.
                    </p>
                    <p>
                        <strong>Availability.</strong> We will use reasonable
                        efforts to keep the system available and maintain full
                        system functionality at all times. We will not be liable
                        to You or any other person in respect of any loss or
                        damage arising from the unavailability of, or
                        interruption in the App service as Our services are not
                        essential.
                    </p>
                    <p>
                        <strong>Sign out.</strong> We remind You that
                        unauthorised transactions may result if You do not sign
                        out of the App after use and a third party gains access
                        to it, for which We will not be liable.
                    </p>
                    <p>
                        <strong>Reliability.</strong> The content on Our App is
                        provided for general and recreational information only.
                        It is not intended to amount to advice that You should
                        rely on. Although we make reasonable efforts to update
                        the information on Our App, we make no representations,
                        warranties or guarantees, whether express or implied,
                        that the content on Our App is accurate, complete, or up
                        to date.{' '}
                    </p>

                    <h4>Creating an Account.</h4>
                    <p>
                        You can register to create an account by completing and
                        submitting the account registration form on Our App and
                        clicking on the verification link in the email that the
                        App will send to You. Once verified, the App will send
                        You an Invite Code with additional information to finish
                        creating Your account. An account can only be
                        successfully completed if You receive an Invite Code.
                    </p>
                    <p>
                        As part of your account setup process, you may need to
                        provide certain information (specific details on the App
                        which may vary from time to time as needed) depending on
                        your user status as Subscriber or Creator.
                    </p>
                    <p>
                        We reserve the right to refuse any application for an
                        account in Our reasonable discretion;
                    </p>
                    <p>
                        <strong>Usernames &amp; Passwords.</strong> If You
                        register for an account on Our App, You will be asked to
                        choose a password which must comply with the content
                        rules set out in clause 6 below and your password and
                        account details should not try to mislead or impersonate
                        another business or person.
                    </p>

                    <p>
                        <strong>Password protection &amp; Reset.</strong> You
                        are responsible for access to the App using Your
                        Internet connection, even if this is by another person.
                        You remain responsible for the safety and security of
                        any password and log in details relating to the App and
                        any accounts you register for on it. To help protect
                        against unauthorised access to Your account We suggest
                        You store Your username(s) and password(s) safely and
                        securely. We recommend that You don’t disclose Your
                        username(s) and password(s) to anyone. We also recommend
                        that You sign out of Your account at the end of each
                        session. You may also wish to close Your browser window
                        when You have finished Your session, especially if You
                        share a computer with someone else or if You are using a
                        computer in a public place, You may reset Your password
                        if You have forgotten it, but will need access to the
                        original e-mail address provided on setup, failing which
                        we can require additional information from You to prove
                        Your identity.
                    </p>
                    <p>
                        <strong>Unauthorised access.</strong> If You suspect
                        that unauthorised access has been made to Your account,
                        You must notify Us immediately by contacting Us at Our
                        address in 2.2 above. We will investigate any suspicious
                        activity. We reserve the right, and You authorise Us, to
                        disable or block Your account and any user
                        identification code/username or password at any time
                        where it is suspected that unauthorised access has been
                        made to Your account or for any other reasonable reason
                        in Our discretion.
                    </p>
                    <p>
                        <strong>Restricted Access.</strong> We reserve the right
                        to restrict Your access to the App or part of it. Access
                        to restricted areas of the App may be subject to
                        registration and other conditions/subscription fees. If
                        we grant You permission to access a restricted area of
                        the App (such as that part only registered Subscribers
                        can see), we may withdraw that permission at any time
                        (including where You breach any of these Terms). We may,
                        for example, suspend access to the App during server
                        maintenance or when we update the App. You must not
                        circumvent or bypass, or attempt to circumvent or
                        bypass, any access restriction measures on the App by
                        for instance creating a new username/account.
                    </p>
                    <p>
                        <strong>
                            Suspension or Cancellation of Your Account by Us.
                        </strong>{' '}
                        We may (a)edit Your account details such as Your
                        username; (b)temporarily suspend Your account;
                        and/or(c)cancel Your account at any time in accordance
                        with clause 12 below.
                    </p>
                    <p>
                        <strong>Cancellation of Your Account by You.</strong>{' '}
                        You may cancel Your account on Our App at any time. You
                        will not be entitled to any refund if You cancel Your
                        account in accordance with this clause, unless you have
                        paid for Services upfront.
                    </p>
                    <p>
                        <strong>Content.</strong> We do not own the Creator’s
                        content and do not grant You any rights to it. Content
                        may be viewed by anyone that may recognise Your identity
                        and We will not be liable to You in any way if they do.{' '}
                    </p>

                    <h3>HOW YOU MAY USE THE APP</h3>

                    <p>When using the App You undertake the following:</p>

                    <p>
                        <strong>Use.</strong> You may not use this App or the
                        content for anything other than its intended use. Our
                        site is mainly directed at people living in the United
                        Kingdom but may also be used by anyone worldwide where
                        it is not against any laws of that country to do so. We
                        do not guarantee or represent that the content on Our
                        App is available or appropriate in other locations. We
                        may limit availability of Our App to any geographical
                        area.
                    </p>
                    <p>
                        <strong>Use by others.</strong> We have no control and
                        will not be responsible for how third
                        parties/Subscribers use Content uploaded by You. Even
                        after You delete Your Content, Subscribers may have
                        downloaded such content and may continue to use it.{' '}
                    </p>
                    <p>
                        <strong>
                            You use the App out of your free will and have no
                            obligation
                        </strong>{' '}
                        to follow any instructions, suggestions or comments from
                        any other account holder or Creator if You do not wish
                        to do so.
                    </p>
                    <p>
                        <strong>Referral Program.</strong> We do not in any way
                        whatsoever claim, promise or guarantee that You will be
                        able to earn any money from the Referral Program (see
                        the specific terms related to it).
                    </p>
                    <p>
                        <strong>Restrictions. You may not: </strong>
                    </p>
                    <ul>
                        <li>
                            Register for an account if your previous account was
                            terminated or suspended by Us because You violated
                            any terms with Us;
                        </li>
                        <li>
                            print, download, copy, adapt or re-transmit any or
                            all of the App or the content except for Your
                            personal, non-commercial use of the App. This means
                            You may print one copy for Your personal use. Our
                            status as the authors of the content must always be
                            acknowledged;{' '}
                        </li>
                        <li>
                            use any data mining, robots or similar data
                            gathering or extraction methods;{' '}
                        </li>
                        <li>
                            manipulate or display the App or its content by
                            using framing or similar navigational technology;{' '}
                        </li>
                        <li>
                            register, subscribe, unsubscribe, or attempt to
                            register, subscribe, or unsubscribe any party if You
                            are not expressly authorised by such party to do so
                            or try to impersonate a party by doing so;{' '}
                        </li>
                        <li>
                            use the App or its content other than for its
                            intended purpose or in violation of any applicable
                            laws including, without limitation, copyright and
                            trademark laws, the laws of privacy and publicity,
                            and applicable communications legislation and
                            regulations;
                        </li>
                        <li>
                            use the App to defame, abuse, harass, stalk,
                            threaten or otherwise violate the rights of others,
                            including without limitation others’ privacy rights
                            or rights of publicity, impersonate any person or
                            entity, falsely state or otherwise misrepresent Your
                            affiliation with any person or entity in connection
                            with the App; or express or imply that we endorse
                            any statement You make;
                        </li>
                        <li>
                            upload any data (where applicable and including
                            usernames and passwords) which is malicious, false,
                            misleading, fraudulent, defamatory or offensive in
                            content. Any breach of this term constitutes a
                            material offence and may result in the removal of
                            such information and/or refusal by Us to allow any
                            further dealings with You, and/or possible
                            prosecution with the relevant authorities. In such
                            event, You will have no claim or claims of
                            whatsoever nature or kind against Us arising out of
                            cancellation or prosecution as the case may be;
                        </li>
                        <li>
                            interfere with or disrupt the operation of the App
                            or the servers or networks used to make the App
                            available; or violate any requirements, procedures,
                            policies or regulations of such networks;
                        </li>
                        <li>
                            transmit or otherwise make available in connection
                            with the App any virus, worm, Trojan horse or other
                            computer code that is harmful or invasive or may or
                            is intended to damage the operation of, or to
                            monitor the use of, any hardware, software, or
                            equipment;
                        </li>
                        <li>
                            modify, adapt, translate, reverse engineer,
                            decompile or disassemble any portion of the App;
                        </li>
                        <li>
                            remove any copyright, trademark or other proprietary
                            rights notice from the App or materials originating
                            from the App;
                        </li>
                        <li>
                            frame or mirror any part of the App without Our
                            express prior written consent;
                        </li>
                        <li>
                            create a database by systematically downloading and
                            storing App content;
                        </li>
                        <li>
                            use any manual or automatic device in any way to
                            gather App content or reproduce or circumvent the
                            navigational structure or presentation of the App
                            without Our express prior written consent.
                            Notwithstanding the foregoing, we grant the
                            operators of public online search engines limited
                            permission to use search retrieval applications to
                            reproduce materials from the App for the sole
                            purpose of and solely to the extent necessary for
                            creating publicly available searchable indices of
                            such materials solely in connection with each
                            operator’s public online search service.
                        </li>
                    </ul>

                    <h3>HOW WE MAY USE YOUR INFORMATION</h3>

                    <p>
                        <strong>Use.</strong> We will process Your personal
                        information in accordance with Our Privacy Policy that
                        can be found at{' '}
                        <Link to="/privacy">https://flock.fitness/privacy</Link>{' '}
                        the Terms of which are incorporated into this Contract.
                        We are committed to secure storage of Your personal
                        information. Under no circumstances will We give or sell
                        any information relating to Our clients to third
                        parties, or organisations except to those of Our
                        registered service providers who are required to render
                        Services to You and who are bound to comply with Data
                        Privacy Legislation.{' '}
                    </p>

                    <p>
                        <strong>
                            Rights that You grant us by uploading Your Content
                            (Both as Subscriber and Creator):
                        </strong>
                    </p>
                    <ul>
                        <li>
                            You confirm that You own all intellectual property
                            rights (for example copyright and trademarks) in
                            Your Content or that You have acquired the rights to
                            be able to license it to Use or other users or
                            Subscribers. We may want to use parts of Your
                            Content in Our marketing but will always get Your
                            permission before doing so. You grant Us a license
                            to perform any act in respect of any intellectual
                            property rights related to all of Your Content which
                            will be an irrevocable, non-exclusive, royalty-free,
                            sublicensable, assignable worldwide perpetual
                            licence to use, reproduce, store, adapt, publish,
                            translate and distribute Your content in any
                            existing or future media, on and in relation to this
                            App and any successor App(s). This means that the
                            license will continue even after You terminate Your
                            account with Us. We intend to primarily use this to
                            market both the App and the fitness Creators’
                            profiles who upload content to the App;
                        </li>
                        <li>
                            You hereby waive all Your moral rights which You may
                            have in Your Content which does not affect Your
                            ownership of Your Content but gives us the right to
                            deal with it (as permitted by the license You
                            granted Us above).
                        </li>
                        <li>
                            You may edit Your Content using the editing
                            functionality made available on Our App, to the
                            extent that we provide for editing and it is
                            possible.
                        </li>
                        <li>
                            without prejudice to Our other rights under these
                            Terms and conditions, if You breach any provision of
                            these Terms and conditions in any way, or if we
                            reasonably suspect that You have breached these
                            Terms and conditions in any way, we may delete,
                            unpublish or edit any or all of Your Content and
                            cancel Your account or access as set out in these
                            Terms and clause 12.
                        </li>
                        <li>
                            Your content is not confidential and You agree to
                            Subscribers viewing and using it for their personal
                            purposes and in line with Our Terms &amp; Conditions
                            for Subscribers from time to time.
                        </li>
                    </ul>

                    <h3>RULES ABOUT YOUR CONTENT</h3>
                    <p>
                        You warrant (a promise that is legally enforceable) and
                        confirm that Your Content will comply with these Terms
                        fully.
                    </p>
                    <p>
                        Your Content must not be illegal, must not infringe any
                        person's legal rights, and must not be capable of giving
                        rise to legal action against any person (in each case in
                        any jurisdiction and under any applicable law).
                    </p>
                    <p>
                        Your Content, and the use of Your Content by Us in
                        accordance with these Terms, must not be:
                    </p>

                    <ul>
                        <li>libellous or maliciously false; </li>
                        <li>obscene or indecent;</li>
                        <li>
                            infringe any copyright, moral right, database right,
                            trade mark right, design right, right in passing off
                            or other intellectual property right;
                        </li>
                        <li>
                            infringe any right of confidence, right of privacy
                            or right under data protection legislation;{' '}
                        </li>
                        <li>
                            constitute negligent advice or contain any negligent
                            statement;{' '}
                        </li>
                        <li>
                            constitute an incitement to commit a crime,
                            instructions for the commission of a crime or the
                            promotion of criminal activity;{' '}
                        </li>
                        <li>
                            be in contempt of any court or in breach of any
                            court order; be in breach of racial or religious
                            hatred or discrimination legislation;{' '}
                        </li>
                        <li>blasphemous;</li>
                        <li>in breach of official secrets legislation;</li>
                        <li>
                            in breach of any contractual obligation owed to any
                            person;
                        </li>
                        <li>
                            depict violence in an explicit, graphic or
                            gratuitous manner;
                        </li>
                        <li>
                            pornographic, lewd, suggestive or sexually explicit;
                        </li>
                        <li>be untrue, false, inaccurate or misleading;</li>
                        <li>
                            consist of or contain any instructions, advice or
                            other information which may be acted upon and could,
                            if acted upon, cause illness, injury or death, or
                            any other loss or damage;
                        </li>
                        <li>constitute spam;</li>
                        <li>
                            be offensive, deceptive, fraudulent, threatening,
                            abusive, harassing, anti-social, menacing, hateful,
                            discriminatory or inflammatory; or
                        </li>
                        <li>
                            cause annoyance, inconvenience or needless anxiety
                            to any person
                        </li>
                        <li>
                            You may not upload any Creator Content that refers
                            to or includes:
                        </li>
                        <li>A minor (any person under 18 years old);</li>
                        <li>
                            Any person who You do not have the prior consent of
                            to do so;
                        </li>
                        <li>
                            That promotes violence, rape, hypnosis, intoxication
                            or torture;
                        </li>
                        <li>
                            Anything that could be construed as of a sexual
                            nature;
                        </li>
                        <li>Promoting self-harm or suicide;</li>
                        <li>
                            Anything that could be construed as body shaming;
                        </li>
                    </ul>

                    <h3>INTELLECTUAL PROPERTY RIGHTS</h3>

                    <p>
                        All content included on the App, unless uploaded by
                        Subscribers, including, but not limited to, text,
                        graphics, logos, icons, images, sound clips, video
                        clips, data compilations, page layout, underlying code
                        and software is the property of or licensed to Us, Our
                        affiliates or our Fitness Creators or other relevant
                        third parties. Such material is protected by applicable
                        United Kingdom and International intellectual property,
                        copyright and other relevant laws and You may not use
                        any of the material on this App without Our prior
                        written permission.
                    </p>
                    <p>
                        You may not reproduce, copy, distribute, store or in any
                        other fashion re-use material from the App unless
                        otherwise indicated on this App or unless given express
                        written permission to do so by Us.
                    </p>

                    <h3>LINKS TO OTHER APPS</h3>

                    <p>
                        This App may contain links to other Apps. Unless
                        expressly stated, these sites are not under Our control
                        and we don’t endorse the App or its contents. We assume
                        no responsibility for the content of such Apps and
                        disclaim liability for any and all forms of loss or
                        damage arising out of the use of them.{' '}
                    </p>
                    <p>
                        <strong>
                            OUR RESPONSIBILITY TO YOU FOR LOSS OR DAMAGE
                            SUFFERED BY YOU - YOUR ATTENTION IS PARTICULARLY
                            DRAWN TO THIS CLAUSE IF YOU ARE A CONSUMER.
                        </strong>
                    </p>
                    <p>
                        Disclaimer. We do not intend to exclude or limit in any
                        way Our liability to You where it would be unlawful to
                        do so or take away any of Your rights as a Consumer in
                        the UK. This includes liability for death or personal
                        injury caused by Our negligence or the negligence of Our
                        employees, agents or subcontractors and for fraud or
                        fraudulent misrepresentation.
                    </p>
                    <p>
                        If You are a Consumer and we fail to comply with these
                        Terms, We are responsible for loss or damage You suffer
                        that is a foreseeable result of Our breaking this
                        contract or Our failing to use reasonable care and
                        skill, but we are not responsible for any loss or damage
                        that is not foreseeable. Loss or damage is foreseeable
                        if either it is obvious that it will happen or if, at
                        the time the contract was made, both We and You knew it
                        might happen, for example, if You discussed it with Us
                        during the sales process.{' '}
                    </p>
                    <p>
                        If You are a business, We will have no liability to You
                        for any loss of profit, loss of business, business
                        interruption, or loss of business opportunity if You use
                        this App for commercial purposes. Where any information
                        or services on our App is free, we will not be liable to
                        You for any loss whatsoever. Our total liability to you
                        for all claims in respect of these Terms will not exceed
                        the total amount paid in Fees under the contract of
                        service subscribed for in the prior 12 months. You
                        hereby indemnify us, and undertake to keep us
                        indemnified, against any and all losses, damages, costs,
                        liabilities and expenses (including without limitation
                        legal expenses and any amounts paid by us to a third
                        party in settlement of a claim or dispute) incurred or
                        suffered by Us and arising directly or indirectly out of
                        Your use of the App or any breach by You of any
                        provision of these Terms (in particular Your obligations
                        in relation to Your Content).
                    </p>
                    <p>
                        We will use Our best efforts to provide access to our
                        App but are under no obligation to provide uninterrupted
                        access to this App. We reserve the right to restrict
                        Your access to this App at any time and for any reason.
                    </p>
                    <p>
                        Subject to clauses above, We exclude any and all
                        liability to You resulting from Your use of the App or
                        these Terms, including but not limited to any type of
                        damages, loss of data, income or profit, loss or damage
                        to property belonging to You or third parties which is
                        related to the use of the App or its contents.
                    </p>
                    <p>
                        We are not liable to You if Your Content is copied,
                        reposted or distributed or Your copyright infringed by a
                        third party or user of the App;
                    </p>
                    <p>
                        Subject to clauses above, Your use of the App or its
                        content in any way is done at Your own risk and We will
                        not be responsible for any loss or damage to any device,
                        computer, software, IT systems or data which results
                        directly or indirectly from the use or inability to use
                        the App or its content. We will not be liable for any
                        disclosure of Your identity by other users without Your
                        consent either.
                    </p>
                    <p>
                        Subject to clauses above, You hereby indemnify us, and
                        undertake to keep us indemnified, against any and all
                        losses, damages, costs, liabilities and expenses
                        (including without limitation legal expenses and any
                        amounts paid by us to a third party in settlement of a
                        claim or dispute) incurred or suffered by Us and arising
                        directly or indirectly out of Your use of the App or any
                        breach by You of any provision of these Terms (in
                        particular Your obligations in relation to Your
                        Content).
                    </p>
                    <p>
                        Although we try to ensure that the App will be free of
                        errors, bugs, worms, trojans or viruses, we cannot
                        guarantee it and don’t take any liability if it does not
                        or otherwise make any representations as to the quality
                        or accuracy or completeness of the content available on
                        the App. You are responsible for maintaining appropriate
                        software on Your computer or device to protect You from
                        any errors, bugs, worms, trojans or viruses. We are not
                        liable for availability of the internet or any error in
                        Your connection when You use the App.
                    </p>
                    <p>
                        We take no liability for the App being accessed by an
                        unauthorised third party due to You not keeping Your
                        login details secure or not logging out after use.
                    </p>

                    <p>
                        We accept no liability for any disruption or
                        non-availability of the App resulting from external
                        causes which is not in Our control, including, but not
                        limited to, Internet Service Provider equipment failure,
                        host equipment failure, communications network failure,
                        power failure, natural events, acts of war or legal
                        restrictions and censorship.
                    </p>
                    <p>
                        Should we be found liable for any claims arising out of
                        or related to Your contract with Us, due to any reason
                        whatsoever, including tort, breach of statutory duty or
                        otherwise will be the higher of:
                    </p>
                    <p>
                        he fees paid by You to Us (not the Creator) or (b)
                        £5,000.00
                    </p>
                    <p>
                        This clause 'LINKS TO OTHER APPS' will survive
                        termination of the Subscription.
                    </p>

                    <h3>
                        TERMINATION, CONSEQUENCES OF TERMINATION AND SURVIVAL
                    </h3>

                    <p>
                        <strong>Suspension &amp; Termination.</strong> Without
                        limiting any of Our other rights, We may suspend or
                        terminate these Terms with You or revoke Your access to
                        the App with immediate effect by giving written notice
                        to You if:
                    </p>
                    <p>
                        You commit a material breach of any term of the Contract
                        and (if such a breach is remediable) fail to remedy that
                        breach within 5 (five) days of You being notified in
                        writing to do so or repeatedly breach these Terms;
                    </p>
                    <p>
                        We reserve the right to suspend and/or cancel the
                        contract between Us and Your account if:
                    </p>

                    <ul>
                        <li>You do not make payment to Us when due;</li>
                        <li>
                            We do not/can no longer deliver services to you or
                            to Your geographical area;{' '}
                        </li>
                        <li>
                            The Creator no longer posts the Creator Content
                            which you subscribed to;{' '}
                        </li>
                        <li>
                            one or more of the Services You ordered was listed
                            at an incorrect price due to a typographical error;
                        </li>
                        <li>
                            to protect Us or You from the threat of malware,
                            spam, fraud, data breaches, cybersecurity attack or
                            any other reasonable risk;
                        </li>
                        <li>
                            if You do anything that causes Us or which we
                            reasonably think may cause Us harm to Our reputation
                            or cause Us to suffer a loss;
                        </li>
                        <li>
                            if We become aware of anything that does not comply
                            with these Terms or applicable laws, to investigate
                            it.
                        </li>
                    </ul>

                    <p>
                        We may take any action that We think is appropriate such
                        as to reinstate or permanently cancel or remove Your
                        account or disable Your access, without Your prior
                        consent or notice to You. You may ask Us to review Our
                        decision, but We are not under any obligation to do so.
                    </p>
                    <p>
                        If We cancel Your contract we will notify You by email
                        and will credit to Your account any sum deducted by Us
                        from Your credit card for Services not rendered yet, as
                        soon as possible but in any event within 30 days of Your
                        order. We will not be obliged to offer any additional
                        compensation for any disappointment suffered.
                    </p>
                    <p>
                        If We cancel the contract because You break the
                        contract, we will refund any money You have paid in
                        advance for services not received but we may deduct a
                        reasonable amount as compensation for the net costs We
                        will incur as a result.
                    </p>
                    <p>
                        We will not be liable to You for any loss suffered by
                        You due to the suspension of access to Your account or
                        content or any other steps we take in good faith to
                        investigate any suspected unlawfulness or content or
                        other non-compliance with these Terms.
                    </p>
                    <p>
                        If We cancel Your account, we may deal with Your Content
                        in line with Our privacy policy from time to time
                        (including deleting it) and You will not be able to
                        retrieve or access it after termination.
                    </p>

                    <p>
                        <strong>Termination by You.</strong> You may cancel your
                        account with Us at any time by letting us know that You
                        want to do so via e-mail to support@flock.fitness.
                    </p>

                    <p>
                        <strong>Consequences of termination.</strong>{' '}
                        Termination of these Terms will not affect Your or Our
                        rights and remedies that have accrued before
                        termination.
                    </p>
                    <p>
                        <strong>Survival.</strong> Any provision of the Contract
                        that expressly or by implication is intended to come
                        into or continue in force on or after termination will
                        remain in full force and effect.
                    </p>

                    <h3>COMMUNICATIONS BETWEEN US</h3>

                    <p>
                        When We refer to "in writing" in these Terms, this
                        includes e-mail.
                    </p>
                    <p>
                        Any notice or other communication given under or in
                        connection with the Contract must be in writing and be
                        delivered personally, sent by pre-paid first-class post
                        or other next working day delivery service, or e-mail.
                    </p>
                    <p>
                        A notice or other communication is deemed to have been
                        received:{' '}
                    </p>

                    <ul>
                        <li>
                            if delivered personally, on signature of a delivery
                            receipt [or at the time the notice is left at the
                            proper address;{' '}
                        </li>
                        <li>
                            if sent by pre-paid first-class post or other next
                            working day delivery service, at 9.00 am (GMT) on
                            the second working day after posting; or
                        </li>
                        <li>
                            if sent by e-mail, at 9.00 am (GMT) the next working
                            day after transmission.
                        </li>
                        <li>
                            In proving the service of any notice, it will be
                            sufficient to prove, in the case of a letter, that
                            such letter was properly addressed, stamped and
                            placed in the post and, in the case of an e-mail,
                            that such e-mail was sent to the specified e-mail
                            address of the addressee.{' '}
                        </li>
                        <li>
                            The provisions of this clause will not apply to the
                            service of any proceedings or other documents in any
                            legal action.
                        </li>
                    </ul>

                    <h3>GENERAL</h3>
                    <p>
                        <strong>Waiver.</strong> If We do not insist that You
                        perform any of Your obligations under the Contract, or
                        if We do not enforce Our rights against You, or if We
                        delay in doing so, that will not mean that We have
                        waived Our rights against You or that You do not have to
                        comply with those obligations. If We do waive any
                        rights, We will only do so in writing, and that will not
                        mean that We will automatically waive any right related
                        to any later default by You.
                    </p>
                    <p>
                        <strong>Severance.</strong> Each paragraph of these
                        Terms operates separately. If any court or relevant
                        authority decides that any of them is unlawful or
                        unenforceable, the remaining paragraphs will remain in
                        full force and effect.
                    </p>
                    <p>
                        <strong>Third party rights.</strong> The Contract is
                        between You and Us. No other person has any rights to
                        enforce any of its Terms.{' '}
                    </p>
                    <p>
                        <strong>Governing </strong>law and jurisdiction. These
                        Terms of Use, their subject matter and their formation,
                        are governed by English law. You and We both agree that
                        the courts of England and Wales will have exclusive
                        jurisdiction (including if You live in another country
                        than the UK or EU), except that if You are a resident of
                        Northern Ireland You may also bring proceedings in
                        Northern Ireland, and if You are resident of Scotland,
                        You may also bring proceedings in Scotland. If You are a
                        business these Terms, its subject matter and its
                        formation (and any non-contractual disputes or claims)
                        are governed by English law. We both agree to the
                        exclusive jurisdiction of the courts of England and
                        Wales.
                    </p>

                    <h1 style={{ marginTop: '5rem' }} id="subscribers">
                        Terms specific to subscribers
                    </h1>

                    <p>
                        All definitions in the General Terms of Use also apply
                        here.
                    </p>
                    <p>
                        “Services” means the services provided by the Creator
                        (such as fitness programs) to which We are NOT a party
                        and have no involvement other than facilitating it.
                    </p>
                    <p>
                        “Subscription” means Your monthly fee paid to a Creator
                        to access the Creator’s content.
                    </p>
                    <p>
                        “Subscription Fee(s)” means the monthly fees You will
                        pay to the Creator (facilitated by our payment platform
                        on the App) for access to the Services.
                    </p>
                    <p>
                        “Subscriber” refers to You/a person subscribing for the
                        Services by way of the Subscription.
                    </p>
                    <p>
                        “VAT” means value added tax in the United Kingdom from
                        time to time and any similar tax, duty, charge or levy
                        imposed in any other jurisdiction on sales by the
                        government, state, municipality or the like. Similarly
                        “Tax” shall include all forms of tax, duties charges and
                        levies imposed in the UK or any other jurisdiction.
                    </p>

                    <h3>OTHER TERMS THAT WILL APPLY TO YOU</h3>

                    <p>
                        These Subscriber Terms, General Terms of Use and Our
                        Privacy Policy will apply to Your use of the App
                        (“Additional Terms”) and are incorporated into these
                        Terms by reference. You will also enter into and be
                        bound by the agreement between You and the Creator.
                    </p>

                    <h3>USE</h3>

                    <p>
                        You warrant (to promise in a legally binding way) that
                        You are older than 18 and/or can be bound by these terms
                        if you are resident in a country where the legal
                        contracting age is different. You also warrant that You
                        have not been barred from using our App in terms of any
                        laws applicable to You.
                    </p>
                    <p>
                        In respect of Your use of the Creator Content on the
                        App, You agree, understand and acknowledge that:
                    </p>
                    <p>
                        Creator Content is uploaded by anybody that registers on
                        our App as a Creator and such Creator is not necessarily
                        a qualified fitness instructor. We may require do not
                        undertake to vet every/any Creator at all as to their
                        competence or knowledge of the subject and You should
                        assume that the Services are community type services
                        offered by unqualified individuals. We take no liability
                        if You decide to follow the advice of any Creator in
                        respect of exercising or otherwise and You should
                        investigate any new fitness program before following
                        suggestions, recommendations or advice from a Creator.
                    </p>
                    <p>
                        We provide the App as a platform where different fitness
                        Creators create Creator Content for Your personal use.
                        WE ARE NOT A PARTY TO THE CONTRACT BETWEEN YOU AND A
                        CREATOR OF THE CREATOR CONTENT, HOWEVER YOU WILL BE
                        BOUND BY THE RULES AND TERMS OF THE CONTRACT YOU WILL
                        ENTER INTO WITH THE CREATOR WHEN SIGNING UP FOR A
                        SUBSCRIPTION.
                    </p>
                    <p>
                        Neither we, nor our fitness Creators are a medical
                        organisation, qualified fitness instructors or
                        nutritionists, healthcare providers or medical, fitness
                        or nutritional practitioners who provide medical,
                        nutritional or qualified fitness advice or diagnosis.
                        The fitness Services and any implied advice associated
                        with it should not be understood as medical, qualified
                        nutritional or fitness advice or treatment. All
                        Subscribers should always seek medical, qualified
                        nutritional or fitness advice from a qualified medical,
                        fitness or nutritional practitioner;{' '}
                    </p>
                    <p>
                        We act merely as a facilitator, providing the platform,
                        storing content and acting as payment intermediary
                        (through the use of Our payment platform provider) and
                        we are not a party to your subscription agreement with
                        any Creator.
                    </p>
                    <p>
                        The Creator Content published on the App should not be
                        interpreted as a substitute for medical consultation,
                        evaluation, or treatment. Subscribers must always seek
                        medical advice from a registered medical practitioner
                        before beginning any new exercise or training plan. The
                        Creator Content is intended for use only by healthy
                        adults. It is not intended or tailored for use by
                        minors, pregnant women, or individuals with any type of
                        health condition or disability.{' '}
                    </p>
                    <p>
                        The Subscriber must always first consult with their own
                        healthcare practitioner or medical practitioner and
                        consider the associated risks before using the Creator
                        Content on the App. By using the App, Subscribers agree,
                        represent and warrant that they have received consent
                        from their own healthcare practitioner or medical
                        practitioner to participate in wellness and fitness
                        programs, workouts, exercises or any of the related
                        activities made available to Subscribers on the App;{' '}
                    </p>
                    <p>
                        Subscribers make use of all content published on the App
                        by Creators at their own risk and are voluntarily using
                        the Services and have not been induced to use the App or
                        content by Us or any of our affiliates or Creator
                        Content Creators;{' '}
                    </p>
                    <p>
                        We do not carry out and are not responsible for any
                        inspection, supervision, preparation, execution or
                        conduct of any of the Creator Content published on the
                        App;{' '}
                    </p>
                    <p>
                        Any athletic activities undertaken by using the Creator
                        Content published on the App carry certain inherent and
                        significant risks of bodily injury and / or death and
                        all Subscribers voluntarily assume all known and unknown
                        risks associated with these activities;{' '}
                    </p>
                    <p>
                        Not all Creators who identify themselves as professional
                        trainers are licensed in any or all applicable
                        jurisdictions. We accept no, and assume no obligation,
                        to verify that Creators who identify themselves as
                        licensed trainers are actually licensed and you should
                        assume that they are unqualified social fitness content
                        providers;{' '}
                    </p>
                    <p>
                        Except as otherwise set out in these Terms or in terms
                        of any consumer rights you may have, We are not
                        responsible nor liable, either directly or indirectly to
                        You or any third party, for any injuries or damages
                        sustained from physical activities or your use of the
                        application contrary to these terms, or inability to use
                        the Creator Content published on the App, even if caused
                        in whole or part by our action, inaction and to the
                        fullest extent permitted by law.
                    </p>

                    <h3>CONTENT THAT YOU AS USER UPLOAD</h3>

                    <p>
                        Subscriber -generated content is not approved by Us.
                        This App may include information and materials uploaded
                        by other users or subscribers of the App, including to
                        bulletin boards and chat rooms. This information and
                        these materials have not been verified or approved by
                        Us. The views expressed by other users on Our App do not
                        represent Our views or values. If You want to complain
                        about content uploaded by other users, please contact Us
                        at our address as indicated at clause 2.2.{' '}
                    </p>
                    <p>
                        You will comply with the Content requirements set out in
                        clause 8 of the General Terms of Use and will indemnify
                        Us for any loss or damage We suffer if You don’t.{' '}
                    </p>

                    <h3>SUBSCRIPTIONS</h3>

                    <p>
                        To become a subscriber to the Services of the Creator,
                        You must pay the applicable subscription Fees for the
                        Content which you choose to use. Subscription Fees are
                        facilitated by Our payment platform partner once you
                        have created an account and subscribed to your choice of
                        Creator Content.{' '}
                    </p>
                    <p>
                        We use the payment platform Stripe to make and receive
                        payments and you will be directed to the Stripe payment
                        platform once you click “pay”. Once you have paid We
                        will send You an acknowledgement of Your subscription,
                        at which point the contract between Us for the supply of
                        the App services shall come into force.
                    </p>
                    <p>
                        We may from time to time vary the benefits associated
                        with a subscription by giving You written notice of the
                        variation, providing that, if in Our reasonable opinion
                        such a variation results in a substantial loss of value
                        or functionality to You, You will have the right to
                        cancel Your subscription, and we will refund to You any
                        amounts paid to Us in respect of any period of
                        subscription after the date of such cancellation.
                    </p>
                    <p>
                        At the end of any period of subscription for which You
                        have paid, and subject to the other provisions of these
                        Terms and conditions, Your subscription will be
                        automatically renewed and You must pay to Us the
                        applicable subscription Fees as at the time of renewal,
                        unless You cancel the subscription using the
                        cancellation facility on Our App before the date of
                        renewal.
                    </p>

                    <h3>FEES</h3>

                    <p>
                        The Fees are exclusive of VAT, which shall be added at
                        the applicable rate (which may be dependent on the
                        country You live in).
                    </p>
                    <p>
                        The Creators are solely responsible for deciding what
                        they charge for their Services (although we provide some
                        guidance and parameters). You will pay the Fees to the
                        Creator monthly in advance by adding a payment card on
                        the payment platform on Our App when subscribing.{' '}
                    </p>
                    <p>
                        You authorise us to supply Your payment card details to
                        the third-party payment platform provider We use from
                        time to time. You may be liable for currency conversion
                        costs charged by the payment platform provider from time
                        to time over which we have no control.
                    </p>
                    <p>
                        The payment provider will take monthly fees from Your
                        account using the supplied payment card details for the
                        duration of Your Subscription, which You consent to and
                        until You cancel or turn off auto-renew in Your profile.
                    </p>
                    <p>
                        The Creator may vary Fees from time to time/annually, by
                        posting new Fees on Our App and advising you in writing,
                        but this will not affect Fees for Services that have
                        been previously paid for and agreed.
                    </p>
                    <p>
                        Any disputed payments must be brought to the Creator’s
                        attention without delay with full details.
                    </p>
                    <p>
                        If You make an unjustified credit card, debit card or
                        other charge-back then You will be liable to pay Us,
                        within 7 days following the date of Our written request:{' '}
                    </p>
                    <p>an amount equal to the amount of the charge-back;</p>
                    <p>
                        all third-party expenses incurred by Us in relation to
                        the charge-back (including charges made by Our or Your
                        bank or payment processor or card issuer);
                    </p>
                    <p>an administration fee of GBP 25.00 including VAT; and</p>
                    <p>
                        all Our reasonable costs, losses and expenses incurred
                        in recovering the amounts referred to in this clause 5.7
                        (including without limitation legal fees and debt
                        collection fees),
                    </p>
                    <p>
                        For the avoidance of doubt, if You fail to recognise or
                        fail to remember the source of an entry on Your card
                        statement or other financial statement, and make a
                        charge-back as a result, this will constitute an
                        unjustified charge-back for the purposes of this clause.
                    </p>

                    <h3>
                        DISTANCE CONTRACTS: CANCELLATION RIGHT OF CONSUMERS
                        (ONLY)
                    </h3>

                    <p>
                        This clause applies ONLY IF You offer to contract with
                        Us, or contract with Us, as a Consumer.
                    </p>
                    <p>
                        You may cancel a subscription entered into with a
                        Creator, at any time within a period of 14 days after
                        the day on which the contract is entered into, subject
                        to clause 6.3 ,You do not have to give any reason for
                        Your withdrawal or cancellation.
                    </p>
                    <p>
                        You agree that the Creator may begin the provision of
                        Services before the expiry of the period referred to in
                        clause 6.2 and You acknowledge that, if it does begin
                        the provision of Services before the end of that period,
                        then:
                    </p>
                    <p>
                        if the Services are fully performed, You will lose the
                        right to cancel referred to in clause 6.2;{' '}
                    </p>
                    <p>
                        if the Services are partially performed at the time of
                        cancellation, You must pay an amount proportional to the
                        Services supplied by the Creator, up to the date of
                        cancellation or he may deduct such amount from any
                        refund due to You in accordance with this clause 6.
                    </p>
                    <p>
                        In order to withdraw an offer to contract or cancel a
                        contract on the basis described in this clause, You must
                        inform Us and the Creator of Your decision to withdraw
                        or cancel by the end of the 14 days stated above. You
                        may inform Us by means of any clear statement setting
                        out the decision. In the case of cancellation of Your
                        entire account, You may inform Us using the cancellation
                        form that we will make available to You. To meet the
                        cancellation deadline, it is sufficient for You to send
                        Your communication concerning the exercise of the right
                        to cancel before the cancellation period has expired.
                    </p>
                    <p>
                        If You withdraw an offer to contract, or cancel a
                        contract, on the basis described in this clause, You
                        will receive a full refund of any amount You paid to Us
                        in respect of the offer or contract, except as specified
                        in this clause (in particular if the Services has
                        already commenced in which case You will not be
                        refunded).
                    </p>
                    <p>
                        You will be refunded using the same method used to make
                        the payment, unless You have expressly agreed otherwise.
                        In any case, You will not incur any fees as a result of
                        the refund.
                    </p>
                    <p>
                        We will process the refund due to You as a result of a
                        cancellation on the basis described in this clause 6
                        without undue delay and, in any case, within the period
                        of 14 days after the day on which we are informed of the
                        cancellation.
                    </p>

                    <h1 style={{ marginTop: '5rem' }} id="creators">
                        Terms specific to creators
                    </h1>

                    <p>
                        All definitions in the General Terms of Use also apply
                        here.
                    </p>
                    <p>
                        “Majority Control” means any person who holds more than
                        50% of the shares or interests in an entity or more than
                        50% of the voting rights in the entity (directly or
                        indirectly).
                    </p>
                    <p>
                        “Services” means the services provided by the Creator
                        (such as fitness programs) to which We are NOT a party
                        and have no involvement other than facilitating it.
                    </p>
                    <p>
                        “Subscription” means Your monthly fee paid to a Creator
                        to access the Creator’s content.
                    </p>
                    <p>
                        “Subscription Fees” means the monthly fees You will pay
                        to the Creator (facilitated by our payment platform on
                        the App) for access to the Services.
                    </p>
                    <p>
                        “Subscriber” refers to You/a person subscribing for the
                        Services by way of the Subscription.
                    </p>
                    <p>
                        “VAT” means value added tax in the United Kingdom from
                        time to time and any similar tax, duty, charge or levy
                        imposed in any other jurisdiction on sales by the
                        government, state, municipality or the like. Similarly
                        “Tax” shall include all forms of tax, duties charges and
                        levies imposed in the UK or any other jurisdiction.
                    </p>

                    <h3>OTHER TERMS THAT WILL APPLY TO YOU</h3>

                    <p>
                        These Creator Terms, General Terms of Use and Our
                        Privacy Policy will apply to Your use of the App
                        (“Additional Terms”) and are incorporated into these
                        Terms by reference. You will also enter into and be
                        bound by the agreement between You and the Subscriber
                        for each transaction You enter with them (to which We
                        are not a party). If You participate in the Referral
                        Program, these terms will also apply to You.
                    </p>

                    <h3>FEES AND PAYMENT OF OUR COMMISSION</h3>

                    <p>
                        No fees are charged by Us if You create an account and
                        profile on the App. You will continue to enjoy access
                        and the benefits of Your account and profile for as long
                        as Your account is active and You continue to load
                        content;
                    </p>
                    <p>
                        The Fees We charge You for Your use of the App, costs of
                        providing, supporting and maintaining the App and any
                        platforms, and storing Your Content as well as
                        facilitating the transaction and payments between You
                        and the Subscriber is a 20% percent of all payments
                        (exclusive of any VAT on such payments) made to You by
                        the Subscribers.{' '}
                    </p>
                    <p>
                        We use a Payment Platform (currently Stripe) to make and
                        receive payments and you will be directed to the Payment
                        Platform to create Your Payment Platform Account through
                        which We will receive payment of Our commission and You
                        will receive payment of Your subscription fees from
                        Subscribers.{' '}
                    </p>
                    <p>Your fees are handled as follows:</p>

                    <ul>
                        <li>
                            Subscribers will subscribe to use Your content and
                            pay the Subscription Fees on the payment platform;
                        </li>
                        <li>
                            The payment platform will automatically deduct Our
                            commission calculated at 20% of the Subscription Fee
                            (Our “Commission”) You charge (excluding any VAT on
                            the payment) and will allocate payments to Our
                            account (20% of Subscription Fee) and Your chosen
                            bank account (80% of the subscription fee). Actual
                            payments are only released once per month by the
                            Payment Platform;
                        </li>
                        <li>
                            Included in Our commission is all fees that are
                            charged by the Payment Platform;
                        </li>
                        <li>
                            You may increase Your Subscription Fees once per
                            year and We recommend you do this on the anniversary
                            of the date of the creation of Your profile and
                            limit such increases to a maximum of 10%;
                        </li>
                    </ul>

                    <p>
                        You must honour all subscriptions made for the
                        subscription period as agreed with the subscriber.
                        Initially all subscriptions will be ongoing (monthly).
                        Take note that Subscribers have consumer rights to
                        cancel subscriptions and We may also do so. Please
                        review the Subscriber Terms of Use and familiarise
                        Yourself with these terms, which are incorporated here
                        by this reference;
                    </p>
                    <p>
                        At the end of any period of subscription, and subject to
                        the other provisions of these Terms, the subscription
                        will be automatically renewed and Subscribers will pay
                        to You the applicable subscription Fees facilitated by
                        Our App.as at the time of renewal, unless Subscribers
                        cancel the subscription using the cancellation facility
                        on Our App before the date of renewal;
                    </p>
                    <p>
                        We cannot guarantee that such payments will be honoured
                        by Subscribers.
                    </p>
                    <p>
                        We may at any time set off any amount that You owe to Us
                        against any amount that We owe to You, by sending You
                        written notice of the set-off.
                    </p>

                    <h3>SUBSCRIBERS &amp; YOUR INTERACTION WITH THEM</h3>

                    <p>
                        Subscribers will subscribe to use Your Content on Our
                        App and will pay the Subscription Fee which You set on
                        Your profile page. Subscribers may subscribe to multiple
                        profiles on the App;
                    </p>
                    <p>
                        Once You have uploaded Your content, You can charge the
                        Subscribers to Your Creators Content a Subscription Fee;{' '}
                    </p>
                    <p>
                        You set Your own subscription fees on Your account
                        profile, however We recommend that you charge
                        subscription fees within a range of £5.00 - £25.00
                        (excluding VAT);
                    </p>
                    <p>
                        You will be entering into a contract with the
                        Subscribers and Our role in Your agreement and
                        interactions with the Subscribers are limited to
                        facilitate the initial transaction between You,
                        providing the App and platform and acting as payment
                        intermediary.
                    </p>
                    <p>
                        Once a Subscriber has paid, You have to allow the
                        Subscriber to use the content as paid for. You indemnify
                        Us in full (to the fullest extent permitted by law)
                        against any and all claims of whatever nature and
                        howsoever arising from Your breach of this obligation.
                    </p>
                    <p>
                        We are not a party to the Contract between You and the
                        Subscriber or any other contract that may exist or be
                        entered into between you and will have not
                        responsibility or liability for any interactions between
                        You and the Subscriber. Please review the Subscriber
                        Terms of Use and familiarise Yourself with these terms,
                        which are incorporated here, by this reference and in
                        particular clause 6 (DISTANCE CONTRACTS: CANCELLATION
                        RIGHT OF CONSUMERS (ONLY)).
                    </p>
                    <p>
                        You have to ensure that You comply fully with any refund
                        to the Subscriber as set out in the above mentioned
                        clause 6 of the Subscriber Terms.
                    </p>
                    <p>
                        You will be responsible for Your own Tax and Vat affairs
                        and We strongly suggest that you get professional advice
                        to ensure Your compliance with Your local Tax and VAT
                        obligations in respect of any Subscription Fees and
                        Referral Fees earned by You.
                    </p>
                    <p>
                        You confirm and warrant that You will report Your income
                        as may be required in the country that You live in and
                        comply with all laws related to Tax applicable to You.
                    </p>

                    <h3>CREATING ACCOUNTS</h3>

                    <p>
                        You can register to create a Creator account by
                        completing and submitting the account registration form
                        on Our App and clicking on the verification link in the
                        email that the App will send to You. Once verified, the
                        App will send You an Invite Code with additional
                        information to finish creating Your account. An account
                        can only be successfully completed if You receive an
                        Invite Code. Once Your account is completed You may
                        start uploading Your Content subject to compliance with
                        these terms at all times;
                    </p>
                    <p>
                        As part of your account setup process, you will need to
                        provide certain information (specific details on the App
                        and this may vary from time to time as needed):
                    </p>

                    <ul>
                        <li>
                            A valid form of Photo Identification (uploaded);
                        </li>
                        <li>A photograph;</li>
                        <li>
                            Bank details of the bank account You would like to
                            receive payments from the Subscribers to;
                        </li>
                        <li>
                            Any additional information required if you live in
                            any other territory than the UK.
                        </li>
                        <li>An e-mail address;</li>
                        <li>
                            Proof that you have authority to contract for an
                            entity;
                        </li>
                        <li>
                            any other relevant information we may require
                            dependant on where You live;
                        </li>
                        <li>
                            We reserve the right to refuse any application for
                            an account in Our reasonable discretion;
                        </li>
                        <li>
                            We may ask You for additional identification or age
                            confirmation at any time.
                        </li>
                    </ul>

                    <h3>WHO MAY NOT REGISTER AS A CREATOR</h3>

                    <p>
                        No agent may create an account on behalf of a Creator.
                        Every Creator must be the owner of its account and able
                        to access it.
                    </p>
                    <p>
                        Allowing an agent/someone else to operate Your account
                        on Your behalf will not affect the fact that You will
                        remain personally liable for Your account and all legal
                        responsibility for your actions/omissions.
                    </p>
                    <p>
                        If You use an entity to set up Your account and/or
                        receive the Subscription Fees, You will need to provide
                        proof that You are the person with Majority Control of
                        that entity. If You can’t, We may withhold payments to
                        You until You can do so.
                    </p>

                    <h3>CONTENT RULES</h3>

                    <p>
                        The rules in respect of Creator Content are the same as
                        set out in clause 8 of the General Terms of Use and
                        incorporated hereto by reference in full. Make sure that
                        You are clear on what these are, as Your account may be
                        suspended or removed if you don’t keep to the rules
                        strictly.
                    </p>
                    <p>
                        You may not upload any Creator Content that refers to or
                        includes:
                    </p>

                    <ul>
                        <li>A minor (any person under 18 years old);</li>
                        <li>
                            Any person who You do not have the prior consent of
                            to do so;
                        </li>
                        <li>
                            Is likely to cause upset, anxiety or embarrass a
                            Subscriber;
                        </li>
                        <li>
                            Can be seen as an attempt to extract or discuss
                            money with the Subscriber other than that related to
                            the Subscription Fee
                        </li>
                        <li>Promotes third party commercial activities;</li>
                        <li>
                            Is misleading or deceptive in any way, including
                            holding Yourself out to be qualified in a particular
                            profession or subject if You do not hold a formal
                            qualification. We may require You to provide proof
                            of a qualification You claim to have, however We do
                            not undertake to vet every/any Creator.
                        </li>
                        <li>
                            You should not pass off pre-recorded videos as live
                            streams;
                        </li>
                        <li>
                            You must not knowingly introduce viruses, trojans,
                            worms, logic bombs or other material into Content
                            which is malicious or can be harmful to technology
                            and cause Us the App, our systems or the Subscriber
                            harm.
                        </li>
                    </ul>

                    <p>
                        <strong>Co-authored Content.</strong> If You post any
                        Content which features anyone else than Yourself, You
                        will ensure that for each and every such piece of
                        Content You:
                    </p>
                    <ul>
                        <li>
                            Obtain prior express consent from such person to
                            his/her appearance on the Content; and
                        </li>
                        <li>
                            Obtain prior express consent from such person that
                            the Content may be posted on the App; and
                        </li>
                        <li>
                            Ensure that such person is registered already as a
                            Creator on the App;
                        </li>
                    </ul>

                    <p>
                        Subscription Fees will only be paid out to the Creator
                        holding the account to which such Content is first
                        uploaded. Such Creator will be liable for dividing any
                        Subscription Fee payments received in line with its
                        agreement with its co-authors and We will have no
                        liability whatsoever to anyone else than the Creator in
                        respect of the Subscription Fees, obligations under this
                        agreement or otherwise.
                    </p>
                    <p>
                        If You are a co-author of Content, You agree to make any
                        claims for payments directly to Your co-author Creator
                        who uploaded the Content and receives the Subscription
                        Fee payments and release Us from any obligation to You
                        in this regard.
                    </p>

                    <h3>WHEN MAY WE WITHOLD YOUR PAYMENTS?</h3>

                    <p>
                        If You breach any of the terms applicable to You and in
                        particular the General Terms, these terms and the
                        Referral Program terms (if applicable to You);
                    </p>
                    <p>
                        If You attempt to breach any of the above terms which we
                        think will have serious consequences for Us;
                    </p>
                    <p>
                        If You don’t honour Your commitments with Your
                        Subscribers in any way;
                    </p>
                    <p>
                        If we have good reason to believe that You are involved
                        in any illegal activity connected to the use of our App
                        or the earning of any fees related to it.
                    </p>
                </Constrain>
            </Wrapper>
        </AuthLayout>
    );
};
