import { CreatorOnboarding } from 'src/components/CreatorOnboarding/CreatorOnboarding';
import AuthLayout from 'src/components/layouts/AuthLayout';
import { Constrain } from 'src/components/ui/Constrain';

export const CreatorLinkBankAccount = () => {
    return (
        <AuthLayout title="Link your bank account | FLOCK.fitness">
            <Constrain tiny>
                <CreatorOnboarding />
            </Constrain>
        </AuthLayout>
    );
};
