import React, { useEffect, useContext, createContext } from 'react';
import { useSubscriberStore } from 'src/store/subscriberStore';
import { useUserStore } from 'src/store/userStore';
import { firestore } from '../firebase';

const SubscriberContext = createContext<{}>({} as any);

export function SubscriberProvider({ children }: { children: any }) {
    const { user, userType } = useUserStore();
    const {
        setLoading,
        subscriber,
        setSubscriber,
        subscriptions,
        setSubscriptions,
    } = useSubscriberStore();

    useEffect(() => {
        function fetchSubscriber(userId: string) {
            return firestore
                .collection('subscribers')
                .doc(userId)
                .onSnapshot((doc) => {
                    setSubscriber(doc.data() as api.Subscriber);
                });
        }

        if (user && userType === 'subscriber') {
            setLoading(true);
            return fetchSubscriber(user.uid);
        }
    }, [setLoading, setSubscriber, user, userType]);

    useEffect(() => {
        function fetchSubscriptions() {
            return firestore
                .collection('subscriptions')
                .where('subscriberId', '==', subscriber!.id)
                .where('stripeSubscription.status', 'in', [
                    'active',
                    'past_due',
                ])
                .onSnapshot(async (querySnapshot) => {
                    const subscriptionsArray: api.Subscription[] = [];

                    await Promise.all(
                        querySnapshot.docs.map(async (doc) => {
                            const subscription = doc.data() as api.Subscription;
                            const profileSnapshot =
                                await subscription.profileRef.get();

                            subscriptionsArray.push({
                                ...subscription,
                                profile:
                                    profileSnapshot.data() as api.CreatorProfile,
                            });
                        })
                    );

                    setSubscriptions(subscriptionsArray);
                });
        }

        if (user && userType === 'subscriber' && subscriber) {
            setLoading(true);
            return fetchSubscriptions();
        }
    }, [user, userType, subscriber, setLoading, setSubscriptions]);

    useEffect(() => {
        if (subscriber && subscriptions) {
            setLoading(false);
        }
    }, [setLoading, subscriber, subscriptions]);

    return (
        <SubscriberContext.Provider value={{}}>
            {children}
        </SubscriberContext.Provider>
    );
}

export const useSubscriber = () => {
    return useContext(SubscriberContext);
};
