import React, { FunctionComponent, useState } from 'react';
import { PrimaryButton } from 'src/components/ui/Button/PrimaryButton';
import { FormActions } from 'src/components/ui/FormActions';
import styled from 'src/styles/styled-components';
import { ReactComponent as ChevronDownSVG } from 'src/svgs/icons/chevron-down.svg';
import { media } from 'src/utils/mediaQueries';
import { Workout } from 'src/components/Workout/Workout';
import { TertiaryButton } from 'src/components/ui/Button/TertiaryButton';
import { StylelessButton } from 'src/components/ui/Button/StylelessButton';

const Wrapper = styled.div`
    max-width: 500px;
    margin: 0 auto;

    ${media.l`
        ${Workout} {
            display: block;
        }
    `}

    button {
        font-size: ${({ theme }) => theme.font.size.level3.small};
    }
`;

const ContentBlock = styled.div`
    background-color: ${({ theme }) => theme.colors.grey6};
    margin-bottom: 2rem;
    border-radius: 3px;
    padding: 1.5rem 1.3rem;
    position: relative;

    h4 {
        margin: 0;
        font-weight: 500;
    }

    p {
        margin-bottom: 2rem;
        font-size: ${({ theme }) => theme.font.size.level5.small};

        &:last-child {
            margin: 0;
        }
    }

    strong {
        font-weight: 500;
    }

    ul {
        font-size: ${({ theme }) => theme.font.size.level5.small};
    }
`;

const CollapsibleContentBlock = styled(ContentBlock)<{ expanded: boolean }>`
    transition: max-height 300ms;
    max-height: ${({ expanded }) => (expanded ? '200rem' : '20rem')};
    overflow: hidden;

    .expand {
        position: absolute;
        bottom: 0rem;
        left: 0;
        right: 0;
        background: linear-gradient(#f2f2f200, #f2f2f2ba, #f2f2f2);
        height: 6rem;
        display: flex;
        align-items: flex-end;
        justify-content: center;
    }
`;

type Props = {
    onBack: () => void;
    publish: () => Promise<void>;
    draftWorkout: flock.Workout;
};

export const PreviewWorkout: FunctionComponent<Props> = ({
    onBack,
    draftWorkout,
    publish,
}) => {
    const [expanded, setExpanded] = useState(
        draftWorkout.whatsInvolved.length < 400
    );
    const [submitting, setSubmitting] = useState(false);

    return (
        <>
            <Wrapper>
                <Workout workout={draftWorkout} isDraft={true} />

                <div>
                    <ContentBlock>
                        <h4>What you'll need</h4>

                        <p>
                            {(draftWorkout.equipment.length > 0 &&
                                draftWorkout.equipment) ||
                                'No equipment needed'}
                        </p>
                    </ContentBlock>

                    <CollapsibleContentBlock expanded={expanded}>
                        <h4>What's involved</h4>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: draftWorkout.whatsInvolved,
                            }}
                        ></div>
                        {!expanded && (
                            <div className="expand">
                                <StylelessButton
                                    onClick={() => setExpanded(true)}
                                >
                                    <ChevronDownSVG />
                                </StylelessButton>
                            </div>
                        )}
                    </CollapsibleContentBlock>

                    <FormActions>
                        <TertiaryButton onClick={onBack}>Edit</TertiaryButton>

                        <PrimaryButton
                            onClick={async () => {
                                setSubmitting(true);
                                await publish();
                                setSubmitting(false);
                            }}
                            isSubmitting={submitting}
                        >
                            Publish
                        </PrimaryButton>
                    </FormActions>
                </div>
            </Wrapper>
        </>
    );
};
