import React, { useCallback, useState } from 'react';
import styled, { css } from 'src/styles/styled-components';
import ReactModal from 'react-modal';
import { transparentize } from 'polished';

export interface IModalProps extends ReactModal.Props {
    onConfirm: () => Promise<void>;
    onCancel: () => void;
    actionType: 'delete';
    confirmText?: string;
    children?: any;
}

function ReactModalAdapter({ className, modalClassName, ...props }: any) {
    return (
        <ReactModal
            className={modalClassName}
            portalClassName={className}
            closeTimeoutMS={400}
            {...props}
        />
    );
}

const overlayClassName = 'confirm-modal__overlay';
const modalContentClassName = 'confirm-modal__content';

const StyledModal = styled(ReactModalAdapter).attrs({
    overlayClassName: overlayClassName as any,
    modalClassName: modalContentClassName as any,
})`
    .${modalContentClassName} {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 300;
        opacity: 0;
        transition: opacity 200ms ease-out;

        &:focus {
            outline: none;
        }
    }

    .${overlayClassName} {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 200;
        background-color: rgba(0, 0, 0, 0.3);
    }

    .ReactModal__Content--after-open {
        opacity: 1;
        transition: opacity 200ms ease-out;
    }

    .ReactModal__Content--before-close {
        opacity: 0;
        transition: opacity 200ms ease-out;
    }

    .ReactModal__Overlay--after-open {
        opacity: 1;
        transition: opacity 200ms ease-out;
    }

    .ReactModal__Overlay--before-close {
        opacity: 0;
        transition: opacity 200ms ease-out;
    }
`;

const ModalContent = styled.div`
    background-color: white;
    width: 30rem;
    border-radius: 3px;
`;

const Question = styled.div`
    padding: 2rem;
    font-weight: 500;
    font-size: 1.8rem;
`;

const Error = styled.p`
    margin-bottom: 1rem;
`;

const removeButtonStyles = css`
    appearance: none;
    border: 0;
    padding: 0;
    margin: 0;
    background: none;
    cursor: pointer;
    transition: background 200ms;
`;

const ConfirmAction = styled.button<{ isDeleteAction: boolean }>`
    ${removeButtonStyles}
    width: 50%;
    padding: 1.5rem;
    border-bottom-left-radius: 3px;
    font-size: 1.8rem;
    font-weight: 500;

    ${({ theme, isDeleteAction }) =>
        isDeleteAction &&
        css`
            background: ${transparentize(0.9, theme.colors.pinkRed)};
            color: ${theme.colors.pinkRed};

            &:hover {
                background: ${transparentize(0.8, theme.colors.pinkRed)};
            }
        `}
`;
const CancelAction = styled.button`
    ${removeButtonStyles}
    width: 50%;
    padding: 1.5rem;
    font-size: 1.8rem;
    border-bottom-right-radius: 3px;

    &:hover {
        background: ${({ theme }) => theme.colors.grey6};
    }
`;

export const ConfirmModal = ({
    actionType,
    onCancel,
    onConfirm,
    confirmText,
    ...props
}: IModalProps) => {
    const [isShowError, setIsShowError] = useState<boolean>(false);

    const handleConfirm = useCallback(async () => {
        try {
            setIsShowError(false);
            await onConfirm();
        } catch (err) {
            setIsShowError(true);
        }
    }, [setIsShowError, onConfirm]);

    return (
        <StyledModal {...props}>
            <ModalContent>
                <Question>{props.children}</Question>
                {isShowError && <Error>An error occurred</Error>}
                <div>
                    <ConfirmAction
                        onClick={handleConfirm}
                        isDeleteAction={actionType === 'delete'}
                    >
                        {confirmText || 'Confirm'}
                    </ConfirmAction>
                    <CancelAction onClick={onCancel}>Cancel</CancelAction>
                </div>
            </ModalContent>
        </StyledModal>
    );
};
