/* eslint-disable flowtype/require-valid-file-annotation */
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { CreateCreatorAccount } from 'src/components/CreateAccount/CreateCreatorAccount';
import AuthLayout from 'src/components/layouts/AuthLayout';
import { Constrain } from 'src/components/ui/Constrain';
import { Heading } from 'src/components/ui/Heading';
import { useUserStore } from 'src/store/userStore';

const CreatorSignUp = () => {
    const { push } = useHistory();
    const { user } = useUserStore();

    useEffect(() => {
        if (user) {
            push('/creator/onboarding');
        }
    }, [user, push]);

    return (
        <AuthLayout title="Sign up | FLOCK.fitness">
            <Constrain tiny>
                <Heading>
                    Start <span>your journey</span>
                </Heading>

                <CreateCreatorAccount />
            </Constrain>
        </AuthLayout>
    );
};

export default CreatorSignUp;
