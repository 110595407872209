import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from 'react-router-dom';
import ProfilePage from './routes/Profile/Profile';
import Logout from './routes/Logout';
import CreatorOnboardingPage from './routes/Creator/Onboarding';
import CreatorSignUp from './routes/Creator/SignUp';
import PrivateRoute from './routes/utils/PrivateRoute';
import SubscriberSignUp from './routes/subscriber/SignUp';
import { Home } from './routes/Home/Home';
import { CreateWorkout } from './routes/CreateWorkout';
import { Settings } from './routes/Settings/Settings';
import Login from './routes/Login';
import { useGoingToStripe } from './contexts/goingToStripe';
import { GoingToStripeOverlay } from './components/GoingToStripeOverlay/GoingToStripeOverlay.component';
import { HealthDisclaimer } from './routes/HealthDisclaimer';
import Subscription from './routes/Subscription';
import { ResetPassword } from './routes/ResetPassword';
import { Privacy } from './routes/Privacy';
import { Terms } from './routes/Terms';
import { CreatorLanding } from './routes/Creator/Creator';
import { ManagePreviewVideoModal } from './routes/ManagePreviewVideoModal/ManagePreviewVideoModal';
import withTracker from './withTracker';
import { LoadingPage } from './components/ui/LoadingPage';
import { useUserStore } from './store/userStore';
import { useCreatorStore } from './store/creatorStore';
import { useSubscriberStore } from './store/subscriberStore';
import { CreatorLinkBankAccount } from './routes/Creator/LinkBankAccount';
import { Notifications } from './components/Notifications/Notifications';

function App() {
    const { goingToStripeMessage } = useGoingToStripe();
    const authLoading = useUserStore((state) => state.loading);
    const authUserType = useUserStore((state) => state.userType);
    const authUser = useUserStore((state) => state.user);
    const creatorLoading = useCreatorStore((state) => state.loading);
    const creator = useCreatorStore((state) => state.creator);

    const subscriberLoading = useSubscriberStore((state) => state.loading);

    if (
        authLoading ||
        (authUserType === 'creator' && creatorLoading) ||
        (authUserType === 'subscriber' && subscriberLoading)
    ) {
        return <LoadingPage />;
    }

    const redirectToOnboarding =
        authUser && authUserType === 'creator' && !creatorLoading && !creator;

    return (
        <>
            <Router>
                <Switch>
                    <Route path="/logout" component={withTracker(Logout)} />

                    {/* Creators onboarding */}
                    <PrivateRoute
                        path="/creator/onboarding"
                        component={CreatorOnboardingPage}
                    />

                    <PrivateRoute
                        path="/creator/link-bank-account"
                        component={withTracker(CreatorLinkBankAccount)}
                        exact
                    />

                    <Route
                        path="/creator/signup"
                        component={withTracker(CreatorSignUp)}
                        exact
                    />

                    {redirectToOnboarding && (
                        <Redirect to="/creator/onboarding" />
                    )}

                    <Route
                        path="/creator"
                        component={withTracker(CreatorLanding)}
                    />

                    <Route
                        path="/signup"
                        component={withTracker(SubscriberSignUp)}
                    />

                    <Route path="/login" component={withTracker(Login)} />

                    <Route
                        path="/reset-password"
                        component={withTracker(ResetPassword)}
                    />

                    <Route path="/terms" component={withTracker(Terms)} />
                    <Route path="/privacy" component={withTracker(Privacy)} />
                    <Route
                        path="/disclaimer"
                        component={withTracker(HealthDisclaimer)}
                    />

                    <PrivateRoute
                        path={`/create-workout`}
                        component={CreateWorkout}
                    />

                    <PrivateRoute
                        path={`/subscription/:id`}
                        component={Subscription}
                    />

                    <PrivateRoute path="/settings" component={Settings} />

                    <PrivateRoute
                        path="/:slug/manage-preview-video"
                        component={ManagePreviewVideoModal}
                        exact
                    />

                    <Route
                        path="/:slug"
                        component={withTracker(ProfilePage as any)}
                    />

                    <Route path="/" component={withTracker(Home)} />
                </Switch>
            </Router>

            {!!goingToStripeMessage && (
                <GoingToStripeOverlay message={goingToStripeMessage} />
            )}

            <Notifications />
        </>
    );
}

export default App;
