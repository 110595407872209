import React, {
    FunctionComponent,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { Link } from 'react-router-dom';
import { FlockVideoPlayer } from 'src/components/FlockVideoPlayer';
import styled from 'src/styles/styled-components';
import { media } from 'src/utils/mediaQueries';
import 'video.js/dist/video-js.css';
import 'videojs-mux';
import { TertiaryButton } from '../ui/Button/TertiaryButton';
import { Constrain } from '../ui/Constrain';
import { ReactComponent as CrossSVG } from 'src/svgs/icons/cross.svg';

const ContentBlock = styled.div`
    background-color: ${({ theme }) => theme.colors.grey6};
    margin-bottom: 2rem;
    border-radius: 3px;
    padding: 1.5rem 1.3rem;
    position: relative;

    h4 {
        margin: 0;
        font-weight: 500;
    }

    p {
        margin-bottom: 2rem;
        font-size: ${({ theme }) => theme.font.size.level5.small};

        &:last-child {
            margin: 0;
        }
    }

    strong {
        font-weight: 500;
    }

    ul {
        font-size: ${({ theme }) => theme.font.size.level5.small};
    }
`;

export const VideoWrapper = styled.div`
    margin: 0 -1.8rem 2rem;
    position: relative;
    height: 60vh;

    ${media.m`
        margin: 0 0 2rem;
        flex-basis: 62%;
        height: 60vh;
    `}
`;

const Wrapper = styled(Constrain)`
    padding: 0;

    ${media.m`
        display: flex;
        justify-content: space-between;

        & > div:nth-child(2) {
            flex-basis: 35%;
        }
    `}
`;

const Disclaimer = styled.div`
    background-color: white;
    border-radius: 3px;
    color: ${({ theme }) => theme.colors.grey1};
    font-size: 1.2rem;
    padding: 1rem;
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 90%;

    ${media.m`
        max-width: 60%;
    `}
`;

const AlignRight = styled.div`
    text-align: right;
`;

const CloseButton = styled.button`
    background: none;
    appearance: none;
    border: 0;
    margin: 0;
    padding: 0;
    float: right;

    svg {
        width: 2.8rem;

        ${media.m`
            width: 3.4rem;
        `}
    }
`;
const DISCLAIMER_HAS_BEEN_HIDDEN_FLAG = 'disclaimerHasBeenHidden';

type Props = {
    workout: flock.Workout;
};

export const VideoModal: FunctionComponent<Props> = ({ workout }) => {
    const [isShowingDisclaimer, setIsShowingDisclaimer] =
        useState<boolean>(false);

    useEffect(() => {
        const hasBeenHidden = localStorage.getItem(
            DISCLAIMER_HAS_BEEN_HIDDEN_FLAG
        );

        if (!hasBeenHidden) {
            setIsShowingDisclaimer(true);
        }
    }, []);

    const hideDisclaimer = useCallback(() => {
        setIsShowingDisclaimer(false);
    }, []);

    const hideDisclaimerForever = useCallback(() => {
        setIsShowingDisclaimer(false);
        localStorage.setItem(DISCLAIMER_HAS_BEEN_HIDDEN_FLAG, 'true');
    }, []);

    return (
        <Wrapper wide>
            <VideoWrapper>
                <FlockVideoPlayer workoutOrPreview={workout} autoPlay={false} />

                {isShowingDisclaimer && (
                    <Disclaimer>
                        <CloseButton onClick={hideDisclaimer}>
                            <CrossSVG />
                        </CloseButton>
                        <p>
                            Take care! Just a note to say that FLOCK.fitness and
                            our Creators are not responsible for any injury or
                            damage caused when using the platform. View our{' '}
                            <Link to="/disclaimer">health disclaimer</Link> for
                            more info.
                        </p>
                        <AlignRight>
                            <TertiaryButton onClick={hideDisclaimerForever}>
                                Got it, don't show me this again
                            </TertiaryButton>
                        </AlignRight>
                    </Disclaimer>
                )}
            </VideoWrapper>

            <div>
                <ContentBlock>
                    <h4>What you'll need</h4>

                    <p>
                        {(workout.equipment.length > 0 && workout.equipment) ||
                            'No equipment needed'}
                    </p>
                </ContentBlock>

                <ContentBlock>
                    <h4>What's involved</h4>

                    <div
                        dangerouslySetInnerHTML={{
                            __html: workout.whatsInvolved,
                        }}
                    ></div>
                </ContentBlock>
            </div>
        </Wrapper>
    );
};
