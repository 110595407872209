import { Field, FieldProps, Formik, FormikProps } from 'formik';
import React, { FunctionComponent, useState } from 'react';
import { Input } from '../ui/Input/Input';
import * as Yup from 'yup';
import { PrimaryButton } from '../ui/Button/PrimaryButton';
import { FormActions } from '../ui/FormActions';
import { CheckboxInput } from '../ui/Input/Checkbox';
import { auth, firestore, functions } from 'src/firebase';
import firebase from 'firebase/app';
import styled from 'src/styles/styled-components';

const Error = styled.div`
    color: ${({ theme }) => theme.colors.pinkRed};
    font-weight: 500;
    text-align: center;
    margin-bottom: 2rem;
`;

const validationSchema = Yup.object().shape({
    emailAddress: Yup.string()
        .email('Please enter a valid email address')
        .required('Please enter a valid email address'),
    firstName: Yup.string().required('Please enter your first name'),
    lastName: Yup.string().required('Please enter your last name'),
    password: Yup.string()
        .required('Please enter a password')
        .min(8, 'Please enter a password of at least 8 characters')
        .matches(/\d/, 'Your password contain at least one number'),
    confirmPassword: Yup.string().when('password', {
        is: (value: string) => (value && value.length > 0 ? true : false),
        then: Yup.string()
            .oneOf([Yup.ref('password')], 'Both passwords need to be the same')
            .required('Please confirm your password'),
    }),
    confirmTerms: Yup.boolean().isTrue(
        'Please accept our terms and conditions'
    ),
});

const INITIAL_VALUES = {
    emailAddress: '',
    firstName: '',
    lastName: '',
    password: '',
    confirmPassword: '',
    confirmTerms: false,
    receiveUpdates: false,
};

type Props = {
    onSuccess: (user: firebase.User) => void;
};

export const CreateSubscriberAccount: FunctionComponent<Props> = ({
    onSuccess,
}) => {
    const [error, setError] = useState<string | null>(null);

    return (
        <>
            <Error>{error}</Error>

            <Formik
                initialValues={{ ...INITIAL_VALUES }}
                validationSchema={validationSchema}
                onSubmit={async (values, actions) => {
                    setError(null);

                    try {
                        const userCred =
                            await auth.createUserWithEmailAndPassword(
                                values.emailAddress,
                                values.password
                            );

                        // call function to assign creator custom claim
                        const assignSubscriber = functions.httpsCallable(
                            'assignSubscriberRole'
                        );

                        await assignSubscriber({ userId: userCred.user!.uid });

                        const subscriber: api.Subscriber = {
                            id: userCred.user!.uid,
                            firstName: values.firstName,
                            lastName: values.lastName,
                            emailAddress: values.emailAddress,
                            receiveUpdates: values.receiveUpdates,
                            createdAt:
                                firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
                        };

                        await firestore
                            .collection('subscribers')
                            .doc(userCred.user?.uid)
                            .set(subscriber);

                        onSuccess(userCred.user!);
                    } catch (err) {
                        switch ((err as unknown as any).code) {
                            case 'auth/email-already-in-use':
                                setError(
                                    'Looks like you already have a FLOCK.fitness account.'
                                );
                                break;
                            default:
                                setError((err as unknown as any).message);

                                break;
                        }

                        actions.setSubmitting(false);
                    }
                }}
            >
                {({
                    submitCount,
                    handleSubmit,
                    isSubmitting,
                }: FormikProps<typeof INITIAL_VALUES>) => (
                    <form onSubmit={handleSubmit} noValidate>
                        <Field name="emailAddress">
                            {({ field, meta }: FieldProps) => (
                                <Input
                                    label="Email address"
                                    type="email"
                                    placeholder="Enter your email address"
                                    error={
                                        (submitCount > 0 && meta.error) || null
                                    }
                                    {...field}
                                />
                            )}
                        </Field>

                        <Field name="firstName">
                            {({ field, meta }: FieldProps) => (
                                <Input
                                    label="First name"
                                    type="text"
                                    placeholder="Enter your first name"
                                    error={
                                        (submitCount > 0 && meta.error) || null
                                    }
                                    {...field}
                                />
                            )}
                        </Field>

                        <Field name="lastName">
                            {({ field, meta }: FieldProps) => (
                                <Input
                                    label="Last name"
                                    type="text"
                                    placeholder="Enter your last name"
                                    error={
                                        (submitCount > 0 && meta.error) || null
                                    }
                                    {...field}
                                />
                            )}
                        </Field>

                        <Field name="password">
                            {({ field, meta }: FieldProps) => (
                                <Input
                                    label="Password"
                                    type="password"
                                    placeholder="Enter a password"
                                    error={
                                        (submitCount > 0 && meta.error) || null
                                    }
                                    {...field}
                                />
                            )}
                        </Field>

                        <Field name="confirmPassword">
                            {({ field, meta }: FieldProps) => (
                                <Input
                                    label="Confirm password"
                                    type="password"
                                    placeholder="Confirm your password"
                                    error={
                                        (submitCount > 0 && meta.error) || null
                                    }
                                    {...field}
                                />
                            )}
                        </Field>

                        <Field name="confirmTerms">
                            {({ field, meta }: FieldProps) => (
                                <CheckboxInput
                                    {...field}
                                    label={
                                        <>
                                            I agree to the FLOCK platform{' '}
                                            <a
                                                href="https://flock.fitness/terms"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                terms of service
                                            </a>
                                        </>
                                    }
                                    error={
                                        (submitCount > 0 && meta.error) || null
                                    }
                                />
                            )}
                        </Field>

                        <Field name="receiveUpdates">
                            {({ field, meta }: FieldProps) => (
                                <CheckboxInput
                                    {...field}
                                    label="I'd like to receive updates from FLOCK"
                                    error={
                                        (submitCount > 0 && meta.error) || null
                                    }
                                />
                            )}
                        </Field>

                        <FormActions>
                            <PrimaryButton
                                type="submit"
                                isSubmitting={isSubmitting}
                            >
                                Create account
                            </PrimaryButton>
                        </FormActions>
                    </form>
                )}
            </Formik>
        </>
    );
};
