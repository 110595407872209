import styled from 'src/styles/styled-components';
import React, { FunctionComponent } from 'react';
import { Loading } from '../Loading';
import { media } from 'src/utils/mediaQueries';

interface ButtonProps
    extends React.DetailedHTMLProps<
        React.ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
    > {
    isSubmitting?: boolean;
}

const StyledButton = styled.button`
    border-radius: 3px;
    background: ${({ theme }) => theme.colors.black};
    color: white;
    font-weight: 600;
    border: 0;
    width: 100%;
    padding: 0.9rem;
    font-size: ${({ theme }) => theme.font.size.level5.small};
    cursor: pointer;

    ${media.m`
        font-size: ${({ theme }) => theme.font.size.level4.small};
    `}

    svg {
        width: 30px;
        height: 14px;
    }

    &:focus {
        outline: none;
        box-shadow: 0px 0px 3px 1px ${({ theme }) => theme.colors.turquoise};
    }
`;

export const SecondaryButton: FunctionComponent<ButtonProps> = ({
    children,
    isSubmitting,
    ref,
    ...props
}) => {
    return (
        <StyledButton {...props} disabled={isSubmitting}>
            {(isSubmitting && <Loading color="white" />) || children}
        </StyledButton>
    );
};
