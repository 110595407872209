import React, { FunctionComponent } from 'react';
import styled from 'src/styles/styled-components';
import { ReactComponent as CircleAlertSVG } from 'src/svgs/icons/circle-alert.svg';
import { Link } from 'react-router-dom';
import { Constrain } from '../ui/Constrain';
import { useGoingToStripe } from 'src/contexts/goingToStripe';
import { goToClientPortal } from 'src/routes/Settings/SubscriberSettings';
import { goToCompleteOnboarding } from './goToCompleteOnboarding';
import { useUserStore } from 'src/store/userStore';
import { useCreatorStore } from 'src/store/creatorStore';
import { isCreatorSetupCompleteSelector } from 'src/store/selectors/isCreatorSetupComplete';
import { isCreatorRequiredToVerify } from 'src/store/selectors/isCreatorRequiredToVerify';
import { isCreatorPendingVerification } from 'src/store/selectors/isCreatorPendingVerification';
import { useSubscriberStore } from 'src/store/subscriberStore';

const Wrapper = styled.div<{ severity: 'high' | 'medium' }>`
    background-color: ${({ severity, theme }) =>
        severity === 'high' ? theme.colors.pinkRed : '#FEFAED'};
    color: ${({ severity, theme }) =>
        severity === 'high' ? 'white' : theme.colors.black};
    font-weight: 500;
    padding: 1.2rem 0;
    box-shadow: ${({ severity, theme }) =>
        severity === 'high'
            ? theme.effects.redShadow
            : theme.effects.greyShadow};
`;

const MainBanner = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > div {
        display: flex;
        align-items: center;
        line-height: 1;

        svg {
            margin-right: 0.7rem;
        }
    }

    small {
        font-weight: 400;
        font-size: 1.2rem;
    }
`;

const CompleteLink = styled(Link)`
    background-color: white;
    border: solid 1px ${({ theme }) => theme.colors.pinkRed};
    color: ${({ theme }) => theme.colors.pinkRed};
    font-weight: 500;
    padding: 0.3rem 1rem;
    text-decoration: none;
    border-radius: 3px;
    cursor: pointer;
`;

const CompleteButton = styled(CompleteLink.withComponent('button'))`
    appearance: none;
`;

export const NotificationBanner: FunctionComponent = () => {
    const creator = useCreatorStore((state) => state.creator);
    const { user, userType } = useUserStore();
    const { subscriptions } = useSubscriberStore();
    const { setGoingToStripeMessage } = useGoingToStripe();

    const setupComplete = useCreatorStore(isCreatorSetupCompleteSelector);
    const requiresVerification = useCreatorStore(isCreatorRequiredToVerify);
    const pendingVerification = useCreatorStore(isCreatorPendingVerification);

    if (
        user &&
        creator &&
        creator.stripeAccount !== undefined &&
        !setupComplete
    ) {
        return (
            <Wrapper severity="high">
                <Constrain>
                    <MainBanner>
                        <div>
                            <CircleAlertSVG stroke="white" />
                            <div>Account setup incomplete</div>
                        </div>

                        <CompleteButton
                            onClick={async () => {
                                setGoingToStripeMessage(
                                    'Taking you to Stripe to complete onboarding'
                                );
                                await goToCompleteOnboarding(
                                    user,
                                    creator.stripeAccount!.id
                                );
                            }}
                        >
                            Finish
                        </CompleteButton>
                    </MainBanner>
                </Constrain>
            </Wrapper>
        );
    }

    if (creator && requiresVerification) {
        return (
            <Wrapper severity="high">
                <Constrain>
                    <MainBanner>
                        <div>
                            <CircleAlertSVG stroke="white" />
                            <div>Action required</div>
                        </div>

                        <CompleteLink to="/settings">Fix</CompleteLink>
                    </MainBanner>
                </Constrain>
            </Wrapper>
        );
    }

    if (creator && pendingVerification) {
        return (
            <Wrapper severity="medium">
                <Constrain>
                    <MainBanner>
                        <div>
                            <CircleAlertSVG stroke="#333333" />
                            <div>
                                <div>Verification pending</div>
                                <small>
                                    We’ll email you when your account has been
                                    verified
                                </small>
                            </div>
                        </div>
                    </MainBanner>
                </Constrain>
            </Wrapper>
        );
    }

    if (
        user &&
        userType === 'subscriber' &&
        subscriptions &&
        subscriptions.length > 0 &&
        subscriptions.some(
            (subscription) =>
                subscription.stripeSubscription?.status === 'past_due'
        )
    ) {
        return (
            <Wrapper severity="high">
                <Constrain>
                    <MainBanner>
                        <div>
                            <CircleAlertSVG stroke="white" />
                            <div>Update payment method</div>
                        </div>

                        <CompleteButton
                            onClick={async () => {
                                setGoingToStripeMessage(
                                    'Taking you to your Stripe dashboard'
                                );
                                await goToClientPortal(subscriptions[0]);
                            }}
                        >
                            Resolve
                        </CompleteButton>
                    </MainBanner>
                </Constrain>
            </Wrapper>
        );
    }

    return <></>;
};
