import { jsx } from 'slate-hyperscript';

export function deserializeHtml(html: string) {
    const document = new DOMParser().parseFromString(html, 'text/html');
    return deserialize(document.body);
}

const deserialize = (el: ChildNode) => {
    if (el.nodeType === 3) {
        return el.textContent;
    } else if (el.nodeType !== 1) {
        return null;
    }

    const children: any[] = Array.from(el.childNodes).map(deserialize);

    switch (el.nodeName) {
        case 'BODY':
            return jsx('fragment', {}, children);
        case 'P':
            return jsx(
                'element',
                { type: 'paragraph' },
                children.length === 0 ? [{ text: '' }] : children
            );
        case 'UL':
            return jsx('element', { type: 'bulleted-list' }, children);
        case 'LI':
            return jsx(
                'element',
                { type: 'list-item' },
                children.length === 0 ? [{ text: '' }] : children
            );
        case 'STRONG':
            return jsx('text', { bold: true }, children);
        case 'U':
            return jsx('text', { underline: true }, children);
        case 'EM':
            return jsx('text', { italic: true }, children);
        default:
            return el.textContent;
    }
};
