import React, { FunctionComponent, useCallback, useState } from 'react';
import { ConfirmModal } from 'src/components/ui/ConfirmModal';
import { Modal } from 'src/components/ui/Modal';
import { VideoModal } from 'src/components/VideoModal/VideoModal';
import { firestore } from 'src/firebase';
import { useUserStore } from 'src/store/userStore';
import styled from 'src/styles/styled-components';
import { ReactComponent as BinSVG } from 'src/svgs/icons/bin.svg';

const DeleteButton = styled.button`
    appearance: none;
    background: none;
    padding: 1rem;
    border: 0;
    cursor: pointer;
    margin-left: 5px;
`;

type Props = {
    workout: flock.Workout | null;
    dismiss: () => void;
    noDelete?: boolean;
};

export const ViewWorkoutModal: FunctionComponent<Props> = ({
    workout,
    dismiss,
    noDelete,
}) => {
    const [isShowingDeleteConfirmation, setIsShowingDeleteConfirmation] =
        useState<boolean>(false);
    const { user } = useUserStore();
    const isWorkoutOwner = user?.uid === workout?.creatorId;

    const markWorkoutAsDeleted = useCallback(async () => {
        if (!dismiss) {
            throw new Error('No dismiss method passed to video modal');
        }

        await firestore
            .collection('workouts')
            .doc(workout!.id)
            .update({ status: 'deleted' } as Partial<flock.Workout>);

        setIsShowingDeleteConfirmation(false);
        dismiss();
    }, [workout, dismiss]);

    return (
        <>
            <Modal
                isOpen={!!workout}
                customHeader={
                    <>
                        {workout && (
                            <>
                                <h2>{workout!.name}</h2>{' '}
                                {isWorkoutOwner && !noDelete && (
                                    <DeleteButton
                                        onClick={() =>
                                            setIsShowingDeleteConfirmation(true)
                                        }
                                    >
                                        <BinSVG />
                                    </DeleteButton>
                                )}
                            </>
                        )}
                    </>
                }
                onClose={dismiss}
            >
                {!!workout && <VideoModal workout={workout} />}
            </Modal>

            <ConfirmModal
                isOpen={isShowingDeleteConfirmation}
                onCancel={() => setIsShowingDeleteConfirmation(false)}
                onConfirm={markWorkoutAsDeleted}
                actionType="delete"
            >
                <>Are you sure you want to delete this workout?</>
            </ConfirmModal>
        </>
    );
};
