import React, { useState, useContext, createContext } from 'react';

type GoingToStripeContextType = {
    goingToStripeMessage: string | null;
    setGoingToStripeMessage: (message: string | null) => void;
};

const GoingToStripeContext = createContext<GoingToStripeContextType>({} as any);

export function GoingToStripeProvider({ children }: { children: any }) {
    const [goingToStripeMessage, setGoingToStripeMessage] = useState<
        string | null
    >(null);

    return (
        <GoingToStripeContext.Provider
            value={
                {
                    goingToStripeMessage,
                    setGoingToStripeMessage,
                } as GoingToStripeContextType
            }
        >
            {children}
        </GoingToStripeContext.Provider>
    );
}

export const useGoingToStripe = () => {
    return useContext(GoingToStripeContext);
};
