import React, { useCallback } from 'react';
import { visitDashboard } from 'src/components/GoToDashboardButton';
import { TertiaryButton } from 'src/components/ui/Button/TertiaryButton';
import { Constrain } from 'src/components/ui/Constrain';
import { Loading } from 'src/components/ui/Loading';
import { Tooltip } from 'src/components/ui/Tooltip';
import { useGoingToStripe } from 'src/contexts/goingToStripe';
import { useCreatorStore } from 'src/store/creatorStore';
import { isCreatorSetupCompleteSelector } from 'src/store/selectors/isCreatorSetupComplete';
import styled from 'src/styles/styled-components';
import { getSymbolFromCurrencyCode } from 'src/utils/getSymbolFromCurrencyCode';
import { media } from 'src/utils/mediaQueries';
import { Heading3 } from '../../components/ui/Heading/Heading3';
import { CreatorOnboardingToDoList } from '../../components/CreatorOnboardingToDoList/CreatorOnboardingToDoList';
import { ReferACreator } from 'src/components/ReferACreator/ReferACreator';
import { Block } from 'src/components/ui/Block';

const Wrapper = styled(Constrain)`
    margin-top: 3rem;

    ${media.l`
        display: flex;
        gap: 3rem;

        & > div {
            flex: 1.5;

            &:last-child {
                flex: 1;
            }
        }
    `}
`;

const Balance = styled.div`
    padding: 2rem;
    border: solid 1px rgb(200 200 200 / 60%);
    box-shadow: 0px 0px 5px rgba(200, 200, 200, 0.6);
    border-radius: 3px;

    ${Heading3} {
        margin-bottom: 1.4rem;
    }

    .tooltip {
        margin-left: 1rem;
    }

    .amount {
        font-size: 4.2rem;
        line-height: 1;
        margin: 0;
        margin-bottom: 1.4rem;
    }

    .currency {
        font-size: 2.4rem;
        font-weight: bold;
        margin-right: 3px;
    }
`;

const Row = styled.div`
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey5};
    font-size: ${({ theme }) => theme.font.size.level4.small};
    padding: 1rem 0;
    display: flex;
    justify-content: space-between;

    .label {
        font-weight: 500;
    }
`;

const RowContainer = styled.div`
    ${Row}:first-child {
        border-top: 1px solid ${({ theme }) => theme.colors.grey5};
    }
`;

function formatPrice(value: number) {
    return value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export const CreatorHome = () => {
    const creator = useCreatorStore((state) => state.creator);
    const creatorLoading = useCreatorStore((state) => state.loading);
    const creatorProfile = useCreatorStore((state) => state.creatorProfile);
    const setupComplete = useCreatorStore(isCreatorSetupCompleteSelector);

    const balance =
        creator &&
        creator.balance &&
        creator.balance.available.length > 0 &&
        creator.balance.available[0].amount + creator.balance.pending[0].amount;
    const currency =
        creator &&
        creator.balance &&
        creator.balance.available.length > 0 &&
        creator.balance.available[0].currency;

    const { goingToStripeMessage, setGoingToStripeMessage } =
        useGoingToStripe();

    const goToDashboard = useCallback(async () => {
        setGoingToStripeMessage('Taking you to your Stripe dashboard');

        try {
            visitDashboard(creator!);
        } catch (err) {
            console.error(err);
            setGoingToStripeMessage(null);
        }
    }, [creator, setGoingToStripeMessage]);

    return (
        <>
            {(!creatorProfile || !creator || creatorLoading) && <Loading />}

            {creatorProfile && creator && !creatorLoading && (
                <Wrapper>
                    <CreatorOnboardingToDoList />

                    <div>
                        <Block spacing="large">
                            <Balance>
                                <Heading3 className="label">
                                    Your available balance
                                    <span className="tooltip">
                                        <Tooltip>
                                            This is an estimate of pending
                                            funds. For a real time view, visit
                                            your dashboard below. We pay out
                                            funds on the 25th of each month (or
                                            next working day).
                                        </Tooltip>
                                    </span>
                                </Heading3>

                                {typeof balance === 'number' &&
                                    typeof currency === 'string' && (
                                        <p className="amount">
                                            <span className="currency">
                                                {getSymbolFromCurrencyCode(
                                                    currency as api.SupportedCurrencies
                                                )}
                                            </span>
                                            {formatPrice(balance / 100)}
                                        </p>
                                    )}

                                {(balance === null ||
                                    balance === undefined) && (
                                    <p className="amount">0.00</p>
                                )}

                                {setupComplete && (
                                    <TertiaryButton
                                        onClick={goToDashboard}
                                        isSubmitting={!!goingToStripeMessage}
                                    >
                                        Visit dashboard
                                    </TertiaryButton>
                                )}
                            </Balance>
                        </Block>

                        <Block spacing="large">
                            <RowContainer>
                                <Row>
                                    <div className="label">
                                        Active subscribers
                                    </div>
                                    <div>
                                        {creatorProfile.numberOfSubscribers}
                                    </div>
                                </Row>
                            </RowContainer>
                        </Block>

                        {creator.referralCode !== undefined && (
                            <Block spacing="large">
                                <ReferACreator />
                            </Block>
                        )}
                    </div>
                </Wrapper>
            )}
        </>
    );
};
