import React, { FunctionComponent } from 'react';
import { motion } from 'framer-motion';
import { ReactComponent as InfoSVG } from 'src/svgs/icons/info.svg';
import BaseTooltip from 'react-tooltip-lite';
import styled from 'src/styles/styled-components';

const Wrapper = styled.div`
    position: relative;
    display: inline-flex;
`;

export const TooltipIconWrapper = styled.div`
    background-color: ${({ theme }) => theme.colors.grey5};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.2rem;
    height: 2.2rem;
    transition: background-color 300ms ease-in;
    cursor: pointer;

    svg {
        width: 1.2rem;
        height: 1.2rem;
        fill: #333333;
        stroke: #333333;
        transition: stroke fill 300ms ease-in;
    }
`;

const StyledTooltip = styled(BaseTooltip)`
    .react-tooltip-lite {
        background-color: white;
        padding: 1.6rem;
        border-radius: 3px;
        box-shadow: 0px 0px 13px rgb(100 100 100 / 50%);
    }

    .react-tooltip-lite-arrow {
        border-color: white;
    }
`;

const Content = styled(motion.div)`
    padding: 1.6rem;
    max-width: 400px;
`;

export const Tooltip: FunctionComponent = ({ children }) => (
    <Wrapper>
        <StyledTooltip
            content={<Content>{children}</Content>}
            direction="down"
            padding={0}
        >
            <TooltipIconWrapper data-testid="tooltip">
                <InfoSVG />
            </TooltipIconWrapper>
        </StyledTooltip>
    </Wrapper>
);
