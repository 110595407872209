import React from 'react';
import { useHistory } from 'react-router';
import { Heading3 } from 'src/components/ui/Heading/Heading3';
import { useGoingToStripe } from 'src/contexts/goingToStripe';
import { useCreatorStore } from 'src/store/creatorStore';
import { isCreatorSetupCompleteSelector } from 'src/store/selectors/isCreatorSetupComplete';
import { useUserStore } from 'src/store/userStore';
import styled, { css } from 'src/styles/styled-components';
import { ReactComponent as ChevronRightBlueSVG } from 'src/svgs/icons/chevron-right-blue.svg';
import { ReactComponent as CircleTickSVG } from 'src/svgs/icons/circle-tick.svg';
import { media } from 'src/utils/mediaQueries';
import { goToCompleteOnboarding } from '../NotificationBanner/goToCompleteOnboarding';

const DEFAULT_AVATAR =
    'https://firebasestorage.googleapis.com/v0/b/flock-fitness.appspot.com/o/default-profile-images%2Fdefault-avatar.png?alt=media&token=e386993c-8ada-4c88-8a1b-200e7b6bafdf';

const Wrapper = styled.div`
    border: solid 1px rgb(200 200 200 / 60%);
    box-shadow: 0px 0px 5px rgba(200, 200, 200, 0.6);
    border-radius: 3px;
    padding: 2rem;
    margin-bottom: 3rem;
`;

const Description = styled.p`
    margin-bottom: 3rem;
`;

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const ToDoButton = styled.button<{ isComplete: boolean }>`
    border: 1px solid #25b5cc;
    background: ${({ isComplete, theme }) =>
        isComplete ? theme.colors.blue : '#fff'};
    box-shadow: 0px 0px 5px rgba(37, 181, 204, 0.4);
    border-radius: 3px;
    padding: 1.5rem;
    text-align: left;
    font-size: 1.8rem;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: box-shadow 200ms;
    color: ${({ theme }) => theme.colors.black};

    ${({ isComplete }) =>
        isComplete &&
        css`
            color: white;
        `}

    ${({ isComplete, disabled }) =>
        !isComplete &&
        disabled &&
        css`
            color: ${({ theme }) => theme.colors.dark};
            box-shadow: 0px 0px 5px rgba(196, 196, 196, 0.4);
            border-color: ${({ theme }) => theme.colors.grey3};
        `}

    .instructions {
        display: flex;
    }

    .what-you-need {
        font-size: 1.4rem;
    }

    .disabled-hint {
        display: block;
        margin-top: 5px;
        font-size: 14px;
    }

    &:not(:last-child) {
        margin-bottom: 1.5rem;
    }

    &:not(:disabled) {
        &:hover {
            cursor: ${({ isComplete }) => (isComplete ? 'inherit' : 'pointer')};
            box-shadow: 0px 0px 13px rgba(37, 181, 204, 0.4);
        }
    }

    svg {
        flex-shrink: 0;
        ${({ isComplete }) =>
            isComplete &&
            css`
                stroke: white;
            `}
    }
`;

const WhatYouNeedTab = styled.span`
    display: inline-flex;
    background: #e9f8fa;
    padding: 0.5rem 1.1rem;
    border-radius: 15px;
    margin-top: 0.8rem;
    margin-right: 0.4rem;

    ${media.l`
        margin-right: .6rem;
    `}
`;

export const CreatorOnboardingToDoList = () => {
    const { push } = useHistory();
    const { setGoingToStripeMessage } = useGoingToStripe();
    const profile = useCreatorStore((state) => state.creatorProfile);
    const user = useUserStore((state) => state.user);
    const workouts = useCreatorStore((state) => state.workouts);
    const isSubscriptionPriceSet = useCreatorStore(
        (state) =>
            (state.creatorProfile &&
                state.creatorProfile.products.length > 0) ||
            false
    );
    const isBankAccountSetup = useCreatorStore(isCreatorSetupCompleteSelector);
    const creatorStripAccount = useCreatorStore(
        (state) => state.creator?.stripeAccount
    );

    if (!profile || !workouts) {
        throw new Error('Profile or workouts are not defined');
    }

    const isProfileImageSet = profile.image !== DEFAULT_AVATAR;
    const isFirstWorkoutUploaded = workouts.length > 0;

    const handleBankAccountSetup = () => {
        if (!isBankAccountSetup && creatorStripAccount && user) {
            setGoingToStripeMessage(
                'Taking you to Stripe to complete onboarding'
            );
            goToCompleteOnboarding(user, creatorStripAccount.id);
        } else {
            push('/creator/link-bank-account');
        }
    };

    return (
        <Wrapper>
            <Heading3>Let's get you started</Heading3>

            <Description>
                There’s a few things you need to go to get started growing your{' '}
                <strong>FLOCK</strong>
            </Description>

            <ButtonsContainer>
                <ToDoButton
                    onClick={() => push(`/${profile!.slug}`)}
                    isComplete={isProfileImageSet}
                    disabled={isProfileImageSet}
                    data-testid="updateProfile"
                >
                    <span>
                        <span className="instructions">
                            Update profile image and bio
                        </span>
                        {!isProfileImageSet && (
                            <span
                                className="what-you-need"
                                data-testid="whatYouNeed"
                            >
                                What you'll need:{' '}
                                <WhatYouNeedTab>
                                    📷 Profile image
                                </WhatYouNeedTab>
                            </span>
                        )}
                    </span>

                    {!isProfileImageSet && <ChevronRightBlueSVG />}

                    {isProfileImageSet && (
                        <CircleTickSVG data-testid="circleTickSVG" />
                    )}
                </ToDoButton>

                <ToDoButton
                    onClick={() => push('/create-workout')}
                    isComplete={isFirstWorkoutUploaded}
                    disabled={isFirstWorkoutUploaded}
                    data-testid="firstWorkout"
                >
                    <span>
                        <span className="instructions">
                            Upload your first workout
                        </span>
                        {!isFirstWorkoutUploaded && (
                            <span
                                className="what-you-need"
                                data-testid="whatYouNeed"
                            >
                                What you'll need:{' '}
                                <WhatYouNeedTab>📹 Content</WhatYouNeedTab>
                                <WhatYouNeedTab>📷 Cover image</WhatYouNeedTab>
                            </span>
                        )}
                    </span>

                    {!isFirstWorkoutUploaded && <ChevronRightBlueSVG />}

                    {isFirstWorkoutUploaded && (
                        <CircleTickSVG data-testid="circleTickSVG" />
                    )}
                </ToDoButton>

                <ToDoButton
                    onClick={handleBankAccountSetup}
                    isComplete={isBankAccountSetup}
                    disabled={isBankAccountSetup}
                    data-testid="linkBank"
                >
                    <span>
                        <span className="instructions">
                            Link your bank account
                        </span>
                        {!isBankAccountSetup && (
                            <span
                                className="what-you-need"
                                data-testid="whatYouNeed"
                            >
                                What you'll need:{' '}
                                <WhatYouNeedTab>🏦 Bank details</WhatYouNeedTab>
                                <WhatYouNeedTab>🛂 ID</WhatYouNeedTab>
                                <WhatYouNeedTab>
                                    🏡 Proof of address
                                </WhatYouNeedTab>
                            </span>
                        )}
                    </span>

                    {!isBankAccountSetup && <ChevronRightBlueSVG />}

                    {isBankAccountSetup && (
                        <CircleTickSVG data-testid="circleTickSVG" />
                    )}
                </ToDoButton>

                <ToDoButton
                    onClick={() => push('/settings')}
                    isComplete={isSubscriptionPriceSet}
                    data-testid="subscriptionPrice"
                    disabled={isSubscriptionPriceSet || !isBankAccountSetup}
                >
                    <span>
                        <span>Set your subscription price</span>
                        <span className="disabled-hint">
                            You need to link your bank account first
                        </span>
                    </span>

                    {isBankAccountSetup && !isSubscriptionPriceSet && (
                        <ChevronRightBlueSVG />
                    )}

                    {isSubscriptionPriceSet && (
                        <CircleTickSVG data-testid="circleTickSVG" />
                    )}
                </ToDoButton>
            </ButtonsContainer>
        </Wrapper>
    );
};
