import { Editor } from 'slate';
import { LeafFormats } from '../RichTextEditor';
import { isMarkActive } from './isMarkActive';

export const toggleMark = (editor: Editor, format: LeafFormats) => {
    const isActive = isMarkActive(editor, format);

    if (isActive) {
        Editor.removeMark(editor, format);
    } else {
        Editor.addMark(editor, format, true);
    }
};
