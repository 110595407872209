import React, { FunctionComponent } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useUserStore } from 'src/store/userStore';

interface IPrivateRouteProps extends RouteProps {
    component: any;
}

const PrivateRoute: FunctionComponent<IPrivateRouteProps> = ({
    component: Component,
    ...rest
}) => {
    const { user } = useUserStore();

    return (
        <Route
            {...rest}
            render={(props) =>
                !!user ? (
                    <Component {...props} {...rest} />
                ) : (
                    <Redirect to="/" />
                )
            }
        />
    );
};

export default PrivateRoute;
