import { Node, Text } from 'slate';

export function serialize(node: Node[] | Node): string {
    if (Array.isArray(node)) {
        return node.map(serialize).join('');
    }

    if (Text.isText(node)) {
        const marks = Object.keys(node).filter((key) => key !== 'text');

        if (marks.length === 0) {
            return node.text;
        }

        return marks.reduce((acc, key) => {
            if (key === 'underline') {
                acc = `<u>${acc}</u>`;
            }

            if (key === 'italic') {
                acc = `<em>${acc}</em>`;
            }

            if (key === 'bold') {
                acc = `<strong>${acc}</strong>`;
            }
            return acc;
        }, node.text);
    }

    const children = node.children.map((n: Node) => serialize(n)).join('');

    switch (node.type) {
        case 'paragraph':
            return `<p>${children}</p>`;
        case 'bulleted-list':
            return `<ul>${children}</ul>`;
        case 'list-item':
            return `<li>${children}</li>`;
        default:
            return children;
    }
}
