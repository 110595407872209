import React, { FunctionComponent, useCallback, useState } from 'react';
import { Loading } from 'src/components/ui/Loading';
import { functions } from 'src/firebase';
import styled, { css } from 'src/styles/styled-components';
import { getSymbolFromCurrencyCode } from 'src/utils/getSymbolFromCurrencyCode';
import { ReactComponent as PencilSVG } from 'src/svgs/icons/pencil.svg';
import { TertiaryButton } from 'src/components/ui/Button/TertiaryButton';
import { format } from 'date-fns';
import { Block } from 'src/components/ui/Block';
import { Header } from 'src/components/ui/Header';
import { useGoingToStripe } from 'src/contexts/goingToStripe';
import { PrimaryButton } from 'src/components/ui/Button/PrimaryButton';
import { Notice } from 'src/components/ui/Notice';
import { useSubscriberStore } from 'src/store/subscriberStore';
import { Distribute } from 'src/components/ui/Distribute';

function getFormattedAmount(
    amount: number,
    currencyCode: api.SupportedCurrencies
): string {
    return `${getSymbolFromCurrencyCode(currencyCode)}${amount / 100}`;
}

const SubscriptionList = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
`;

const Subscription = styled.li`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

    a {
        display: inline-flex;
        text-decoration: none;
        align-items: center;
    }

    img {
        border-radius: 50%;
        width: 4rem;
        height: 4rem;
        object-fit: cover;
    }

    .name {
        margin-right: auto;
        margin-left: 1rem;
        font-family: ${({ theme }) => theme.fontSerif.type};
        font-size: 20px;
    }

    .amount {
        font-size: ${({ theme }) => theme.font.size.level5.small};
        font-weight: 500;
    }

    .cancel-message {
        font-size: ${({ theme }) => theme.font.size.level5.small};
    }
`;

const SubscriptionHeading = styled(Header)`
    margin-bottom: 1rem;
`;

const IconButton = styled.button<{ isActive: boolean }>`
    margin-left: 1rem;
    width: 3.2rem;
    height: 3.2rem;
    border-radius: 50%;
    border: 0;
    background-color: ${({ theme }) => theme.colors.grey6};
    transition: background-color 300ms;
    text-align: center;
    padding: 0;

    svg {
        transition: stroke 300ms;
        stroke: ${({ theme }) => theme.colors.black};
        vertical-align: middle;
    }

    ${({ isActive }) =>
        isActive &&
        css`
            background-color: ${({ theme }) => theme.colors.grey1};

            svg {
                stroke: white;
            }
        `}
`;

const Cancel = styled(TertiaryButton)`
    background: ${({ theme }) => theme.colors.pinkRed};
    color: white;
    font-weight: 500;
    border: 0;
    min-width: 80px;
`;

const PaymentMethod = styled(Block)`
    button {
        width: 100%;
    }
`;

export async function goToClientPortal(
    subscription: api.Subscription
): Promise<void> {
    const getPortalLink = functions.httpsCallable('getCustomerPortalLink');

    const stripeResult = await getPortalLink({
        customerId: subscription.customerId,
    });

    window.location.assign(stripeResult.data.url);
}

export const SubscriberSettings: FunctionComponent = () => {
    const { subscriptions, loading } = useSubscriberStore();
    const { setGoingToStripeMessage } = useGoingToStripe();
    const [isEditingSubscriptions, setIsEditingSubscriptions] =
        useState<boolean>(false);

    const editClientPortal = useCallback(
        async (subscription: api.Subscription) => {
            setGoingToStripeMessage('Taking you to your Stripe dashboard');
            await goToClientPortal(subscription);

            await new Promise((resolve) => setTimeout(resolve, 1000));
            setGoingToStripeMessage(null);
        },
        [setGoingToStripeMessage]
    );

    return (
        <>
            <Block>
                <Distribute>
                    <SubscriptionHeading>My subscriptions</SubscriptionHeading>

                    <IconButton
                        data-testid="editSubscriptions"
                        isActive={isEditingSubscriptions}
                        onClick={() =>
                            setIsEditingSubscriptions(!isEditingSubscriptions)
                        }
                    >
                        <PencilSVG />
                    </IconButton>
                </Distribute>

                {loading && <Loading />}

                {!loading && subscriptions && (
                    <SubscriptionList>
                        {subscriptions.map((subscription, i) => (
                            <Subscription key={i}>
                                <a href={subscription.profile?.slug}>
                                    <img
                                        src={subscription.profile!.image}
                                        alt={`${
                                            subscription.profile!.displayName
                                        } profile`}
                                    />

                                    <div className="name">
                                        {subscription.profile!.displayName}
                                    </div>
                                </a>

                                {!isEditingSubscriptions &&
                                    !subscription.stripeSubscription
                                        ?.cancel_at_period_end && (
                                        <div className="amount">
                                            {getFormattedAmount(
                                                subscription.amount,
                                                subscription.currency
                                            )}
                                            /month
                                        </div>
                                    )}

                                {subscription.stripeSubscription
                                    ?.cancel_at_period_end &&
                                    subscription.stripeSubscription
                                        ?.cancel_at && (
                                        <div className="cancel-message">
                                            Cancelled, ends{' '}
                                            {format(
                                                new Date(
                                                    subscription
                                                        .stripeSubscription
                                                        ?.cancel_at * 1000
                                                ),
                                                'do LLL'
                                            )}
                                        </div>
                                    )}

                                {isEditingSubscriptions &&
                                    !subscription.stripeSubscription
                                        ?.cancel_at_period_end && (
                                        <Cancel
                                            onClick={() =>
                                                editClientPortal(subscription)
                                            }
                                            loadingColor="white"
                                        >
                                            Cancel
                                        </Cancel>
                                    )}
                            </Subscription>
                        ))}
                    </SubscriptionList>
                )}

                {!loading && subscriptions && subscriptions.length === 0 && (
                    <Notice>You have no active subscriptions</Notice>
                )}
            </Block>

            <PaymentMethod>
                <Header>Payment method</Header>

                <p>
                    Your payment method is stored securely with Stripe, you can
                    update your payment information on your FLOCK Stripe
                    dashboard.
                </p>

                <PrimaryButton
                    onClick={() => {
                        if (!subscriptions || subscriptions.length === 0) {
                            return;
                        }

                        editClientPortal(subscriptions![0]);
                    }}
                    disabled={!subscriptions || subscriptions.length === 0}
                >
                    Edit payment method
                </PrimaryButton>
            </PaymentMethod>
        </>
    );
};
