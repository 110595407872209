import create from 'zustand';
import produce from 'immer';
import { v4 as uuidv4 } from 'uuid';

export type Notification = {
    id: string;
    text: string;
    shown: boolean;
    timeout: number;
};

type NotificationStore = {
    notifications: Notification[];
    trigger: (notification: { text: string }) => void;
    setAsShown: (id: string) => void;
    remove: (notification: Notification) => void;
};

export const useNotificationStore = create<NotificationStore>((set, get) => ({
    notifications: [],
    trigger(notificationText) {
        set((baseState) =>
            produce(baseState, (state) => {
                const newNotification = {
                    id: uuidv4(),
                    ...notificationText,
                    shown: false,
                    timeout: 5000,
                };
                state.notifications = [...state.notifications, newNotification];
            })
        );
    },
    remove(notification) {
        set((baseState) =>
            produce(baseState, (state) => {
                state.notifications = state.notifications.filter(
                    (n) => n.id !== notification.id
                );
            })
        );
    },
    setAsShown(id) {
        set((baseState) =>
            produce(baseState, (state) => {
                const notificationToUpdate = state.notifications.find(
                    (n) => n.id !== id
                );

                if (notificationToUpdate) {
                    notificationToUpdate.shown = true;
                }
            })
        );
    },
}));
