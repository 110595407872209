import React, {
    FunctionComponent,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { RouteChildrenProps, useParams } from 'react-router-dom';
import AppLayout from 'src/components/layouts/AppLayout';
import styled from 'src/styles/styled-components';
import { ReactComponent as NoEntrySVG } from 'src/svgs/icons/no-entry.svg';
import { LoadingPage } from 'src/components/ui/LoadingPage';
import { loadStripe } from '@stripe/stripe-js';
import { firestore, functions } from 'src/firebase';
import config from '../../config';
import { useUserStore } from 'src/store/userStore';
import { PublicViewProfile } from './PublicViewProfile/PublicViewProfile';
import { CreatorViewProfile } from './CreatorViewProfile/CreatorViewProfile';
import { SubscriberViewProfile } from './SubscriberViewProfile/SubscriberViewProfile';
import { useSubscriberStore } from 'src/store/subscriberStore';
import { differenceInSeconds } from 'date-fns';

const ErrorWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;

    svg {
        margin-bottom: 3rem;
    }
`;

const Well = styled.div`
    border-radius: 3px;
    background-color: ${({ theme }) => theme.colors.grey6};
    padding: 2rem 5rem;
    text-align: center;
    max-width: 30rem;
    margin: 0 auto;
`;

const subscribeUserToCreator = async (
    user: firebase.default.User,
    profile: api.CreatorProfile,
    subscriber: api.Subscriber
) => {
    const stripePromise = loadStripe(config.STRIPE_PUBLIC_KEY);

    const stripeFn = functions.httpsCallable('createCheckoutSession');
    const stripeResult = await stripeFn({
        priceId: profile.products[0].priceId,
        connectedAccountId: profile.products[0].connectedAccountId,
        profileId: profile.id,
        profileSlug: profile.slug,
        userId: user.uid,
        email: user.email,
        customerId: subscriber?.stripeCustomerId,
    });

    const stripe = await stripePromise;

    return await stripe!.redirectToCheckout({
        sessionId: stripeResult.data.sessionId,
    });
};

const ProfilePage: FunctionComponent<RouteChildrenProps> = () => {
    const { user, userType, loading: userLoading } = useUserStore();
    const {
        subscriber,
        subscriptions,
        loading: subscriberLoading,
    } = useSubscriberStore();
    const { slug } = useParams<{ slug: string }>();

    const [loadedProfile, setLoadedProfile] = useState<api.CreatorProfile>();
    const [loadedWorkouts, setLoadedWorkouts] = useState<flock.Workout[]>([]);
    const [loadedPreviewVideo, setLoadedPreviewVideo] =
        useState<api.CreatorPreviewVideo | null>(null);
    const [noProfileFound, setNoProfileFound] = useState<boolean>(false);
    const [isSubscribing, setIsSubscribing] = useState<boolean>(false);

    const isOwner = loadedProfile?.id === user?.uid;
    const isSubscribed =
        (subscriptions !== null &&
            subscriptions.some((s) => s.profile?.id === loadedProfile?.id)) ||
        false;

    const fetchProfileBySlug = useCallback(
        async (slug: string): Promise<() => void> => {
            try {
                const profileSnapshot = await firestore
                    .collection('creatorProfiles')
                    .where('slug', '==', slug)
                    .get();

                if (profileSnapshot.empty) {
                    throw new Error(`No profile found for slug: ${slug}`);
                }

                const profile =
                    profileSnapshot.docs[0].data() as api.CreatorProfile;
                setLoadedProfile(profile);

                const profilePreviewQuery = await profileSnapshot.docs[0].ref
                    .collection('previews')
                    .where('status', '==', 'published')
                    .get();

                if (profilePreviewQuery && profilePreviewQuery.size === 1) {
                    setLoadedPreviewVideo(
                        profilePreviewQuery.docs[0].data() as any
                    );
                }

                const workoutsOnSnapshot = await firestore
                    .collection('workouts')
                    .where('profileId', '==', profile.id)
                    .where('status', '==', 'published')
                    .onSnapshot((snapshot) => {
                        const fetchedWorkouts: flock.Workout[] = [];
                        snapshot.forEach(function (doc) {
                            fetchedWorkouts.push(doc.data() as flock.Workout);
                        });
                        setLoadedWorkouts(fetchedWorkouts);
                    });

                return workoutsOnSnapshot;
            } catch (err) {
                setNoProfileFound(true);
                return () => {};
            }
        },
        [setLoadedProfile, setLoadedPreviewVideo]
    );

    const handleSubscribe = useCallback(async () => {
        if (!user || !subscriber) {
            throw new Error('No user and/or subscriber defined');
        }

        if (!loadedProfile) {
            throw new Error('No profile defined');
        }

        setIsSubscribing(true);
        await subscribeUserToCreator(user, loadedProfile, subscriber);
        setIsSubscribing(false);
    }, [setIsSubscribing, user, loadedProfile, subscriber]);

    // Detecting whether a subscriber has just been created - if so trigger the subscription button click
    useEffect(() => {
        if (
            userType === 'subscriber' &&
            subscriber &&
            !isSubscribed &&
            loadedProfile &&
            subscriber.createdAt &&
            differenceInSeconds(new Date(), subscriber.createdAt?.toDate()) < 20
        ) {
            handleSubscribe();
        }
    }, [userType, subscriber, handleSubscribe, isSubscribed, loadedProfile]);

    useEffect(() => {
        let cb: () => void = () => {};

        fetchProfileBySlug(slug).then(
            (unsubscribeFromWorkouts) => (cb = unsubscribeFromWorkouts)
        );

        return cb;
    }, [fetchProfileBySlug, slug]);

    if (noProfileFound) {
        return (
            <AppLayout
                title={`Profile not found | FLOCK.fitness`}
                description="This profile can't be found"
            >
                <ErrorWrapper>
                    <NoEntrySVG />
                    <Well>Oops, looks like that profile doesn’t exist!</Well>
                </ErrorWrapper>
            </AppLayout>
        );
    }

    if (
        userLoading ||
        (userType === 'subscriber' && subscriberLoading) ||
        !loadedProfile
    ) {
        return <LoadingPage />;
    }

    switch (userType) {
        case null:
            // Public
            return (
                <PublicViewProfile
                    profile={loadedProfile}
                    previewVideo={loadedPreviewVideo}
                    workouts={loadedWorkouts}
                />
            );
        case 'creator': {
            // Could be owner or viewing another creators profile
            if (isOwner) {
                return (
                    <CreatorViewProfile
                        profile={loadedProfile}
                        previewVideo={loadedPreviewVideo}
                        workouts={loadedWorkouts}
                        setLoadedProfile={setLoadedProfile}
                    />
                );
            } else {
                return (
                    <PublicViewProfile
                        profile={loadedProfile}
                        previewVideo={loadedPreviewVideo}
                        workouts={loadedWorkouts}
                        hideSubscribe
                    />
                );
            }
        }
        case 'subscriber':
            // Could be subscriber or could be public
            return (
                <SubscriberViewProfile
                    profile={loadedProfile}
                    previewVideo={loadedPreviewVideo}
                    workouts={loadedWorkouts}
                    isSubscribed={isSubscribed}
                    isSubscribing={isSubscribing}
                    handleSubscribe={handleSubscribe}
                />
            );
        default:
            throw new Error(`Unexpected UserType ${userType}`);
    }
};

export default ProfilePage;
