import styled from 'src/styles/styled-components';
import { Workout } from './Workout';
import { media } from 'src/utils/mediaQueries';

export const WorkoutGrid = styled.div`
    ${media.m`
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 0 2rem;

        & > ${Workout} > div {
            height: 100%;
        }
    `}
`;
