import React from 'react';
import styled from 'src/styles/styled-components';
import ReactModal from 'react-modal';
import { media } from 'src/utils/mediaQueries';
import { ReactComponent as CrossSVG } from 'src/svgs/icons/cross.svg';
export interface IModalProps extends ReactModal.Props {
    title?: string | boolean;
    customHeader?: JSX.Element;
    onClose?: () => void;
    children?: any;
}

function ReactModalAdapter({ className, modalClassName, ...props }: any) {
    return (
        <ReactModal
            className={modalClassName}
            portalClassName={className}
            closeTimeoutMS={400}
            {...props}
        />
    );
}

const overlayClassName = 'modal__overlay';
const modalContentClassName = 'modal__content';

const StyledModal = styled(ReactModalAdapter).attrs({
    overlayClassName: overlayClassName as any,
    modalClassName: modalContentClassName as any,
})`
    .${modalContentClassName} {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 300;
        overflow: scroll;
        transform: translateY(100vh);
        transition: transform 300ms ease-out;
        background-color: white;

        &:focus {
            outline: none;
        }
    }

    .${overlayClassName} {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 200;
    }

    .ReactModal__Content--after-open {
        transform: translateY(0);
        transition: transform 300ms ease-out;
    }

    .ReactModal__Content--before-close {
        transform: translateY(100vh);
        transition: transform 300ms ease-out;
    }
`;

const ModalHeader = styled.div`
    min-height: 4.5rem;
    font-weight: 500;
    padding: 2rem;
    max-width: 900px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    h2 {
        margin: 0;
        font-weight: normal;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        ${media.m`
            max-width: 300px;
        `}
    }

    ${media.m`
        padding: 3rem;
    `}
`;

const ModalContent = styled.div`
    padding: 0 1.8rem;
    background-color: white;
    width: 100vw;
    min-height: calc(100vh - 76px);

    ${media.m`
        min-height: calc(100vh - 96px);
    `}
`;

const ModalClose = styled.button`
    display: inline-flex;
    background: none;
    appearance: none;
    border: 0;
    margin: 0;
    padding: 1rem;
    position: absolute;
    right: 1rem;

    svg {
        width: 2.8rem;

        ${media.m`
            width: 3.4rem;
        `}
    }
`;

export const Modal = ({
    title,
    onClose,
    customHeader,
    ...props
}: IModalProps) => (
    <StyledModal {...props}>
        <ModalHeader>
            {!customHeader && title && <h2>{title}</h2>}
            {customHeader !== undefined && customHeader}

            {onClose && (
                <ModalClose onClick={onClose}>
                    <CrossSVG />
                </ModalClose>
            )}
        </ModalHeader>

        <ModalContent>{props.children}</ModalContent>
    </StyledModal>
);
