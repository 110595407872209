import React, { useCallback } from 'react';
import { Loading } from 'src/components/ui/Loading';
import { useGoingToStripe } from 'src/contexts/goingToStripe';
import { functions } from 'src/firebase';
import { useCreatorStore } from 'src/store/creatorStore';
import styled from 'src/styles/styled-components';

export async function visitDashboard(creator: api.Creator): Promise<void> {
    const getDashboardLink = functions.httpsCallable('getDashboardLink');
    const result = await getDashboardLink({ userId: creator.id });
    window.location.assign(result.data.url);
}

export const GoToDashboardButton = styled(
    ({ className, children }: { children: string; className?: string }) => {
        const { creator } = useCreatorStore();
        const { goingToStripeMessage, setGoingToStripeMessage } =
            useGoingToStripe();

        const goToDashboard = useCallback(async () => {
            setGoingToStripeMessage('Taking you to your Stripe dashboard');
            if (!creator) {
                throw new Error('No creator defined');
            }

            try {
                visitDashboard(creator);
            } catch (err) {
                console.error(err);
                setGoingToStripeMessage(null);
            }
        }, [creator, setGoingToStripeMessage]);

        return (
            <button
                className={className}
                onClick={goToDashboard}
                disabled={!!goingToStripeMessage}
            >
                {!!goingToStripeMessage ? <Loading /> : children}
            </button>
        );
    }
)`
    appearance: none;
    border: 0;
    border-radius: 3px;
    background-color: white;
    color: ${({ theme }) => theme.colors.pinkRed};
    margin-left: auto;
    font-weight: 500;
    font-size: 14px;
    min-width: 75px;

    &:hover {
        cursor: pointer;
    }

    svg {
        height: 15px;
        fill: ${({ theme }) => theme.colors.pinkRed};
    }
`;
