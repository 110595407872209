import { css, ThemedCssFunction } from 'styled-components';
import theme from '../styles/theme';

export const breakpoints = {
    s: 576,
    m: 768,
    l: 1024,
    xl: 1280,
    xxl: 1600,
};

// Iterate through the sizes and create a media template
export const media: {
    [key in keyof typeof breakpoints]: ThemedCssFunction<typeof theme>;
} = (Object.keys(breakpoints) as Array<keyof typeof breakpoints>).reduce(
    (acc, label: keyof typeof breakpoints) => {
        acc[label] = (first: any, ...args: any) => css`
            @media (min-width: ${breakpoints[label] / 16}em) {
                ${css(first, ...args)}
            }
        `;
        return acc;
    },
    {} as { [key in keyof typeof breakpoints]: ThemedCssFunction<typeof theme> }
);

export const mediaMax: {
    [key in keyof typeof breakpoints]: ThemedCssFunction<typeof theme>;
} = (Object.keys(breakpoints) as Array<keyof typeof breakpoints>).reduce(
    (acc, label: keyof typeof breakpoints) => {
        acc[label] = (first: any, ...args: any) => css`
            @media (max-width: ${breakpoints[label] / 16}em) {
                ${css(first, ...args)}
            }
        `;
        return acc;
    },
    {} as { [key in keyof typeof breakpoints]: ThemedCssFunction<typeof theme> }
);
