import React, { FunctionComponent } from 'react';
import styled, { keyframes } from 'src/styles/styled-components';
import { Loading } from '../ui/Loading';

const Overlay = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1001;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const Wrapper = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 3px;
    padding: 2.5rem 2rem 2rem;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    animation: ${fadeIn} 300ms linear forwards;
    z-index: 1002;

    svg {
        margin-bottom: 2rem;
        width: 5rem;
        height: 5rem;
    }
`;

type Props = {
    message: string;
};

export const GoingToStripeOverlay: FunctionComponent<Props> = ({ message }) => {
    return (
        <>
            <Overlay />

            <Wrapper>
                <Loading />

                <div>{message}</div>
            </Wrapper>
        </>
    );
};
